import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'

import { AddUser } from './pages/AddUser'
import { BasicInfo } from './pages/BasicInfo'
import { ConfirmClient } from './pages/ConfirmClient'
import { ContractServices } from './pages/ContractServices'

import { ClientAddContextProvider } from './ClientAddContext'

import classes from './ClientAdd.module.scss'

export const ClientAdd: React.FC<RouteComponentProps> = ({ match }) => (
  <ClientAddContextProvider>
    <Breadcrumb>
      <BreadcrumbItem to="/clients">Clientes</BreadcrumbItem>
      <BreadcrumbItem>Criar cliente</BreadcrumbItem>
    </Breadcrumb>
    <div className={classes.card}>
      <div className={classes.main}>
        <Switch>
          <Route path={`${match.path}`} exact component={BasicInfo} />
          <Route path={`${match.path}/services`} component={ContractServices} />
          <Route path={`${match.path}/user`} component={AddUser} />
          <Route path={`${match.path}/confirm`} component={ConfirmClient} />
        </Switch>
      </div>
    </div>
  </ClientAddContextProvider>
)
