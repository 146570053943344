import React, { useEffect, useState, Fragment } from 'react'

import * as S from '../../styled'
import { Button } from 'components/Button'
import { useModal } from 'shared/modal'
import { TransactionModal } from 'components/users/TransactionModal'

const handleDateWithTime = (handle: any) => {
  let date = new Date(handle)
  return !date.getDate() ? '' : date.toLocaleString('pt-BR').slice(0, 16)
}

export const ClientTransactions: App.ClientViewSection = ({ state: [client, setClient] }) => {
  const [transactions, setTransactions] = useState<Model.Transaction[]>([])

  const modal = useModal(TransactionModal)

  useEffect(() => {
    fetch(`/api/clients/${client?.id}/transactions`)
      .then(res => res.json())
      .then(({ data }) => setTransactions(data))
  }, [client])

  const openNewTransactionModal = () =>
    modal.open({
      client,
      onClosed: () => {
        fetch(`/api/clients/${client?.id}/transactions`)
          .then(res => res.json())
          .then(({ data }) => setTransactions(data))
      }
    })

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={openNewTransactionModal} outline>
          CRIAR TRANSAÇÃO
        </Button>
      </div>
      <S.Table className={'transactions'}>
        <S.ItemTable className={'first-line'}>Data</S.ItemTable>
        <S.ItemTable className={'first-line'}>Usuário</S.ItemTable>
        <S.ItemTable className={'first-line'}>Valor</S.ItemTable>
        <S.ItemTable className={'first-line'}>Balanço</S.ItemTable>
        <S.ItemTable className={'first-line'} />
        {transactions.map((transaction, index) => (
          <Fragment key={index}>
            <S.ItemTable>{handleDateWithTime(transaction?.created_at)}</S.ItemTable>
            <S.ItemTable>{transaction?.user?.name}</S.ItemTable>
            <S.ItemTable>R${transaction?.value}</S.ItemTable>
            <S.ItemTable>R${transaction?.balance}</S.ItemTable>
            <S.ItemTable>{transaction?.description}</S.ItemTable>
          </Fragment>
        ))}
      </S.Table>
    </>
  )
}
