/**
 * @deprecated
 * @param number The number to cast
 * @param locale The locale (default: pt-BR)
 */
export function castNumber(number: string | number, locale: string = 'pt-BR') {
  return Number(number).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

/**
 *
 * @param number The number to cast
 * @param locale The locale (default: pt-BR)
 */
export function formatDecimal(value: string | number, digits = 2) {
  const number = Number(value)
  if (!isNaN(number)) {
    return number.toLocaleString('pt-BR', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  }
}
