import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import Label from 'components/common/Label'
import { FormTitle } from 'components/common/Form'

import { sessionStates } from 'pages/sessions/typings/session'
import { useFilterContext } from '../hooks/filters'

const animatedComponents = makeAnimated()

export const Status = () => {
  const [, filter] = useFilterContext()

  const options = Array.from(sessionStates).map((state: any) => {
    const [id, { label }] = state
    return {
      value: id,
      label: label
    }
  })

  const onChange = (selected: any) => filter('status', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label>
      <FormTitle>Status</FormTitle>
      <Select
        placeholder={'Selecione um status'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}
