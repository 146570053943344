import React from 'react'

import { Button } from 'components/Button'
import { Modal } from 'shared/modal'

import classes from './DeleteServiceModal.module.scss'

export const DeleteServiceModal: React.FC<any> = ({ service, close }: any) => {
  const confirm = () => {
    fetch(`/api/services/${service.id}`, { method: 'DELETE' })
      .then(close)
      .catch(() => alert('Falha ao deletar serviço!'))
  }
  return (
    <Modal size="sm">
      <div className={classes.title}>
        <h3>Excluir serviço</h3>
      </div>
      <div className={classes.divider} />
      <div className={classes.description}>
        <h4>Gostaria de excluir o serviço</h4>
        <h4>{service.name}?</h4>
      </div>
      <div className={classes.divider} />
      <div className={classes.buttons}>
        <Button outline onClick={close}>
          Cancelar
        </Button>
        <Button onClick={confirm}>Excluir</Button>
      </div>
    </Modal>
  )
}
