import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Container.module.scss'

interface ContainerProps {
  className?: string
  fluid?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | true
  size?: 'sm' | 'md'
}

export const Container: React.FC<ContainerProps> = ({ className, fluid, size, ...props }) => {
  const params = {
    className: classNames(
      className,
      classes.container,
      size && classes[`size-${size}`],
      fluid && fluid !== true && classes[`fluid-${fluid}`]
    )
  }
  return <div {...params} {...props} />
}
