import React, { useCallback, useEffect, useState } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import { Card } from 'shared/ui/card/Card'
import { Button } from 'shared/ui/button/Button'

import classes from './PriceRules.module.scss'

import { Rule } from './Rules'
import { toFormData } from 'shared/util/form-data'

export const PriceRules: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [service, setService] = useState<Partial<Model.Service>>()
  const [isEditing, setIsEditing] = useState(false)

  const onPricingRuleChange = useCallback((rule: Model.PricingRule, current: Model.PricingRule) => {
    setService(({ rules = [], ...service } = {}) => {
      const index = rules.indexOf(rule)
      const items = [...rules]
      if (index >= 0) {
        items.splice(index, 1, current)
      }
      return { ...service, rules: items }
    })
  }, [])

  useEffect(() => {
    fetch(`/api/services/${match.params.id}`)
      .then(response => response.json())
      .then(({ data }) => setService(data))
  }, [match])

  if (!service) {
    return null
  }

  const addNewRule = () => {
    setService(({ rules = [], ...service } = {}) => ({ ...service, rules: [{}, ...rules] }))
  }

  const save = () => {
    setIsEditing(false)
    if (service.rules) {
      for (let rule of service.rules) {
        let url = `/api/prices`

        if (rule.id) {
          url += '/' + rule.id
        }

        const init: RequestInit = {
          method: 'POST',
          body: toFormData({
            service_id: service.id,
            name: rule?.name,
            final_price: rule?.base_price * (1 - rule?.discount?.value) || 1,
            base_price: rule?.base_price,
            order: rule?.order ?? 0,
            discount: rule?.discount,
            rule: {
              conditions: rule?.rule?.conditions ?? {},
              main: rule?.rule?.main ?? {},
              items: rule?.rule?.items ?? []
            }
          })
        }

        fetch(url, init)
      }
    }
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    save()
  }

  const removeRule = (ruleId: number, index: number) => () => {
    fetch(`/api/prices/${ruleId}`, { method: 'DELETE' }).then(() => {
      service.rules!.splice(index, 1)
      setService((service: any) => ({ ...service, rules: service.rules! }))
    })
  }

  const onUpdateRuleOrder = (index: number) => (order: 'UP' | 'DOWN') => {
    if (service.rules) {
      if (order === 'UP') {
        if (index !== 0) {
          service.rules[index].order = service.rules[index].order ? service.rules[index].order - 1 : index - 1
          service.rules[index - 1].order = service.rules[index - 1].order ? service.rules[index - 1].order + 1 : index
        }
      } else {
        if (index !== service.rules.length - 1) {
          service.rules[index].order = service.rules[index].order ? service.rules[index].order + 1 : index + 1
          service.rules[index + 1].order = service.rules[index + 1].order ? service.rules[index + 1].order - 1 : index
        }
      }

      setService((service: any) => ({ ...service, rules: service.rules! }))
    }
  }

  return (
    <Card className={classes.container}>
      <h2>Regras</h2>
      <form onSubmit={onSubmit}>
        <div className={classes.row}>
          {isEditing ? (
            <>
              <Button type="button" onClick={addNewRule}>
                Adicionar nova regra
              </Button>
              <Button>Salvar alterações</Button>
              <Button type="button" onClick={() => setIsEditing(false)} outline>
                Cancelar alterações
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEditing(true)}>Editar regras</Button>
          )}
        </div>
        <div className={classes.rules}>
          {service.rules
            ?.sort((rule1, rule2) => (rule1?.order ?? 0) - (rule2?.order ?? 0))
            .map((pricingRule, index) => {
              return (
                <Rule
                  key={index}
                  pricingRule={pricingRule}
                  isEditing={isEditing}
                  onChange={onPricingRuleChange}
                  onUpdateOrder={onUpdateRuleOrder(index)}
                  remove={removeRule(pricingRule.id, index)}
                />
              )
            })}
        </div>
      </form>
    </Card>
  )
}
