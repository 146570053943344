import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'

import IconAdd from 'assets/images/Icon-Add.svg'
import { Steps } from 'components/Steps'
import { Button } from 'components/Button'

import { ContractServiceItem } from './components/ContractServiceItem'

import { useClientAddContext } from '../ClientAddContext'

import classes from './ContractServices.module.scss'

const Call = styled.h2`
  font-size: 32px;
  line-height: 38px;
  padding: 0;
  margin: 0 0 20px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
`

const Img = styled.img`
  max-width: 35px;
  width: 100%;
  height: auto;
  margin-right: 5px;
`

export const ContractServices: React.FC<RouteComponentProps> = ({ history }) => {
  const [context, setContext] = useClientAddContext()
  const [services, setServices] = useState<any[]>(context.services || [{}])
  const [serviceItemError, setServiceItemError] = useState(false)
  const handleAdd = () => setServices(services => [...services, {}])
  const handleService = (i: number) => (service: any) => {
    setServices(services => {
      services.splice(i, 1, service)
      return [...services]
    })
  }
  const handleBack = () => history.goBack()
  const handleNext = (event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setContext(context => ({ ...context, services }))
    if (services.find(({ products }) => !(products?.length >= 1))) {
      setServiceItemError(true)
    } else {
      history.push(`/clients/add/user`)
    }
  }
  return (
    <Fragment>
      <Steps className="second" />
      <Call>Serviços contratados</Call>
      <form onSubmit={handleNext}>
        {services.map((service, i) => (
          <ContractServiceItem key={i} index={i} onChange={handleService(i)} service={service} />
        ))}
        <button className={classes.add} onClick={handleAdd} type="button">
          <Img src={IconAdd} alt="Adicionar" />
          <span>Adicionar outro serviço</span>
        </button>
        {serviceItemError && <p className={classes.error}>Selecione pelo menos um entregável em cada serviço</p>}
        <Buttons>
          <Button className={classes.back} onClick={handleBack} outline type="button">
            VOLTAR
          </Button>
          <Button className={classes.next}>AVANÇAR →</Button>
        </Buttons>
      </form>
    </Fragment>
  )
}
