import React from 'react'
import { Button } from 'components/Button'

import { Modal } from 'shared/modal'

import classes from './DeliverSessionModal.module.scss'

interface DeliverSessionModalProps {
  close: () => void
  onChange: () => void
  session: Resource.SessionResource
}

export const DeliverSessionModal: React.FC<DeliverSessionModalProps> = ({ close, onChange, session }) => {
  const confirm = () => {
    const data = new FormData()
    data.set('status', '5')
    const request = {
      body: data,
      method: 'POST'
    }
    fetch(`/api/sessions/${session.id}`, request).then(onChange).then(close)
  }

  return (
    <Modal size="sm">
      <div className={classes.title}>
        <h3>Entregar ensaio</h3>
      </div>
      <div className={classes.divider} />
      <div className={classes.description}>
        <h4>Confirma a entrega do ensaio ID {session.id}?</h4>
      </div>
      <div className={classes.divider} />
      <div className={classes.buttons}>
        <Button outline onClick={close}>
          Cancelar
        </Button>
        <Button onClick={confirm}>Confirmar</Button>
      </div>
    </Modal>
  )
}
