import React from 'react'

import { classNames } from '../util/class-names'

import classes from './FormGroup.module.scss'

interface FormGroupProps {
  className?: string
  label?: string
}

export const FormGroup: React.FC<FormGroupProps> = ({ className, children, label }) => {
  return (
    <div className={classNames(classes.group, className, 'form-group')}>
      {label && <label className={classes.label}>{label}</label>}
      {children}
    </div>
  )
}
