import React, { createElement, useEffect, useState } from 'react'

import { Card, CardBody, CardHeader } from 'shared/bootstrap/card'
import { SessionProductIcon } from 'shared/components/sessions/SessionProductIcon'
import { Item } from 'shared/enum/item'
import { ServiceProductSessionStatus } from 'shared/enum/service-product-session'
import { FormTextArea } from 'shared/form/FormTextArea'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'

import { SessionDeliverableBlueprints } from './SessionDeliverableBlueprints'
import { SessionDeliverableEditPhotos } from './SessionDeliverableEditPhotos'
import { SessionDeliverableText } from './SessionDeliverableText'
import { SessionDeliverableTour } from './SessionDeliverableTour'
import { SessionDeliverableUrl } from './SessionDeliverableUrl'
import { SessionDeliverableVideos } from './SessionDeliverableVideos'

import classes from './SessionDeliverables.module.scss'

function getComponent(
  item: Item | number,
  category: 'admin' | 'client' | 'provider',
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>,
  session: Resource.SessionResource
) {
  const props = { category, session }
  switch (item) {
    case Item.BLUEPRINTS:
      return createElement(SessionDeliverableBlueprints, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableBlueprint>,
        ...props
      })
    case Item.CAPTURE_PHOTOS:
    case Item.CAPTURE_TOUR:
    case Item.CAPTURE_TOUR_PHOTOS_TOUR:
    case Item.CAPTURE_TOUR_PHOTOS_PHOTOS:
      return createElement(SessionDeliverableUrl, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>,
        ...props
      })
    case Item.COPYWRITING:
      return createElement(SessionDeliverableText, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableText>,
        ...props
      })
    case Item.EDIT_PHOTOS:
    case Item.EDIT_TOUR:
    case Item.EDIT_TOUR_PHOTOS:
      return createElement(SessionDeliverableEditPhotos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverablePhoto>,
        ...props
      })
    case Item.PRESENTATION_TOUR:
    case Item.PRESENTATION_TOUR_PHOTOS:
      return createElement(SessionDeliverableTour, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableTour>,
        ...props
      })
    case Item.VIDEOS:
      return createElement(SessionDeliverableVideos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableVideo>,
        ...props
      })
    default:
      throw new Error(`Component for item id ${item} not found`)
  }
}

interface SessionDeliverablesProps {
  className?: string
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

function getClientOrientation(
  session: Resource.SessionResource,
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>
) {
  return session.client.items.find(({ id }) => id === product.item.id)?.pivot.orientation
}

export const SessionDeliverables: React.FC<SessionDeliverablesProps> = ({ className, session, category = 'admin' }) => {
  const [orientations, setOrientations] = useState(() => {
    return new Map<number, string>(session.products.map(p => [p.item.id, p.orientation || '']))
  })
  const handleDeliver = (sessionProductId: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ status: ServiceProductSessionStatus.DELIVERED })
    fetch(`/api/sessions/${session.id}/${sessionProductId}`, { body, method: 'POST' })
      .then(() => window.location.reload())
      .catch(() => alert('Falha ao realizar entrega'))
  }
  const handleOrientation = (item: Pick<Model.Item, 'id'>) => (orientation: string) => {
    setOrientations(orientations => new Map(orientations.set(item.id, orientation)))
  }
  const handleOrientationSave = (product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>) => (
    event: React.MouseEvent
  ) => {
    event.preventDefault()
    const body = toFormData({ orientation: orientations.get(product.item.id) || null })
    fetch(`/api/sessions/${session.id}/${product.id}`, { body, method: 'POST' })
      .then(() => window.location.reload())
      .catch(() => alert('Falha ao atualizar orientações'))
  }
  useEffect(() => {}, [session])
  return (
    <div className={className}>
      {session.products.map(product => {
        const orientationsProps = {
          placeholder: getClientOrientation(session, product),
          value: orientations.get(product.item.id)
        }
        const orientation = orientationsProps.placeholder || orientationsProps.value
        return (
          <Card key={product.id} className={classes.card} shadow>
            <CardHeader className={classes.header}>
              <SessionProductIcon url={product.item.icon} />
              <h2 className={classes.title}>{product.item.label}</h2>
              {product.status === ServiceProductSessionStatus.DELIVERED ? (
                product.delivered_at && (
                  <div>
                    <div>Entregue por: {product.delivered_by.name}</div>
                    <div>Entregue às: {formatISO(product.delivered_at, 'dd/MM/yyyy HH:mm')}</div>
                  </div>
                )
              ) : (
                <Button onClick={handleDeliver(product.id)}>FINALIZAR</Button>
              )}
            </CardHeader>
            {category === 'provider' && orientation && product.item.orientation && (
              <CardHeader className={classes.orientations}>
                <div className={classes.info}>
                  <Icon.Bell className={classes.icon} size="2x" />
                  <h4 className={classes.title}>ORIENTAÇÕES</h4>
                  <p className={classes.text}>{orientation}</p>
                </div>
              </CardHeader>
            )}
            {category === 'admin' && (
              <CardHeader className={classes.orientations}>
                <div className={classes.form}>
                  <h4>ORIENTAÇÕES AO FORNECEDOR</h4>
                  <Button size="sm" outline onClick={handleOrientationSave(product)}>
                    SALVAR
                  </Button>
                  <FormTextArea onChange={handleOrientation(product.item)} {...orientationsProps} />
                </div>
              </CardHeader>
            )}
            <CardBody>{getComponent(product.item.id, category, product, session)}</CardBody>
          </Card>
        )
      })}
    </div>
  )
}
