import React, { useContext, useEffect } from 'react'
import { useServiceContext } from './services'
import { useLoadingContext } from './loading'

function useMeta(id: any): [any] {
  const [, setLoad] = useLoadingContext()
  const [, setServices] = useServiceContext()
  const filter = { clients: [id] }

  const reducer = (action: string) => {
    switch (action) {
      case 'update':
        setLoad(true)
        fetch(`/api/services/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter })
        })
          .then(res => res.json())
          .then(({ data }) => setServices(data))
          .then(() => setLoad(false))
        break
    }
  }

  const update = () => reducer('update')

  useEffect(update, [])

  return [reducer]
}

const MetaContext = React.createContext<[any] | undefined>(undefined)

export const useMetaContext = () => useContext(MetaContext)!

export const MetaProvider: React.FC<{ id: any }> = props => {
  const meta = useMeta(props.id)

  return <MetaContext.Provider value={meta} {...props} />
}
