import React, { createContext, useContext, useRef } from 'react'

let uniqueId = 0

const InputRadioContext = createContext<string>(undefined!)

export function useInputRadioContext() {
  return useContext(InputRadioContext)!
}

export const InputRadioContextProvider: React.FC = props => {
  const name = useRef(`input-radio-context-${++uniqueId}`)
  return <InputRadioContext.Provider value={name.current} {...props} />
}
