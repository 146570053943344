import React, { Fragment, useState } from 'react'
import styled from 'styled-components'

import { useAuthContext } from 'components/auth/AuthContext'
import { NavbarLinks } from './NavbarLinks'

import classes from './Navbar.module.scss'

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 16px 1vw;
  @media (max-width: 768px) {
    display: flex;
  }
`

interface NavboxProps {
  open?: boolean
}

const Navbox = styled.div<NavboxProps>`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: #000000;
    transition: all 300ms ease-in-out;
    z-index: -1;
    top: ${({ open }) => (open ? '-200%' : '55px')};
    left: 0;
  }
`

interface HamburgerProps {
  open?: boolean
}

const Hamburger = styled.div<HamburgerProps>`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all 300ms ease-in-out;
  align-self: center;
  position: relative;
  transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'inherit')};

  &::before,
  &::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: '';
    position: absolute;
    transition: all 300ms ease-in-out;
  }

  &::before {
    transform: ${props => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }

  &::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`

export const Navbar = () => {
  const [auth] = useAuthContext()
  const [navbarOpen, setNavbarOpen] = useState(false)
  return (
    <nav className={classes.nav}>
      <div className={classes.wrapper}>
        <div className={classes.navigation}>
          <img src="/img/birdie-icon-yellow.svg" alt="logo" />
          {auth && (
            <Fragment>
              <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
                <Hamburger open={navbarOpen} />
              </Toggle>
              <Navbox open={navbarOpen}>
                <NavbarLinks auth={auth} />
              </Navbox>
            </Fragment>
          )}
        </div>
      </div>
    </nav>
  )
}
