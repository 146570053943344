import React, { useState } from 'react'

import { Button } from 'components/Button'

import { UserProfile } from 'shared/enum/user/UserProfile'
import { FormCheck } from 'shared/form/FormCheck'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormSelect } from 'shared/form/FormSelect'
import { FormText } from 'shared/form/FormText'
import { Modal, ModalHeader } from 'shared/modal'

import classes from './UserFormModal.module.scss'

interface UserFormProps {
  client: Model.Client
  user?: Model.User
}

export const UserFormModal: App.Modal<UserFormProps> = ({ client, close, user }) => {
  const [state, setState] = useState({
    cpf: user?.cpf || '',
    name: user?.name || '',
    email: user?.email || '',
    password: '',
    phone: user?.phone || '',
    profile: user?.profile || 0,
    type: user?.type || 0
  })
  const handleChange = (key: keyof typeof state) => (value: string) => {
    setState(state => ({ ...state, [key]: value }))
  }
  const handleProfile = (value: number) => (checked: boolean) => {
    const toggleProfile = (profile: number) => {
      if (checked) {
        return profile | value
      } else {
        return profile & ~value
      }
    }
    setState(({ profile = 0, ...state }) => ({ ...state, profile: toggleProfile(profile) }))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = new FormData()
    const method = 'POST'
    body.set('client_id', `${client.id}`)
    Object.entries(state).forEach(([key, value]) => {
      body.set(key, `${value}`)
    })
    if (user) {
      fetch(`/api/users/${user.id}`, { body, method })
        .then(close)
        .catch(() => alert('Falha ao atualizar usuário!'))
    } else {
      fetch(`/api/users`, { body, method })
        .then(close)
        .catch(() => alert('Falha ao cadastrar usuário!'))
    }
  }
  const profileCheck = (value: UserProfile) => ({
    checked: Boolean(state.profile & value),
    onChange: handleProfile(value)
  })
  return (
    <Modal size="lg">
      <ModalHeader close={close}>{user ? 'Editar usuário' : 'Criar usuário'}</ModalHeader>
      <form className={classes.container} onSubmit={handleSubmit}>
        <div className={classes.form}>
          <FormGroup className={classes.name} label="Nome">
            <FormText onChange={handleChange('name')} value={state.name} required />
          </FormGroup>
          <FormGroup className={classes.email} label="E-mail">
            <FormText type="email" onChange={handleChange('email')} value={state.email} required />
          </FormGroup>
          <FormGroup label="Tipo de Usuário">
            <FormSelect onChange={handleChange('type')} value={state.type?.toString()}>
              <option value=""></option>
              <option value="1">Admin</option>
              <option value="2">Usuário</option>
              <option value="3">Financeiro</option>
            </FormSelect>
          </FormGroup>
          <FormGroup label="Senha (alteração)">
            <FormText onChange={handleChange('password')} value={state.password} />
          </FormGroup>
          <FormGroup label="Telefone">
            <FormMasked mask="phone" onChange={handleChange('phone')} value={state.phone} />
          </FormGroup>
          {Boolean(state.profile & UserProfile.LegalRepresentative) && (
            <FormGroup label="CPF">
              <FormMasked mask="cpf" onChange={handleChange('cpf')} value={state.cpf} required />
            </FormGroup>
          )}
        </div>
        <FormCheck {...profileCheck(UserProfile.LegalRepresentative)}>Representante Legal</FormCheck>
        <FormCheck {...profileCheck(UserProfile.FinancesManager)}>Responsável Financeiro</FormCheck>
        <FormCheck {...profileCheck(UserProfile.OperationManager)}>Responsável Operacional</FormCheck>
        <hr />
        <div className={classes.footer}>
          <Button outline onClick={close} type="button">
            CANCELAR
          </Button>
          <Button>SALVAR</Button>
        </div>
      </form>
    </Modal>
  )
}
