import React, { /* useEffect, */ useRef, useState } from 'react'

import { useDebouncedCallback } from '../util'

import { BaseDataSource, DataSourceItem } from './BaseDataSource'

interface ServiceDataSourceProps {
  clientId?: number
}

export const ServiceDataSource: React.FC<ServiceDataSourceProps> = ({ clientId, ...props }) => {
  const abortController = useRef<AbortController>()
  const [items, setItems] = useState<DataSourceItem[]>()
  // const [query, setQuery] = useState('') ??
  const handleChange = useDebouncedCallback(
    (value: string) => {
      abortController.current?.abort()
      abortController.current = new AbortController()
      const signal = abortController.current?.signal
      const search = new URLSearchParams()
      search.set('length', '20')
      search.set('v', '2')
      search.set('value', value)
      if (clientId) {
        search.set('client_id', clientId.toString())
      }
      fetch(`/api/services?${search.toString()}`, { signal })
        .then(response => response.json())
        .then(response => response.data as Model.Service[])
        .then(items => items.map(({ id, name }) => [id, name] as DataSourceItem))
        .then(items => setItems(items))
        .catch(error => {
          if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
            return null
          }
          throw new Error(error)
        })
    },
    [],
    300
  )
  return <BaseDataSource items={items} onChange={handleChange} {...props} />
}
