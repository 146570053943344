import React from 'react'

import { classNames } from '../util/class-names'

import classes from './Modal.module.scss'

interface ModalProps {
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Modal: React.FC<ModalProps> = ({ className, size, ...props }) => {
  const params = {
    className: classNames(className, classes.modal, size && classes[`size-${size}`])
  }
  return <div {...params} {...props} />
}
