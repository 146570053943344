import React from 'react'
import * as P from 'components/Pagination'
import ListPagination from 'components/common/ListPagination'

import { usePaginationContext } from '../hooks/pagination'

export const Pagination = () => {
  const [{ lastPage, page }, setPagination] = usePaginationContext()

  if (lastPage < 2) {
    return <></>
  }

  const previousPage = () => {
    setPagination('previous')
  }
  const nextPage = () => {
    setPagination('next')
  }
  const selectPage = (page: number) => {
    setPagination('select', page)
  }

  return (
    <>
      <ListPagination style={{ justifyContent: 'center', marginTop: 'auto' }}>
        <P.PaginationList>
          <P.ItemPrevious onClick={previousPage}>&lsaquo;</P.ItemPrevious>
          {Array.from({ length: lastPage < 5 ? lastPage : 5 }, (_, k) => {
            if (page < 4) {
              return k + 1
            }
            if (lastPage - page < 2) {
              return k + page - 4 + lastPage - page
            }

            return k + page - 2
          }).map(index => (
            <P.ItemPagination key={index} onClick={() => selectPage(index)} className={index === page ? 'active' : ''}>
              {index}
            </P.ItemPagination>
          ))}
          <P.ItemFowards onClick={nextPage}>&rsaquo;</P.ItemFowards>
        </P.PaginationList>
      </ListPagination>
    </>
  )
}
