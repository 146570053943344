import React from 'react'

import { FormGroup, FormText } from 'shared/form'
import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'
import { Button } from 'shared/ui/button/Button'

import classes from './Calendar.module.scss'
import { toFormData } from 'shared/util/form-data'
import { useState } from 'react'
import { FormSelect } from 'shared/form/FormSelect'

interface ModalProps {
  close?: () => void
  calendar?: any
  city: any
}

export const CalendarModal: React.FC<ModalProps> = ({ close, city, calendar }) => {
  const [title, setTitle] = useState<string>(calendar?.title!)
  const [description, setDescription] = useState<string>(calendar?.description!)
  const [item, setItem] = useState<string>('' + calendar?.item_id!)
  const [externalId, setExternalId] = useState<string>(calendar?.associable.external_calendar_id!)

  const save = () => {
    if (city && city.id) {
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({
          item_id: item,
          title,
          city_id: city.id,
          description,
          external_calendar_id: externalId
        })
      }

      let url = `/api/calendars`

      // if it has a calendar, then it's updating itself
      if (calendar && calendar.id) {
        url += `/${calendar.id}`
      }

      fetch(url, init).then(() => window.location.reload())
    } else {
      alert('Recarregue a página e tente novamente.')
    }
  }

  const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      save()
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    save()
  }

  return (
    <Container>
      <Card>
        <h1>{calendar ? 'Editar' : 'Novo'} calendário</h1>
        <form onSubmit={onSubmit}>
          <FormGroup label="Entregável do calendário">
            <FormSelect required onChange={setItem}>
              <option selected={item === undefined || item === '5'} value={'5'}>
                Tour 3D
              </option>
              <option selected={item === '7'} value={'7'}>
                Fotos PRO
              </option>
            </FormSelect>
          </FormGroup>
          <FormGroup label="Título">
            <FormText value={title} onChange={setTitle} placeholder="Título" />
          </FormGroup>
          <FormGroup label="Descrição">
            <FormText value={description} onChange={setDescription} placeholder="Descrição" onKeyPress={onEnterPress} />
          </FormGroup>
          <FormGroup label="ID externo - Opcional">
            <FormText
              value={externalId}
              type="email"
              onChange={setExternalId}
              placeholder="Id do calendário"
              onKeyPress={onEnterPress}
            />
          </FormGroup>
          <div className={classes.row}>
            <Button outline onClick={close}>
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </div>
        </form>
      </Card>
    </Container>
  )
}

export default CalendarModal
