import { useEffect, useState } from 'react'

interface Bank {
  id: number
  code: string
  name: string
}

export function useBanks() {
  const [banks, setBanks] = useState<Bank[]>()
  useEffect(() => {
    fetch(`/api/banks`)
      .then(response => response.json())
      .then(({ data }) => setBanks(data))
  }, [])
  return banks
}
