import React, { Fragment } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { AuthenticatedRoutes } from './components/auth/AuthenticatedRoutes'
import { GuestRoutes } from './components/auth/GuestRoutes'

import { Login } from './pages/auth/Login'
import { Cities } from './pages/cities/Cities'
import { Clients } from './pages/clients/Clients'
import { Deliverables } from './pages/deliverables/Deliverables'
import { Matterport } from './pages/matterport/Matterport'
import { Providers } from './pages/providers/Providers'
import { Services } from './pages/services/Services'
import { Sessions } from './pages/sessions/Sessions'

export const Routes: React.FC = () => (
  <Fragment>
    <AuthenticatedRoutes>
      <Route path="/cities" component={Cities} />
      <Route path="/clients" component={Clients} />
      <Route path="/deliverables" component={Deliverables} />
      <Route path="/matterport" component={Matterport} />
      <Route path="/providers" component={Providers} />
      <Route path="/services" component={Services} />
      <Route path="/sessions" component={Sessions} />
      <Redirect to="/sessions" />
    </AuthenticatedRoutes>
    <GuestRoutes>
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </GuestRoutes>
  </Fragment>
)
