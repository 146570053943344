import React, { useEffect, useRef, useState } from 'react'

import classes from './FormLogo.module.scss'

interface FormLogoProps {
  accept?: string
  multiple?: boolean
  onChange?: (_: File[]) => void
  value?: string | File
}

export const FormLogo: React.FC<FormLogoProps> = ({ onChange, value, ...props }) => {
  const input = useRef<HTMLInputElement>(null)
  const [logo, setLogo] = useState<string>(() => {
    if (typeof value === 'string') {
      return value
    } else {
      return ''
    }
  })
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files.item(0)
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          const result = reader.result as string
          setLogo(result)
          onChange?.([file])
        }
        reader.onerror = () => setLogo('')
        reader.readAsDataURL(file)
      } else {
        // setLogo('')
      }
    } else {
      // setLogo('')
    }
  }
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    input.current?.click()
  }
  useEffect(() => {
    if (value instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        const result = reader.result as string
        setLogo(result)
      }
      reader.onerror = () => setLogo('')
      reader.readAsDataURL(value)
    }
  }, [value])
  return (
    <div className={classes.wrapper} onClick={handleClick}>
      <input type="file" onChange={handleChange} ref={input} {...props} />
      {logo ? (
        <img src={logo} alt="Logo" />
      ) : (
        <span>{props.accept === '.png' ? 'Escolha um arquivo PNG' : 'Escolha um arquivo JPG, PNG ou SVG'}</span>
      )}
    </div>
  )
}
