import React, { useContext, useState } from 'react'

function useService(): [any, any] {
  const [service, setService] = useState<any[]>()

  return [service, setService]
}

const ServiceContext = React.createContext<ReactState<any[]> | undefined>(undefined)

export const useServiceContext = () => useContext(ServiceContext)!

export const ServiceProvider: React.FC = props => {
  const service = useService()!

  return <ServiceContext.Provider value={service} {...props} />
}
