import { useEffect, useState } from 'react'

export function useServices(data: any = {}) {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    const params = new URLSearchParams()
    Object.entries(data).forEach(([key, value]) => {
      params.set(key, `${value}`)
    })
    fetch(`/api/services?${params.toString()}`)
      .then(response => response.json())
      .then(({ data }) => setServices(data))
  }, [data])
  return services
}
