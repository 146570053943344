export function phoneToLink(phone: Nullable<string>) {
  if (phone) {
    const parts = phone.replace(/[^\d]/g, '').match(/(\d{2})(\d{4,5})(\d{4})/)
    if (parts) {
      return `tel:+55-${parts[1]}-${parts[2]}-${parts[3]}`
    }
  }
  return ''
}

export function phoneToWhatsApp(phone: Nullable<string>, message?: string) {
  if (phone) {
    phone = phone.replace(/[^\d]/g, '')
    if (phone.match(/\d{10,11}/)) {
      if (message) {
        message = encodeURIComponent(message)
      } else {
        message = ''
      }
      if (phone.length === 11) {
        return `https://wa.me/55${phone}?text=${message}`
      } else {
        return `https://wa.me/${phone}?text=${message}`
      }
    }
  }
  return ''
}
