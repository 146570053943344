import React, { Children, Fragment } from 'react'

import { classNames } from '../util/class-names'

import classes from './FormInputGroup.module.scss'

interface FormInputGroupAddonOld {
  type?: 'button' | 'text'
}

type Position = 'Append' | 'Prepend'

type FormInputGroupType = React.FC &
  {
    [key in Position]: React.FC<FormInputGroupAddonOld>
  }

export const FormInputGroup: FormInputGroupType = props => {
  return <div className={classes.wrapper} {...props} />
}

export const FormInputGroupAppend: React.FC = ({ children }) => (
  <Fragment>
    {Children.map(children as React.ReactElement[], child =>
      React.cloneElement(child, { className: classNames(classes.append, child.props.className) })
    )}
  </Fragment>
)

FormInputGroup.Append = ({ type, ...props }) => {
  const Component = type === 'button' ? 'button' : 'div'
  return <Component className={classes.append} {...props} />
}

FormInputGroup.Prepend = ({ type, ...props }) => {
  const Component = type === 'button' ? 'button' : 'div'
  return <Component className={classes.prepend} {...props} />
}
