import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import '../../react-table.scss'
import 'react-date-range/dist/theme/default.css'
import './dateselectstyle.css'

import classes from './ClientSessions.module.scss'

import * as S from '../../styled'
import { Button } from 'components/Button'
import { LoadingProvider, useLoadingContext } from './hooks/loading'
import { PaginationProvider } from './hooks/pagination'
import { FilterProvider, useFilterContext } from './hooks/filters'
import { SessionsProvider, useSessionContext } from './hooks/sessions'
import { MetaProvider, useMetaContext } from './hooks/meta'
import { useProviders } from 'hooks/data/providers'

import { sessionStates } from 'pages/sessions/typings/session'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { Input } from 'components/common/Grid'
import Label from 'components/common/Label'

import { FormTitle } from 'components/common/Form'
import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { Badge } from 'shared/ui/badge/Badge'
import { FormText } from 'shared/form'
import { Icon } from 'shared/ui/icon'

import { parseAddressComponents } from 'shared/util/google-maps'
import { SortProvider, useSortContext } from './hooks/sort'

const animatedComponents = makeAnimated()

const Search = () => {
  const [meta] = useMetaContext()
  const [, filter] = useFilterContext()

  const onChange = (e: any) => filter('search', e.target.value)
  const onKeyPress = (e: any) => (e.key === 'Enter' ? meta('update') : null)

  return (
    <Label className={'long'} style={{ marginTop: '5px' }}>
      <FormTitle>Busca</FormTitle>
      <Input type="text" onChange={onChange} onKeyPress={onKeyPress} placeholder="ID, endereço, referência..." />
    </Label>
  )
}

const StatusFilter = () => {
  const [, filter] = useFilterContext()

  const options = Array.from(sessionStates).map((state: any) => {
    const [id, { label }] = state
    return {
      value: id,
      label: label
    }
  })

  const onChange = (selected: any) => filter('status', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label>
      <FormTitle>Status</FormTitle>
      <Select
        placeholder={'Selecione um status'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}

const PhotographerFilter = () => {
  const providers = useProviders()
  const [, filter] = useFilterContext()

  function map(photos: any[]) {
    return photos?.map(photo => {
      return {
        value: photo.id,
        label: photo.name
      }
    })
  }

  const options = map(providers!)

  const onChange = (selected: any) => filter('providers', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label>
      <FormTitle>Fornecedor</FormTitle>
      <Select
        placeholder={'Selecione um fornecedor'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}

const parseStrings = (...str: any[]) => {
  return str.reduce((prev, curr) => {
    if (curr === undefined) {
      return prev
    }

    if (prev === '') return curr

    return prev + ', ' + curr
  }, '')
}

const handleDateWithTime = (handle: any) => {
  let date = new Date(handle)
  return !date.getDate() ? '' : date.toLocaleString('pt-BR').slice(0, 16)
}

const Session = ({ session, index }: any) => {
  const history = useHistory()
  const address = parseAddressComponents(session?.address?.address_components)
  const addressLabel = parseStrings(
    address?.get('route'),
    address?.get('street_number'),
    address?.get('sublocality'),
    address?.get('administrative_area_level_2')
  )

  const onClick = () => {
    history.push(`/sessions/${session?.id}`)
  }

  return (
    <Fragment>
      <S.ItemTable onClick={onClick} className={'text-center ' + (index % 2 === 0 ? 'dark' : 'light')}>
        {session?.id}
        <br />
        <Badge color={getSessionStatusColor(session?.status)}>{getSessionStatusDescription(session?.status)}</Badge>
      </S.ItemTable>
      <S.ItemTable onClick={onClick} className={'text-center ' + (index % 2 === 0 ? 'dark' : 'light')}>
        {session?.vertical_data?.reference}
      </S.ItemTable>
      <S.ItemTable onClick={onClick} className={index % 2 === 0 ? 'dark' : 'light'}>
        {session?.provider?.name}
        <br />
        {session?.provider?.cpf}
        <br />
      </S.ItemTable>
      <S.ItemTable onClick={onClick} className={index % 2 === 0 ? 'dark' : 'light'}>
        {addressLabel}
      </S.ItemTable>
      <S.ItemTable onClick={onClick} className={index % 2 === 0 ? 'dark' : 'light'}>
        {session?.service?.name} <br />
        R$ {session?.service?.price ?? '0,00'}
      </S.ItemTable>
      <S.ItemTable onClick={onClick} className={index % 2 === 0 ? 'dark' : 'light'}>
        {session?.scheduled_for && handleDateWithTime(session?.scheduled_for)}
      </S.ItemTable>
    </Fragment>
  )
}

const SessionDate = () => {
  const [, filter] = useFilterContext()
  const [from, setFrom] = useState<any>()
  const [to, setTo] = useState<any>()

  useEffect(() => {
    if (from && to) {
      filter('session_date', {
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString()
      })
    } else {
      filter('session_date', {})
    }

    // TODO: add filter in dep array
    // eslint-disable-next-line
  }, [from, to])

  const onChange = (date: string, set: any) => {
    set(date)
  }

  return (
    <Label>
      <FormTitle>Data do Ensaio</FormTitle>
      <div className={classes.row}>
        <FormText type="date" onChange={date => onChange(date, setFrom)} value={from} />
        <FormText type="date" onChange={date => onChange(date, setTo)} value={to} />
      </div>
    </Label>
  )
}
const PaymentFilter: React.FC = () => {
  const options = [
    {
      value: 1,
      label: 'pagamento 1'
    },
    {
      value: 2,
      label: 'pagamento 2'
    }
  ]

  const onChange = () => {}

  return (
    <Label style={{ display: 'none' }}>
      <FormTitle>Pagamento</FormTitle>
      <Select
        placeholder={'Selecione'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        options={options}
      />
    </Label>
  )
}

const ClientSessionsContent = ({ state }: any) => {
  const [client] = state
  const [, filter] = useFilterContext()
  const [sessions] = useSessionContext()
  const [meta] = useMetaContext()
  const [loading] = useLoadingContext()
  const [sort, setSort] = useSortContext()
  const [filterSchedule, setFilterSchedule] = useState(false)

  useEffect(() => {
    filter('client_id', client?.id ? client.id : null)
    meta('update')
    // eslint-disable-next-line
  }, [])

  const flipSchedule = () => {
    setFilterSchedule(!filterSchedule)

    setSort({ ...sort, order_by: 'scheduled_for', descending: filterSchedule })
  }

  const onClick = () => meta('update')

  return (
    <>
      <S.CardForm>
        <S.Title className={'sessions'}>Filtrar Ensaios</S.Title>
        <S.TableCardForm className={'two-columns'}>
          <StatusFilter />
          <SessionDate />
          <PaymentFilter />
          <PhotographerFilter />
          <Search />
        </S.TableCardForm>
        <Button onClick={onClick}>FILTRAR</Button>
      </S.CardForm>
      <S.Table className={'sessions'}>
        <S.ItemTable className={'first-line text-center'}>ID</S.ItemTable>
        <S.ItemTable className={'first-line text-center'}>Ref Cliente</S.ItemTable>
        <S.ItemTable className={'first-line text-left'}>Ensaio</S.ItemTable>
        <S.ItemTable className={'first-line text-left'}>Endereço</S.ItemTable>
        <S.ItemTable className={'first-line text-left'}>Serviço</S.ItemTable>
        <S.ItemTable className={'first-line text-left'} onClick={flipSchedule}>
          <div className={classes.flip}>
            Data do Ensaio
            <Icon.ArrowUp transform={filterSchedule ? { rotate: 180 } : {}} />
          </div>
        </S.ItemTable>

        {loading ? (
          <Skeleton width={'700%'} style={{ marginBottom: '80px' }} height={'80px'} count={5} />
        ) : (
          sessions!.map((session: any, index: number) => <Session key={index} index={index} session={session} />)
        )}
      </S.Table>
    </>
  )
}

export const ClientSessions = ({ state }: any) => {
  return (
    <SortProvider>
      <LoadingProvider>
        <PaginationProvider>
          <FilterProvider>
            <SessionsProvider>
              <MetaProvider>
                <ClientSessionsContent state={state} />
              </MetaProvider>
            </SessionsProvider>
          </FilterProvider>
        </PaginationProvider>
      </LoadingProvider>
    </SortProvider>
  )
}
