import React, { useState } from 'react'

import { ClientListTable } from 'shared/table/ClientListTable'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { useDebouncedCallback } from 'shared/hooks/util'

import { ClientListFilter } from './components/ClientListFilter'

import classes from './ClientList.module.scss'

export const ClientList: React.FC = () => {
  const [filter, setFilter] = useState<object>({})
  const handleFilter = useDebouncedCallback((filter: object) => setFilter({ filter }), [], 400)
  return (
    <Container>
      <div className={classes.header}>
        <Breadcrumb>
          <BreadcrumbItem>Clientes</BreadcrumbItem>
        </Breadcrumb>
        <Button size="sm" to="/clients/add">
          CRIAR CLIENTE
        </Button>
      </div>
      <Card className={classes.card} shadow>
        <ClientListFilter onChange={handleFilter} />
        {/*<div className={classes.buttonRow}>
          <Button onClick={onClick} size="sm">FILTRAR</Button>
        </div>*/}
      </Card>
      <Card className={classes.content} shadow>
        <ClientListTable className={classes.table} filter={filter} />
      </Card>
    </Container>
  )
}
