import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { ServiceAdd } from './service-add/ServiceAdd'
import { ServiceView } from './service-view/ServiceView'

export const Services: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/add`} component={ServiceAdd} />
    <Route path={`${match.path}/:id`} component={ServiceView} />
  </Switch>
)
