import React, { createContext, useCallback, useContext, useState } from 'react'

interface DropdownContextProps {
  close?: () => void
  open?: () => void
  opened?: boolean
}

const DropdownContext = createContext<DropdownContextProps>(undefined!)

export function useDropdownContext(): DropdownContextProps | undefined {
  return useContext(DropdownContext)
}

export const Dropdown: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(false)
  const close = useCallback(() => setOpened(true), [])
  const open = useCallback(() => setOpened(false), [])
  return (
    <DropdownContext.Provider value={{close, open, opened}}>
      <div className="dropdown">{children}</div>
    </DropdownContext.Provider>
  )
}
