import React from 'react'
import { Switch } from 'react-router-dom'

import { useAuthContext } from './AuthContext'

export const AuthenticatedRoutes: React.FC = ({ children }) => {
  const [user] = useAuthContext()
  if (Boolean(user)) {
    return <Switch>{children}</Switch>
  } else {
    return null
  }
}
