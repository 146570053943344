import styled from 'styled-components'

export const PaginationList = styled.div`
  display: inline-flex;
  flex-direction: row;
`

export const ItemFowards = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #d8d8d8;
  transition: all 300ms ease-in-out;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin-right: 5px;
  color: #000;

  &:hover {
    background: #ffd600;
    border-color: #ffd600;
  }
`

export const ItemPrevious = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  background: transparent;
  border: 2px solid #d8d8d8;
  transition: all 300ms ease-in-out;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin-left: 5px;
  color: #000;

  &:hover {
    background: #ffd600;
    border-color: #ffd600;
  }
`

export const ItemPagination = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  background: #d8d8d8;
  border: 2px solid #d8d8d8;
  transition: all 300ms ease-in-out;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  margin: 0 3px;
  color: #000;

  &:hover {
    background: #ffd600;
    border-color: #ffd600;
  }

  &.active {
    background: #ffd600;
    border-color: #ffd600;
  }
`
