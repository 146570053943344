import React from 'react'

import classes from './Filter.module.scss'

import Label from 'components/common/Label'
import { Input } from 'components/common/Grid'
import { FormTitle } from 'components/common/Form'
import { useFilterContext } from '../hooks/filters'
import { useMetaContext } from '../hooks/meta'

export const Search = () => {
  const [meta] = useMetaContext()
  const [, filter] = useFilterContext()

  const onChange = (e: any) => filter('search', e.target.value)
  const onKeyPress = (e: any) => (e.key === 'Enter' ? meta('update') : null)

  return (
    <Label className={classes.label}>
      <FormTitle>Busca</FormTitle>
      <Input type="text" onChange={onChange} onKeyPress={onKeyPress} placeholder="ID, endereço, referência, ..." />
    </Label>
  )
}
