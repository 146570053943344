import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Check from 'assets/images/check.svg'

import { Button } from 'components/Button'
import { useItems } from 'hooks/data/item'

import { useClientAddContext } from '../ClientAddContext'

const Call = styled.h2`
  font-size: 32px;
  line-height: 38px;
  padding: 0;
  margin: 0 0 20px;
  font-weight: bold;
  text-align: left;
  color: #13be2a;
`

const Icon = styled.img`
  max-width: 24px;
  height: auto;
  margin-right: 10px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
`

const DadosCard = styled.div`
  background: #f2f2f2;
  border-radius: 6px;
  padding: 25px 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
`

const InfoServices = styled.div``

const InfoUsers = styled.div``

const InfoImage = styled.div`
  width: 140px;
  height: 70px;
  background: #ffffff;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
`
const InfoLogo = styled.img`
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: auto;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;
  flex-direction: column;
  text-align: left;
  flex: 1;
`

const Name = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #000;
  padding: 0;
  margin: 0;
`

const MyText = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #000;
  padding: 0;
  margin: 0;
`

const User = styled.div``

const MyUser = styled.p`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #000;
  margin: 10px 0 5px;
`

const ItemService = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 20px;
`
const ListCard = styled.ul`
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const CardSelected = styled.li`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 10px 5px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #b2b2b2;
  min-height: 120px;
  min-width: 85px;
  max-width: 85px;
  max-height: 120px;
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
`

const Img = styled.img`
  max-width: 60px;
  height: auto;
  margin-bottom: 15px;
`

const Text = styled.p`
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #000;
`

export const ConfirmClient = () => {
  const [context] = useClientAddContext()
  const [logoPreview, setLogoPreview] = useState('')
  const items = useItems().filter(item => !item.item)
  const getItemLogo = (item: any) => {
    const logo = items?.find(({ title }) => title === item.name)?.icon
    return `http://assets.birdie.com.br/${logo}`
  }
  useEffect(() => {
    if (context?.logo) {
      const reader = new FileReader()
      reader.onerror = () => alert('Falha ao carregar logo')
      reader.onload = () => {
        const result = reader.result?.toString()
        if (result) {
          setLogoPreview(result)
        }
      }
      reader.readAsDataURL(context?.logo)
    }
  }, [context])
  return (
    <Fragment>
      <Call>
        <Icon src={Check} alt="check icon" />
        Cliente salvo com sucesso!
      </Call>
      <DadosCard>
        <InfoHeader>
          <InfoImage>{context?.logo && <InfoLogo src={logoPreview} alt="Logo Empresa" />}</InfoImage>
          <Content>
            <Name>{context?.name}</Name>
            <MyText>{/*context?.city?.name}, {context?.city?.state*/}</MyText>
          </Content>
        </InfoHeader>
        <InfoServices>
          {context?.services?.map((service, i) => (
            <ItemService key={i}>
              <Name>{service.name}</Name>
              <MyText>R$ {service.price}</MyText>
              <ListCard>
                {service?.products?.map((product, i) => (
                  <CardSelected key={i}>
                    <Img src={getItemLogo(product)} alt={product.name} />
                    <Text>{product.name}</Text>
                  </CardSelected>
                ))}
              </ListCard>
            </ItemService>
          ))}
        </InfoServices>
        <InfoUsers>
          <User>
            <MyUser>Usuário</MyUser>
            <Name>{context?.user?.name}</Name>
            <MyText>{context?.user?.email}</MyText>
            <MyText>{context?.user?.phone}</MyText>
          </User>
        </InfoUsers>
      </DadosCard>
      <Buttons>
        <Button outline to={`/clients/${context?.id}`}>
          EDITAR
        </Button>
        <Link to="/">
          <Button>CERTO!</Button>
        </Link>
      </Buttons>
    </Fragment>
  )
}
