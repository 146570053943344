import { useEffect, useState } from 'react'

export function useClientServices(clientId?: number) {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    if (clientId) {
      fetch(`/api/clients/${clientId}/services`)
        .then(response => response.json())
        .then(({ data }) => setServices(data))
    } else {
      setServices(undefined)
    }
  }, [clientId])
  return services
}
