import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { Input } from 'shared/bootstrap/form/Input'
import { InputAutocomplete } from 'shared/bootstrap/form/InputAutocomplete'
import { CityDataSource } from 'shared/hooks/source/CityDataSource'
import { ServiceDataSource } from 'shared/hooks/source/ServiceDataSource'

import classes from './ClientListFilter.module.scss'

interface ClientListFilterProps {
  onChange?: (_: any) => void
}

export const ClientListFilter: React.FC<ClientListFilterProps> = ({ onChange }) => {
  const [, setValue] = useState<object>()
  const handleChange = (key: string) => (value: string | number) => {
    setValue(current => {
      onChange?.({ ...current, [key]: value })
      return { ...current, [key]: value }
    })
  }
  return (
    <div className={classes.container}>
      <FormGroup label="Cidade">
        <CityDataSource>
          <InputAutocomplete onChange={handleChange('city_id')} />
        </CityDataSource>
      </FormGroup>
      <FormGroup label="Serviço">
        <ServiceDataSource>
          <InputAutocomplete onChange={handleChange('service_id')} />
        </ServiceDataSource>
      </FormGroup>
      <FormGroup label="Buscar">
        <Input onChange={handleChange('search')} placeholder="Buscar por nome ou ID" />
      </FormGroup>
    </div>
  )
}
