import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import { classNames } from '../util/class-names'

import { ModalConfig, ModalContainer } from './ModalContainer'

import classes from './ModalHost.module.scss'

interface ModalHostProps<P> {
  component: React.FC<P>
  params?: P & ModalConfig & ModalCallbacks
}

export interface ModalCallbacks {
  onClosed?: () => void
}

export interface ModalHostRef<P> {
  close: () => void
  open: (params?: Omit<P, 'close'> & ModalCallbacks) => void
}

const ModalHostComponent: React.ForwardRefRenderFunction<ModalHostRef<any>, ModalHostProps<any>> = (
  { component: Component, params },
  ref
) => {
  // const [closing, setClosing] = useState(false)
  const [opened, setOpened] = useState(false)
  const [props, setProps] = useState<typeof params>()
  // const [opening, setOpening] = useState(false)
  const show = opened // (opened || opening) && closing === false
  const close = useCallback(() => {
    setOpened(false)
    props?.onClosed?.()
  }, [props])
  const open = useCallback((props?: Omit<typeof params, 'close'>) => {
    setProps(props)
    setOpened(true)
  }, [])
  useImperativeHandle(ref, () => ({ close, open }))
  return (
    <div className={classNames(classes.container, show && classes.show)}>
      <ModalContainer show={show}>{show && <Component close={close} {...params} {...props} />}</ModalContainer>
    </div>
  )
}

export const ModalHost = forwardRef(ModalHostComponent)
