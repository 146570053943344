import React, { useState } from 'react'

import { Icon } from 'shared/ui/icon'
import { Button } from 'shared/ui/button/Button'

import classes from './Saving.module.scss'
import { handleTimeout } from 'shared/util/functions'

import { Status } from 'shared/enum/requests/status'

interface SavingProps {
  onSave?: () => Promise<any>
  onChange?: (value: any) => void
}

export const Saving: React.FC<SavingProps> = ({ onSave, onChange }) => {
  const [saved, setSaved] = useState<Status>(Status.INITIAL)

  const setOk = () => setSaved(Status.OK)
  const setError = () => setSaved(Status.ERROR)
  const setSaving = () => setSaved(Status.SAVING)

  const onClick = () => {
    setSaving()

    if (onSave) {
      onSave()
        .then(response => {
          handleTimeout(setOk)

          if (onChange) {
            onChange(response)
          }
        })
        .catch(setError)
    }
  }

  return (
    <div>
      <Button type="submit" onClick={onClick}>
        Salvar
      </Button>
      <div className={classes.check}>
        {saved === Status.OK && <Icon.Check />}
        {saved === Status.SAVING && <Icon.CircleNotch className={classes.faster} spin />}
        {saved === Status.ERROR && <Icon.Cross />}
      </div>
    </div>
  )
}
