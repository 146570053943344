import React, { useState } from 'react'

import { ProviderListTable } from 'shared/table/ProviderListTable'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { useDebouncedCallback } from 'shared/hooks/util'

import { ProviderListFilter } from './components/ProviderListFilter'

import classes from './ProviderList.module.scss'

export const ProviderList: React.FC = () => {
  const [filter, setFilter] = useState<object>({})
  const handleFilter = useDebouncedCallback(
    (value: object) => setFilter(filter => ({ ...filter, filter: value })),
    [],
    400
  )
  return (
    <Container>
      <div className={classes.header}>
        <Breadcrumb>
          <BreadcrumbItem>Fornecedores</BreadcrumbItem>
        </Breadcrumb>
        <Button size="sm" to="/providers/add">
          CRIAR FORNECEDOR
        </Button>
      </div>
      <Card className={classes.card} shadow>
        <ProviderListFilter onChange={handleFilter} />
      </Card>
      <Card className={classes.content} shadow>
        <ProviderListTable className={classes.table} filter={filter} />
      </Card>
    </Container>
  )
}
