import React, { createContext, useContext } from 'react'

export type DataSourceItem<T = string> = [string | number, T]

interface BaseDataSourceProps<T = string | object> {
  items?: DataSourceItem<T>[]
  onChange: (_: string) => void
}

type Props<T> = React.PropsWithChildren<BaseDataSourceProps<T>>

const DataSourceContext = createContext<BaseDataSourceProps>(undefined!)

export function useDataSource() {
  return useContext(DataSourceContext)
}

export const BaseDataSource = <T extends string | object>({ items, onChange, ...props }: Props<T>) => (
  <DataSourceContext.Provider value={{ items, onChange }} {...props} />
)
