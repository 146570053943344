import React, { useContext, useState } from 'react'

function useLoading(): [boolean, (_: boolean) => void] {
  const [loading, setLoading] = useState(true)

  return [loading, setLoading]
}

const LoadingContext = React.createContext<[boolean, (_: boolean) => void] | undefined>(undefined)

export const useLoadingContext = () => useContext(LoadingContext)!

export const LoadingProvider: React.FC = props => {
  const loading = useLoading()

  return <LoadingContext.Provider value={loading} {...props} />
}
