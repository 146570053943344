import React, { useEffect, useState } from 'react'

import Label from 'components/common/Label'
import { FormTitle } from 'components/common/Form'
import { FormText } from 'shared/form'

import { useFilterContext } from '../hooks/filters'

import classes from './Filter.module.scss'

export const Data = () => {
  const [, filter] = useFilterContext()
  const [from, setFrom] = useState<any>()
  const [to, setTo] = useState<any>()

  useEffect(() => {
    if (from && to) {
      filter('scheduled_date', {
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString()
      })
    } else {
      filter('scheduled_date', {})
    }
    // TODO: add filter in dep array
    // eslint-disable-next-line
  }, [from, to])

  const onChange = (date: string, set: any) => {
    set(date)
  }

  return (
    <Label>
      <FormTitle>Criado em</FormTitle>
      <div className={classes.row}>
        <FormText type="date" onChange={date => onChange(date, setFrom)} value={from} />
        <FormText type="date" onChange={date => onChange(date, setTo)} value={to} />
      </div>
    </Label>
  )
}
