import React from 'react'

import { useFormGroupContext } from './FormGroupNew'

export interface Props {
  maxLength?: number
  onChange?: (_: string) => void
  placeholder?: string
  required?: boolean
  type?: 'email' | 'password' | 'text'
  value?: string
}

export const Input: React.FC<Props> = ({ onChange, ...props }) => {
  const formGroup = useFormGroupContext()
  const params = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    }
  }
  return <input className="form-control" id={formGroup?.id} {...props} {...params} />
}
