import React, { useEffect, useRef, useState } from 'react'

import { useDebouncedCallback } from '../util'

import { BaseDataSource, DataSourceItem } from './BaseDataSource'

export const ProviderDataSource: React.FC = ({ children }) => {
  const abortController = useRef<AbortController>()
  const [items, setItems] = useState<DataSourceItem[]>()
  // const [query, setQuery] = useState('') ??
  const handleChange = useDebouncedCallback(
    (value: string) => {
      abortController.current?.abort()
      abortController.current = new AbortController()
      const signal = abortController.current?.signal
      fetch(`/api/providers?v=2&length=20&search=${value}`, { signal })
        .then(response => response.json())
        .then(response => response.data as Model.Provider[])
        .then(items => items.map(({ id, name }) => [id, name] as DataSourceItem))
        .then(items => setItems(items))
        .catch(error => {
          if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
            return null
          }
          throw new Error(error)
        })
    },
    [],
    300
  )
  useEffect(() => {
    handleChange('')
  }, [handleChange])
  return (
    <BaseDataSource items={items} onChange={handleChange}>
      {children}
    </BaseDataSource>
  )
}
