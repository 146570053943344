import React, { Fragment, useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { MatterportTourStatus } from 'shared/enum/matterport-tour'
import { Input } from 'shared/form/Input'
import { InputGroup } from 'shared/form/InputGroup'
import { Icon } from 'shared/ui/icon'
import { TableColumn, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'

import classes from './TourListTableItem.module.scss'

interface Props {
  onChange?: () => void
  tour: Model.MatterportTour
}

export const TourListTableItem: React.FC<Props> = ({ onChange, tour }) => {
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const handleAttached = () => {
    if (loading === false) {
      setLoading(true)
      const body = toFormData({ status: MatterportTourStatus.ATTACHED })
      fetch(`/api/matterport-tours/${tour.id}?session_id=${input}`, {body, method: 'POST'})
        .then(() => onChange?.())
    }
  }
  const handleDetached = () => {
    if (loading === false) {
      setLoading(true)
      const body = toFormData({ status: MatterportTourStatus.DETACHED })
      fetch(`/api/matterport-tours/${tour.id}`, {body, method: 'POST'})
        .then(() => onChange?.())
    }
  }
  return (
    <TableRow>
      <TableColumn size={2}>
        <div>
          <strong>{tour.model}</strong>
        </div>
        <div className={classes.date}>
          {formatISO(tour.info.created, 'dd/MM/yyyy')}
        </div>
        <div className={classes.date}>
          {formatISO(tour.info.created, 'HH:mm:ss')}
        </div>
      </TableColumn>
      <TableColumn align="center" size={7}>
        <div>{tour.name}</div>
        <div className={classes.link}>
          <a href={`https://my.matterport.com/models/${tour.model}`} rel="noopener noreferrer" target="_blank">
            https://my.matterport.com/models/{tour.model}
          </a>
        </div>
      </TableColumn>
      <TableColumn align="center" size={3}>
        {loading ? (
          <Icon.CircleNotch spin size="2x" />
        ) : (
          <Fragment>
            <div>
              <InputGroup size="sm">
                <Input placeholder="ID Birdie" onChange={setInput} value={input} />
                <Button outline variant="secondary" onClick={handleAttached}>
                  <strong>OK</strong>
                </Button>
              </InputGroup>
            </div>
            <div>
              <Button className={classes.detach} size="sm" variant="link" onClick={handleDetached}>
                Desconsiderar
              </Button>
            </div>
          </Fragment>
        )}
      </TableColumn>
    </TableRow>
  )
}