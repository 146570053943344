import React, { useState, useContext } from 'react'

function useFilters(): [any, any] {
  const [filter, setFilter] = useState<any>({})

  const handleFilterChange = (key: string, value: any) => {
    let _filter = filter
    switch (key) {
      case 'product_status':
        _filter[key] !== undefined && _filter[key][0] === value ? (_filter[key] = undefined) : (_filter[key] = [value])
        break

      default:
        _filter[key] = value
        break
    }
    setFilter({ ..._filter })
  }

  return [filter, handleFilterChange]
}

const FilterContext = React.createContext<[any, any] | undefined>(undefined)

export const useFilterContext = () => useContext(FilterContext)!

export const FilterProvider: React.FC = props => {
  const filter = useFilters()

  return <FilterContext.Provider value={filter} {...props} />
}
