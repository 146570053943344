import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { ProviderAdd } from './provider-add/ProviderAdd'
import { ProviderList } from './provider-list/ProviderList'
import { ProviderView } from './provider-view/ProviderView'

export const Providers: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={ProviderList} />
    <Route path={`${match.path}/add`} component={ProviderAdd} />
    <Route path={`${match.path}/:id`} component={ProviderView} />
  </Switch>
)
