import React from 'react'
import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'
import { FormGroup } from 'shared/form'
import { FormSelect } from 'shared/form/FormSelect'

import classes from './AddCityToServiceModal.module.scss'
import { useCities } from 'hooks/data/city'
import { Button } from 'shared/ui/button/Button'
import { useState } from 'react'
import { toFormData } from 'shared/util/form-data'

// 'client.id' => ['required'],
// 'host_prize' => ['required', 'integer'],
// 'name' => ['required'],
// 'price' => ['nullable'],
// 'products.*.item_id' => ['required'],
// 'products.*.name' => ['required'],
// 'products.*.tags.*.name' => ['nullable'],

interface Modal {
  close?: () => void
  service: Model.Service
}

export const AddCityToServiceModal: React.FC<Modal> = ({ close, service }) => {
  const cities = useCities()
  const [city_id, setSelectedCity] = useState<string>()

  const save = (event: React.FormEvent) => {
    event.preventDefault()

    if (service && city_id) {
      let body = toFormData({
        name: service?.name || '',
        host_prize: service?.host_prize || 12,
        price: service?.price || 0,
        products: service?.products || [],
        city_id
      })

      const init: RequestInit = {
        method: 'POST',
        body
      }

      fetch('/api/services', init).then(() => window.location.reload())
    }
  }

  return (
    <Container className={classes.container}>
      <Card>
        <h1>Copiar para cidade</h1>
        <form onSubmit={save}>
          <FormGroup label="Escolha a cidade">
            <FormSelect onChange={setSelectedCity} required>
              <option>Selecione</option>
              {cities?.map((city, index) => (
                <option key={index} value={city.id}>
                  {city.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <div className={classes.row}>
            <Button outline onClick={close}>
              Cancelar
            </Button>
            <Button type="submit">Adicionar</Button>
          </div>
        </form>
      </Card>
    </Container>
  )
}
