import React, { useCallback, useRef } from 'react'
import IMask, { InputMask } from 'imask'

import { classNames } from '../../util/class-names'

import { useFormGroupContext } from './FormGroup'

interface InputDecimalProps {
  onChange?: (_: number) => void
  sm?: boolean
  scale?: number
  value?: number | string
}

export const InputDecimal: React.FC<InputDecimalProps> = ({ onChange, scale, sm, value }) => {
  const formGroup = useFormGroupContext()
  const inputMaskRef = useRef<InputMask<IMask.MaskedNumberOptions>>()
  const ref = useCallback(
    (element: HTMLInputElement | null) => {
      if (element) {
        // element.value = Number(value).toLocaleString('pt-BR') || ''
        inputMaskRef.current?.destroy()
        inputMaskRef.current = new InputMask<IMask.MaskedNumberOptions>(element, {
          mask: Number,
          radix: ',',
          scale: scale || 0
        })
      }
    },
    [scale]
  )
  const params = {
    className: classNames('form-control', sm && 'form-control-sm'),
    id: formGroup?.id,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(Number(event.target.value))
    },
    ref: ref,
    value: value
  }
  return <input {...params} />
}
