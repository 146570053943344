import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { Input } from 'shared/bootstrap/form/Input'
import { InputAutocomplete } from 'shared/bootstrap/form/InputAutocomplete'
import { InputDateRange } from 'shared/bootstrap/form/InputDateRange'
import { InputSelect } from 'shared/bootstrap/form/InputSelect'
import { InputSelectItem } from 'shared/bootstrap/form/InputSelectItem'
import { SessionStatus, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { ClientDataSource } from 'shared/hooks/source/ClientDataSource'
import { ProviderDataSource } from 'shared/hooks/source/ProviderDataSource'
import { ServiceDataSource } from 'shared/hooks/source/ServiceDataSource'

import classes from './SessionListFilter.module.scss'

interface SessionListFilterProps {
  onChange?: (_: any) => void
}

export const SessionListFilter: React.FC<SessionListFilterProps> = ({ onChange }) => {
  const [, setValue] = useState<object>()
  const handleChange = (key: string) => (value: string | number) => {
    setValue(current => {
      onChange?.({ ...current, [key]: value })
      return { ...current, [key]: value }
    })
  }
  const handleDateChange = (key: string) => (value: [string, string]) => {
    setValue(current => {
      onChange?.({ ...current, [key]: value })
      return { ...current, [key]: value }
    })
  }
  return (
    <div className={classes.container}>
      <FormGroup label="Cliente">
        <ClientDataSource>
          <InputAutocomplete onChange={handleChange('client_id')} />
        </ClientDataSource>
      </FormGroup>
      <FormGroup label="Fornecedor">
        <ProviderDataSource>
          <InputAutocomplete onChange={handleChange('provider_id')} />
        </ProviderDataSource>
      </FormGroup>
      <FormGroup label="Status">
        <InputSelect onChange={handleChange('status')}>
          {Object.values(SessionStatus)
            .filter(Number)
            .map(status => (
              <InputSelectItem key={status} value={status}>
                {getSessionStatusDescription(Number(status))}
              </InputSelectItem>
            ))}
        </InputSelect>
      </FormGroup>
      <FormGroup label="Serviço">
        <ServiceDataSource>
          <InputAutocomplete onChange={handleChange('service_id')} />
        </ServiceDataSource>
      </FormGroup>
      <FormGroup label="Data do ensaio">
        <InputDateRange onChange={handleDateChange('scheduled_for')} />
      </FormGroup>
      <FormGroup label="Data de criação">
        <InputDateRange onChange={handleDateChange('created_at')} />
      </FormGroup>
      <FormGroup className={classes.search} label="Buscar">
        <Input maxLength={200} placeholder="ID, referência ou endereço" onChange={handleChange('search')} />
      </FormGroup>
    </div>
  )
}
