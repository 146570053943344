import React from 'react'

import { classNames } from '../../util/class-names'

import { useDropdownContext } from './Dropdown'

interface DropdownItemProps {
  className?: string
  onClick?: (_: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'button'
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ className, onClick, ...props }) => {
  const dropdownContext = useDropdownContext()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dropdownContext?.close?.()
    onClick?.(event)
  }
  return <button className={classNames('dropdown-item', className)} onClick={handleClick} {...props} />
}
