import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { FormAddress } from 'shared/form/FormAddress'
import { Button } from 'shared/ui/button/Button'
import { FormGroup } from 'shared/form'

import classes from './Add.module.scss'
import { toFormData } from 'shared/util/form-data'

type Place = google.maps.places.PlaceResult

export const Add: React.FC = () => {
  const history = useHistory()
  const [city, setCity] = useState<Place>()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const init: RequestInit = {
      method: 'POST',
      body: toFormData({ city })
    }

    fetch('/api/cities', init)
      .then(res => res.json())
      .then(({ data }) => history.push(`/cities/${data.id}`))
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>Cidades</BreadcrumbItem>
        <BreadcrumbItem>Nova cidade</BreadcrumbItem>
      </Breadcrumb>
      <Container className={classes.container}>
        <Card>
          <form onSubmit={onSubmit}>
            <Card variant="100">
              <h2>Informações</h2>
              <FormGroup label="Nome da cidade">
                <FormAddress onChange={setCity} type="cities" required />
              </FormGroup>
            </Card>
            <Button type="submit">Salvar</Button>
          </form>
        </Card>
      </Container>
    </>
  )
}

export default Add
