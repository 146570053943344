import React, { Fragment } from 'react'

import * as S from '../../styled'
import { Button } from 'components/Button'
import { ServiceFormModal } from 'components/services/ServiceFormModal'
import { useModal } from 'shared/modal'
import { LoadingProvider, useLoadingContext } from './hooks/loading'
import { useServiceContext, ServiceProvider } from './hooks/services'
import { MetaProvider, useMetaContext } from './hooks/meta'
import Skeleton from 'react-loading-skeleton/lib'

import Edit from 'assets/images/icones/icon-edit.svg'
import Trash from 'assets/images/icones/icon-trash.svg'
import { DeleteServiceModal } from 'components/services/DeleteServiceModal'

const Service: React.FC<any> = ({ client, service, index, modal, deleteModal }) => {
  const [meta] = useMetaContext()

  const openEditModal = () => {
    modal.open({
      client,
      service,
      close: () => {
        meta('update')
        modal.close()
      }
    })
  }

  const openDeleteModal = () => {
    deleteModal.open({
      service,
      close: () => {
        meta('update')
        deleteModal.close()
      }
    })
  }
  return (
    <>
      <S.ClientServiceItem className={index % 2 === 0 ? 'dark' : 'light'}>{service?.name}</S.ClientServiceItem>
      <S.ClientServiceItem className={index % 2 === 0 ? 'dark' : 'light'}>
        {service?.products?.map((product: any) => product?.name).join(', ')}
      </S.ClientServiceItem>
      <S.ClientServiceItem className={index % 2 === 0 ? 'dark' : 'light'}>
        R$ {service?.price ?? '0,00'}
      </S.ClientServiceItem>
      <S.ClientServiceItem className={index % 2 === 0 ? 'dark' : 'light'}>
        <S.IconEdit onClick={openEditModal} src={Edit} />
        <S.IconTrash onClick={openDeleteModal} src={Trash} />
      </S.ClientServiceItem>
    </>
  )
}

const Services = (props: any) => {
  const [services] = useServiceContext()
  return (
    <>
      {services!.map((service: any, key: number) => (
        <Fragment key={key}>
          <Service service={service} index={key} {...props} />
        </Fragment>
      ))}
    </>
  )
}

const ClientServicesContent: App.ClientViewSection = ({ state: [client] }) => {
  const [load] = useLoadingContext()
  const [meta] = useMetaContext()
  const modal = useModal(ServiceFormModal)
  const deleteModal = useModal(DeleteServiceModal)

  const onClick = () => {
    modal.open({
      client,
      onClosed: () => {
        meta('update')
      }
    })
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClick} outline>
          CRIAR SERVIÇO
        </Button>
      </div>
      <S.Table className={'services'}>
        <S.ItemTable className={'first-line'}>Serviço</S.ItemTable>
        <S.ItemTable className={'first-line'}>Produtos</S.ItemTable>
        <S.ItemTable className={'first-line'}>Valor</S.ItemTable>
        <S.ItemTable className={'first-line'}></S.ItemTable>

        {load ? (
          <Skeleton width="500%" height="40px" count={5} style={{ marginBottom: '40px' }} />
        ) : (
          <Services client={client} modal={modal} deleteModal={deleteModal} />
        )}
      </S.Table>
    </>
  )
}

export const ClientServices: App.ClientViewSection = ({ state: [client, setClient] }) => (
  <LoadingProvider>
    <ServiceProvider>
      <MetaProvider id={client!.id}>
        <ClientServicesContent state={[client, setClient]} />
      </MetaProvider>
    </ServiceProvider>
  </LoadingProvider>
)
