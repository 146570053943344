import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Breadcrumb.module.scss'

interface BreadcrumbProps {
  className?: string
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ className, ...props }) => (
  <div className={classNames(classes.breadcrumb, className)} {...props} />
)
