import React, { forwardRef, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { IMaskInput } from 'react-imask'
import { format, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Icon } from '../../ui/icon'
import { classNames } from '../../util/class-names'

import classes from './InputDateRange.module.scss'

registerLocale('ptBR', ptBR)
setDefaultLocale('ptBR')

interface InputDateRangeProps {
  onChange?: (_: [string, string]) => void
  value?: [string?, string?]
}

interface DateRangeInputProps {
  onClick?: () => void
  value?: string
}

const DateRangeInput = forwardRef<HTMLInputElement, DateRangeInputProps>((props, ref) => (
  <IMaskInput mask="00/00/00[00]" inputRef={ref} {...props} />
))

export const InputDateRange: React.FC<InputDateRangeProps> = ({ onChange, value }) => {
  const [end, setEnd] = useState<Date>()
  const [start, setStart] = useState<Date>()
  const datePickerProps = (setDate: (_: Date) => void, value?: string) => {
    return {
      className: `form-control ${classes.input}`,
      customInput: <DateRangeInput />,
      dateFormat: 'dd-MM-y',
      endDate: end,
      selectsEnd: setDate === setEnd,
      selectsStart: setDate === setStart,
      startDate: start,
      value: value
    }
  }
  const endDatePicker = {
    onChange: (date: Date) => {
      setEnd(date)
      const startDate = isValid(start) ? format(start!, 'yyyy-MM-dd') : ''
      const endDate = isValid(date) ? format(date, 'yyyy-MM-dd') : ''
      onChange?.([startDate, endDate])
    },
    placeholderText: 'Data fim',
    selected: end
  }
  const startDatePicker = {
    onChange: (date: Date) => {
      setStart(date)
      const startDate = isValid(date) ? format(date, 'yyyy-MM-dd') : ''
      const endDate = isValid(end) ? format(end!, 'yyyy-MM-dd') : ''
      onChange?.([startDate, endDate])
    },
    placeholderText: 'Data início',
    selected: start
  }
  const handleClear = (setDate: (_?: Date) => void) => (event: React.MouseEvent) => {
    event.preventDefault()
    setDate(undefined)
  }
  return (
    <div className={classes.container}>
      <div className="input-group">
        <button className={classes.calendar} type="button">
          <Icon.Calendar />
        </button>
        <DatePicker {...startDatePicker} {...datePickerProps(setStart, value?.[0])} />
        <button className={classNames(classes.clear, start && classes.clearable)} onClick={handleClear(setStart)}>
          <Icon.Times />
        </button>
      </div>
      <div className="input-group">
        <button className={classes.calendar} type="button">
          <Icon.Calendar />
        </button>
        <DatePicker {...endDatePicker} {...datePickerProps(setEnd, value?.[1])} />
        <button className={classNames(classes.clear, end && classes.clearable)} onClick={handleClear(setEnd)}>
          <Icon.Times />
        </button>
      </div>
    </div>
  )
}
