import React, { useState } from 'react'

import { classNames } from 'shared/util/class-names'
import classes from './Table.module.scss'
import { Icon } from 'shared/ui/icon'
import { useSortContext } from '../hooks/sort'

export const Header = () => {
  const row = classNames(classes.row, classes.header)
  const idClass = classes.col
  const dateClass = classNames(classes.col, classes.order)
  const addressClass = classNames(classes.col3, classes.textLeft)
  const serviceClass = classNames(classes.col, classes.textLeft)
  const clientClass = classNames(classes.col, classes.textLeft)
  const priceClass = classNames(classes.col, classes.textLeft)

  const [filterSchedule, setFilterSchedule] = useState(false)
  const [sort, setSort] = useSortContext()

  const flipSchedule = () => {
    setFilterSchedule(!filterSchedule)

    setSort({ ...sort, order_by: 'scheduled_for', descending: filterSchedule })
  }

  return (
    <>
      <div className={row}>
        <div className={idClass}>ID</div>
        <div className={dateClass} onClick={flipSchedule}>
          Data
          <Icon.ArrowUp transform={filterSchedule ? { rotate: 180 } : {}} />
        </div>
        <div className={addressClass}>Endereço</div>
        <div className={serviceClass}>Serviço</div>
        <div className={clientClass}>Cliente</div>
        <div className={priceClass}>Preço?</div>
      </div>
    </>
  )
}
