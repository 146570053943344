import React, { Children } from 'react'

import { ClassListItem } from '../util/class-names'

import { useTableContext } from './Table'

import classes from './TableBody.module.scss'

interface TableBodyProps {
  className?: string
}

interface TableBodyChild {
  // i: number
  hoverClass?: ClassListItem
  stripClass?: ClassListItem
}

type TableBodyChildElement = React.FunctionComponentElement<TableBodyChild>

export const TableBody: React.FC<TableBodyProps> = ({ className, children }) => {
  const context = useTableContext()
  const childProps = (index: number) => {
    return {
      // i: index, ?
      hoverClass: context.hover && classes.hover,
      stripClass: context.strip && Boolean(++index % 2) && classes.strip
    }
  }
  return (
    <div className={className}>
      {Children.map(children, (child, i) =>
        React.cloneElement<TableBodyChild>(child as TableBodyChildElement, childProps(i))
      )}
    </div>
  )
}
