import React from 'react'
import { Switch } from 'react-router-dom'

import { useAuthContext } from './AuthContext'

export const GuestRoutes: React.FC = ({ children }) => {
  const [user] = useAuthContext()
  if (user === null) {
    return <Switch>{children}</Switch>
  } else {
    return null
  }
}
