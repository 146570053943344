import React from 'react'
import { Button } from 'components/Button'

import { Modal } from 'shared/modal'

import classes from './DeleteUserModal.module.scss'

export const DeleteUserModal: React.FC<{ close: any; user: any }> = ({ close, user }) => {
  const confirm = () => {
    fetch(`/api/users/${user.id}`, { method: 'DELETE' }).then(close)
  }

  return (
    <Modal size="sm">
      <div className={classes.title}>
        <h3>Excluir usuário</h3>
      </div>
      <div className={classes.divider} />
      <div className={classes.description}>
        <h4>Gostaria de excluir o usuário</h4>
        <h4>{user.name}?</h4>
      </div>
      <div className={classes.divider} />
      <div className={classes.buttons}>
        <Button outline onClick={close}>
          Cancelar
        </Button>
        <Button onClick={confirm}>Excluir</Button>
      </div>
    </Modal>
  )
}
