import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { useItems } from 'hooks/data/item'
import { FormGroup } from 'shared/form/FormGroup'
import { FormInputGroup } from 'shared/form/FormInputGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { classNames } from 'shared/util/class-names'

import classes from './ServiceAdd.module.scss'
import { FormTag } from 'shared/form/FormTag'
import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'
import { FormSelect } from 'shared/form/FormSelect'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'
import { ErrorModal } from 'shared/ui/payment/ErrorModal'
import { useModal } from 'shared/modal'
import { useClients } from 'shared/hooks/data/client'
import { useCities } from 'hooks/data/city'

export const ServiceAdd: React.FC<RouteComponentProps<{ city_id: string; vertical_id: string; client_id: string }>> = ({
  match
}) => {
  const history = useHistory()
  const [state, setState] = useState({
    host_prize: 6,
    name: '',
    price: '',
    products: [] as PartialField<Model.ServiceProduct, 'id'>[]
  })

  const items = useItems().filter(item => !item.item)
  const clients = useClients()
  const cities = useCities()

  const messageModal = useModal(ErrorModal)

  useEffect(() => {
    console.log(state)
  }, [state])

  useEffect(() => {
    if (match.params) {
      setState((state: any) => ({
        ...state,
        vertical_id: match.params.vertical_id,
        client: { id: match.params.client_id },
        city: { id: match.params.city_id }
      }))
    }
  }, [match])

  const [productTags, setProductTags] = useState<string[]>(['Padrão'])

  const handleChange = (key: string) => (value: any) => {
    setState({ ...state, [key]: value })
  }

  const handleTagChange = (tags: string[]) => {
    setProductTags(tags)
    const product = { ...state.products[0], tags: tags.map(tag => ({ name: tag })) }
    const products = [product, ...state.products.splice(1)]
    setState((state: any) => ({ ...state, products }))
  }

  // this handles if is selected or not state?.products
  const handleItem = (item: Model.Item) => (_: React.MouseEvent) => {
    if (state?.products) {
      const index = state?.products.findIndex((product: any) => product.item!.id === item.id)

      if (index >= 0) {
        // remove the item
        const products = state?.products

        setState(({ ...service }) => {
          return { ...service, products: products.filter(product => product.item!.id !== item.id) }
        })
      } else {
        // add the item
        const products = state?.products || []
        products.push({ item_id: item!.id!, item, name: item!.title })

        setState(({ products = [], ...service }) => {
          return { ...service, products }
        })
      }
    }
  }

  const getItemClass = (item: Model.Item) => {
    return classNames(state?.products?.find(({ name }: any) => name === item.title) && classes.selected)
  }

  const save = (event: React.FormEvent) => {
    event.preventDefault()

    const method = 'POST'
    const body = toFormData(state)

    const init: RequestInit = {
      method,
      body
    }

    fetch('/api/services', init).then(res => {
      if (res.ok) {
        messageModal.open({
          title: 'Serviço criado com sucesso!',
          message: '',
          onClosed: () => history.push('/cities')
        })
      } else {
        messageModal.open({
          title: 'Ops!',
          message: 'Houve um erro ao processar o serviço!'
        })
      }
    })
  }

  return (
    <Container className={classes.container}>
      <Card shadow variant="light">
        <form className={classes.wrapper} onSubmit={save}>
          <FormGroup label="Nome do serviço">
            <FormText onChange={handleChange('name')} value={state?.name} required />
          </FormGroup>
          <FormGroup label="Vertical">
            <FormSelect required onChange={handleChange('vertical_id')}>
              <option>Selecione</option>
              <option value={'1'}>Imóveis</option>
              <option value={'2'}>Carros</option>
            </FormSelect>
          </FormGroup>
          <FormGroup label="Valor">
            <FormInputGroup>
              <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
              <FormMasked mask="currency" onChange={handleChange('price')} value={state?.price?.toString()} required />
            </FormInputGroup>
          </FormGroup>
          <h3 className={classes.title}>Entregável principal</h3>
          <ul className={classes.items}>
            {items
              ?.filter(item => item.id < 3)
              .map((item, i) => (
                <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
                  <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
                  <p>{item.title}</p>
                </li>
              ))}
          </ul>
          <h3 className={classes.title}>Entregáveis adicionais</h3>
          <ul className={classes.items}>
            {items
              ?.filter(item => item.id >= 3)
              .map((item, i) => (
                <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
                  <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
                  <p>{item.title}</p>
                </li>
              ))}
          </ul>
          <FormGroup>
            <h3>ADICIONAR CATEGORIAS</h3>
            <small>Separe as tags com vírgula</small>
            <FormTag readOnly={state?.products?.[0] === undefined} onChange={handleTagChange} value={productTags} />
          </FormGroup>
          <FormGroup label="Prazo padrão de hospedagem do tour">
            <FormInputGroup>
              <FormText onChange={handleChange('host_prize')} value={`${state?.host_prize || 6}`} required />
              <FormInputGroup.Append>meses</FormInputGroup.Append>
            </FormInputGroup>
          </FormGroup>
          <FormGroup label="Cliente">
            <FormSelect required onChange={id => setState((state: any) => ({ ...state, client: { id } }))}>
              <option>Selecione</option>
              {clients?.map((client, index) => (
                <option key={index} value={client.id}>
                  ID {client.id} - {client.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup label="Cidade">
            <FormSelect onChange={handleChange('city_id')}>
              <option>Selecione</option>
              {cities?.map((city, index) => (
                <option key={index} value={city.id}>
                  ID {city.id} - {city.name} - {city.state}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <div className={classes.row}>
            <Button outline>Cancelar</Button>
            <Button>Salvar</Button>
          </div>
        </form>
      </Card>
    </Container>
  )
}
