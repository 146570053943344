import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import Label from 'components/common/Label'
import { FormTitle } from 'components/common/Form'

import { useClients } from 'hooks/data/client'
import { useFilterContext } from '../hooks/filters'

const animatedComponents = makeAnimated()

function map(clients: any[]) {
  if (clients === undefined) {
    return []
  }

  return clients?.map((client: any) => {
    return {
      value: client.id,
      label: client.name
    }
  })
}

export const Clients = () => {
  const clients = useClients()
  const [, filter] = useFilterContext()

  const options = map(clients!)

  const onChange = (selected: any) => filter('clients', selected?.map((select: any) => select.value) ?? null)

  return (
    <Label>
      <FormTitle>Cliente</FormTitle>
      <Select
        placeholder={'Selecione um cliente'}
        closeMenuOnSelect={false}
        components={animatedComponents}
        onChange={onChange}
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        options={options}
      />
    </Label>
  )
}
