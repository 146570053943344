import React, { Fragment, createElement, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'

import { BasicInfo } from './components/BasicInfo'
import { Confirm } from './components/Confirm'
import { PhotographerOrientations } from './components/PhotographerOrientations'
import { Responsible } from './components/Responsible'
import { Scheduling } from './components/Scheduling'

import classes from './SessionAdd.module.scss'

interface SessionRouteProps {
  component: App.SessionAddSection
  exact?: boolean
  path: string
  state: App.ReactState<Model.Session[]>
}

const SessionRoute: React.FC<SessionRouteProps> = ({ component, exact, path, state }) => {
  const render = (props: RouteComponentProps) => createElement(component, { ...props, state })
  return <Route exact={exact} path={path} render={render} />
}

export const SessionAdd: React.FC<RouteComponentProps> = ({ match }) => {
  const state = useState<Model.Session[]>([{} as Model.Session])
  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem>Ensaios</BreadcrumbItem>
        <BreadcrumbItem>Criar ensaio</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <div className={classes.wrapper}>
          <Switch>
            <SessionRoute path={`${match.path}`} exact component={BasicInfo} state={state} />
            <SessionRoute path={`${match.path}/responsible`} component={Responsible} state={state} />
            <SessionRoute path={`${match.path}/confirm`} component={Confirm} state={state} />
            <SessionRoute path={`${match.path}/scheduling`} component={Scheduling} state={state} />
            <SessionRoute path={`${match.path}/photographer`} component={PhotographerOrientations} state={state} />
          </Switch>
        </div>
      </Card>
    </Fragment>
  )
}
