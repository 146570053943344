import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 9;
`

const List = styled.ul`
  margin: 0 0 40px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
`

const Item = styled.li`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #d5d5d5;
  position: relative;
  border: 1px solid transparent;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: #d5d5d5;
    height: 3px;
    transform: translateY(-50%);
    width: 9999px;
    z-index: -1;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &.first {
    &:first-child {
      background: #0aa0d2;
    }
  }

  &.second {
    &:first-child {
      background: #ffd600;

      &:after {
        background: #ffd600;
      }
    }

    &:nth-child(2) {
      background: #0aa0d2;
    }
  }

  &.third {
    &:first-child {
      background: #ffd600;

      &:after {
        background: #ffd600;
      }
    }

    &:nth-child(2) {
      background: #ffd600;

      &:after {
        background: #ffd600;
      }
    }

    &:nth-child(3) {
      background: #0aa0d2;
    }
  }

  &.yellow {
    background: #ffd600;

    &:after {
      background: #ffd600;
    }
  }

  &.blue {
    background: #0aa0d2;
  }
`

interface StepsProps {
  className?: string
}

export const Steps: React.FC<StepsProps> = ({ className }) => (
  <Wrapper>
    <List>
      <Item className={`${className}`} />
      <Item className={`${className}`} />
      <Item className={`${className}`} />
    </List>
  </Wrapper>
)
