import React, { forwardRef, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { IMaskInput } from 'react-imask'
import { format, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import classes from './FormDateRange.module.scss'

registerLocale('ptBR', ptBR)
setDefaultLocale('ptBR')

interface FormDateRangeProps {
  onChange?: (_: [string, string]) => void
  value?: [string?, string?]
}

interface FormDateRangeInputProps {
  onClick?: () => void
  value?: string
}

const FormDateRangeInput = forwardRef<HTMLInputElement, FormDateRangeInputProps>((props, ref) => (
  <IMaskInput mask="00/00/00[00]" inputRef={ref} {...props} />
))

export const FormDateRange: React.FC<FormDateRangeProps> = ({ onChange, value }) => {
  const [end, setEnd] = useState<Date>()
  const [start, setStart] = useState<Date>()
  const handleEnd = (date: Date) => {
    setEnd(date)
    const startDate = isValid(start) ? format(start!, 'yyyy-MM-dd') : ''
    const endDate = isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    onChange?.([startDate, endDate])
  }
  const handleStart = (date: Date) => {
    setStart(date)
    const startDate = isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    const endDate = isValid(end) ? format(end!, 'yyyy-MM-dd') : ''
    onChange?.([startDate, endDate])
  }
  return (
    <div className={classes.input}>
      <div className={classes.wrapper}>
        <DatePicker
          customInput={<FormDateRangeInput />}
          isClearable
          onChange={handleStart}
          dateFormat="dd-MM-y"
          placeholderText="Data início"
          selected={start}
          endDate={end}
          value={value?.[0]}
          startDate={start}
          selectsStart
        />
      </div>
      <div className={classes.wrapper}>
        <DatePicker
          customInput={<FormDateRangeInput />}
          isClearable
          onChange={handleEnd}
          dateFormat="dd-MM-y"
          placeholderText="Data fim"
          selected={end}
          endDate={end}
          value={value?.[1]}
          startDate={start}
          selectsEnd
        />
      </div>
    </div>
  )
}
