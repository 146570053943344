import React, { useEffect, useState } from 'react'

import { classNames } from '../util/class-names'

import classes from './FormSelect.module.scss'

// TODO: remove empty prop and handle it on FormSelect usage

interface FormSelectProps {
  className?: string
  disabled?: boolean
  /** @deprecated */
  empty?: boolean | string
  onChange?: (_: string) => void
  required?: boolean
  multiple?: boolean
  value?: string
}

export const FormSelect: React.FC<FormSelectProps> = ({ className, onChange, value, ...props }) => {
  const [current, setCurrent] = useState(value || '')
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrent(event.target.value)
    onChange?.(event.target.value)
  }
  useEffect(() => setCurrent(value || ''), [value])
  if (props.empty !== undefined) {
    const { children, empty, ...params } = props
    return (
      <select className={classNames(classes.select, className)} onChange={handleChange} value={current} {...params}>
        {empty !== false && <option value="">{empty || ''}</option>}
        {children}
      </select>
    )
  } else {
    return (
      <select className={classNames(classes.select, className)} onChange={handleChange} value={current} {...props} />
    )
  }
}
