import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { DeliverableList } from './deliverable-list/DeliverableList'

export const Deliverables: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={DeliverableList} />
  </Switch>
)
