import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Card.module.scss'

interface CardProps {
  className?: string
  shadow?: boolean
}

export const Card: React.FC<CardProps> = ({ className, shadow, ...props }) => {
  return <div className={classNames('card', shadow && classes.shadow, className)} {...props} />
}
