import React, { useState } from 'react'

import { Button } from 'components/Button'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'

interface ResponsibleValue {
  name: string
  phone: string
  email: string
}

export const Responsible: App.SessionAddSection = ({ history, state: [, setSessions] }) => {
  const [value, setValue] = useState<Partial<ResponsibleValue>>({})
  const handleChange = (key: string) => (input: string) => {
    setValue(value => ({ ...value, [key]: input }))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const responsible_information = {
      name: value.name!,
      phone: value.phone!,
      email: value.email!
    }
    setSessions(sessions => sessions.map(session => ({ ...session, responsible_information })))
    history.push('/sessions/add/confirm')
  }
  return (
    <form onSubmit={handleSubmit}>
      <h1>Responsável pelo ensaio</h1>
      <section>
        <FormGroup label="Responsável pelo ensaio">
          <FormText required onChange={handleChange('name')} value={value.name} />
        </FormGroup>
        <FormGroup label="Telefone">
          <FormMasked mask="phone" required onChange={handleChange('phone')} value={value.phone} />
        </FormGroup>
        <FormGroup label="E-mail">
          <FormText type="email" required onChange={handleChange('email')} value={value.email} />
        </FormGroup>
      </section>
      <Button size="lg" outline onClick={history.goBack}>
        VOLTAR
      </Button>{' '}
      <Button size="lg">AVANÇAR →</Button>
    </form>
  )
}
