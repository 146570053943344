import React from 'react'

import { Table, TableHeader, TableColumn, TableBody, TableRow } from 'shared/table'
import { Icon } from 'shared/ui/icon'
import { Button } from 'shared/ui/button/Button'
import { useModal } from 'shared/modal'
import CalendarModal from 'shared/ui/modals/calendar'
import DeleteCalendarModal from 'shared/ui/modals/calendar/delete'

import classes from './Calendars.module.scss'

interface CityProps {
  city: Partial<Model.City>
}

function getItemString(id: number) {
  switch (id) {
    case 5:
      return 'Tour 3D'
    case 7:
      return 'Foto PRO'

    default:
      return 'Outro'
  }
}

export const Calendars: React.FC<CityProps> = ({ city }) => {
  const modal = useModal(CalendarModal)
  const deleteModal = useModal(DeleteCalendarModal)

  const open = () => modal.open({ city })

  return (
    <>
      <Button onClick={open} style={{ marginLeft: 'auto' }} outline>
        Novo calendário
      </Button>
      <Table>
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn size={2}>Entregável</TableColumn>
          <TableColumn size={2}>Título</TableColumn>
          <TableColumn size={4}>Descrição</TableColumn>
          <TableColumn size={3}>Tipo</TableColumn>
          <TableColumn size={6}>Link</TableColumn>
          <TableColumn size={6} />
        </TableHeader>
        <TableBody>
          {city.calendars?.map((calendar, index) => (
            <TableRow key={index}>
              <TableColumn>{calendar?.id}</TableColumn>
              <TableColumn size={2}>{getItemString(calendar.item_id!)}</TableColumn>
              <TableColumn size={2}>{calendar?.title}</TableColumn>
              <TableColumn size={4}>{calendar?.description}</TableColumn>
              <TableColumn size={3}>Google</TableColumn>
              <TableColumn size={6}>
                <a
                  href={`https://calendar.google.com/calendar/u/0/r?src=${calendar?.associable?.external_calendar_id}`}
                  rel="noopener noreferrer"
                  target="_blank">
                  Ver calendário
                  <Icon.Link style={{ marginLeft: '6px' }} />
                </a>
              </TableColumn>

              <TableColumn className={classes.row} size={6}>
                <Button onClick={() => modal.open({ city, calendar })}>Editar</Button>
                <Button onClick={() => deleteModal.open({ city, calendar })}>Deletar</Button>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default Calendars
