import React from 'react'

import { DropdownItem } from 'shared/bootstrap/dropdown/DropdownItem'

import { useInputSelectContext } from './InputSelect'

interface InputSelectItemProps {
  children: string
  className?: string
  value: number | string
}

export const InputSelectItem: React.FC<InputSelectItemProps> = ({ children, value, ...props }) => {
  const inputSelectContext = useInputSelectContext()
  const handleClick = () => inputSelectContext.onClick([value, children])
  return <DropdownItem children={children} onClick={handleClick} {...props} />
}
