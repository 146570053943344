import React from 'react'
import { useHistory } from 'react-router-dom'

import classes from './Table.module.scss'

import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { Badge } from 'shared/ui/badge/Badge'
import { classNames } from 'shared/util/class-names'

const handleDate = (handle: any) => {
  let date = new Date(handle)
  return !date.getDate() ? 'não agendada' : date.toLocaleDateString()
}

export const Session = (props: any) => {
  const { index, session } = props
  const history = useHistory()

  const className = classNames(
    classes.row,
    classes.clickable,
    index % 2 === 0 ? classes.rowBackDark : classes.rowBackLight
  )

  const onClick = () => {
    history.push(`/sessions/${session?.id}`)
  }

  return (
    <>
      <div onClick={onClick} className={className}>
        <div className={classes.col}>
          {session?.id}
          <br />
          <Badge color={getSessionStatusColor(session?.status)}>{getSessionStatusDescription(session?.status)}</Badge>
        </div>
        <div className={classNames(classes.col, classes.textLeft)}>{handleDate(session?.scheduled_for)}</div>
        <div className={classNames(classes.col3, classes.textLeft)}>{session?.address?.formatted_address}</div>
        <div className={classNames(classes.col, classes.textLeft)}>{session?.service?.name}</div>
        <div className={classNames(classes.col, classes.textLeft)}>{session?.client?.name}</div>
        {/* TODO: solve price information inside this div */}
        <div className={classNames(classes.col, classes.textLeft)}>-</div>
      </div>
    </>
  )
}
