import React, { useRef, useState } from 'react'

import classes from './FormTag.module.scss'

interface FormTagProps {
  onChange?: (_: string[]) => void
  value?: string[]
  readOnly?: boolean
}

export const FormTag: React.FC<FormTagProps> = ({ onChange, value, ...props }) => {
  const [current, setCurrent] = useState('')
  const [tags, setTags] = useState<string[]>(value || [])
  const input = useRef<HTMLInputElement>(null)
  const addTag = (tag: string) => {
    tag = tag.replace(',', '').trim()
    if (tag.length > 0 && !tags.includes(tag)) {
      setTags(tags => [...tags, tag])
      onChange?.([...tags, tag])
    }
  }
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = event.target.value
    addTag(input)
    setCurrent('')
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.indexOf(',') >= 0) {
      const [input] = event.target.value.split(',')
      addTag(input)
      setCurrent('')
    } else {
      setCurrent(event.target.value)
    }
  }
  const handleRemove = (tag: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const value = [...tags.filter(t => t !== tag)]
    setTags(value)
    onChange?.(value)
  }
  return (
    <div className={classes.wrapper}>
      {tags.map(tag => (
        <div className={classes.tag} key={tag}>
          <button className={classes.button} onClick={handleRemove(tag)}>
            &times;
          </button>
          <span>{tag}</span>
        </div>
      ))}
      <input {...props} onBlur={handleBlur} onChange={handleChange} ref={input} value={current} />
    </div>
  )
}
