import React from 'react'

import { Filter } from './Filter'
import { Table } from './Table'
import { Pagination } from './Pagination'

import { SessionsProvider } from './hooks/sessions'
import { LoadingProvider } from './hooks/loading'
import { FilterProvider } from './hooks/filters'
import { PaginationProvider } from './hooks/pagination'
import { MetaProvider } from './hooks/meta'
import { SortProvider } from './hooks/sort'

export const ProviderSessions: React.FC = ({ state }: any) => {
  const [provider] = state

  return (
    <SortProvider>
      <SessionsProvider>
        <LoadingProvider>
          <FilterProvider provider={provider}>
            <PaginationProvider>
              <MetaProvider>
                <Filter />
                <Table />
                <Pagination />
              </MetaProvider>
            </PaginationProvider>
          </FilterProvider>
        </LoadingProvider>
      </SessionsProvider>
    </SortProvider>
  )
}
