import { useEffect, useState } from 'react'

export function useCities() {
  const [cities, setCities] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/cities`)
      .then(response => response.json())
      .then(({ data }) => setCities(data))
  }, [])
  return cities
}
