import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { TourList } from './pages/tour-list/TourList'

export const Matterport: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/tours`} component={TourList} />
    <Redirect to={`${match.url}/tours`} />
  </Switch>
)
