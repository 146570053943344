import React from 'react'

import { Container } from 'shared/ui/container/Container'
import { Card } from 'shared/ui/card/Card'
import { Button } from 'shared/ui/button/Button'

import classes from './Calendar.module.scss'

interface ModalProps {
  close?: () => void
  city: any
  calendar: any
}

export const DeleteCalendarModal: React.FC<ModalProps> = ({ close, calendar, city }) => {
  const deleteCalendar = () => {
    if (city && city.id && calendar && calendar.id) {
      const init: RequestInit = {
        method: 'DELETE'
      }

      fetch(`/api/cities/${city.id}/calendars/${calendar.id}`, init).then(() => window.location.reload())
    } else {
      alert('Recarregue a página e tente novamente.')
    }
  }

  return (
    <Container>
      <Card>
        <h1>Deletar</h1>
        <h2>Você tem certeza?</h2>
        <div className={classes.row}>
          <Button outline onClick={close}>
            Não
          </Button>
          <Button onClick={deleteCalendar}>Sim</Button>
        </div>
      </Card>
    </Container>
  )
}

export default DeleteCalendarModal
