import React from 'react'

import { Button } from 'components/Button'
import { CardGrey } from 'shared/ui/card/CardGrey'
import { parseAddressComponents } from 'shared/util/google-maps'

export const Confirm: App.SessionAddSection = ({ history, state: [sessions, setSessions] }) => {
  const { address, client, cost_center, responsible_information, service } = sessions[0]
  const addr = parseAddressComponents(address?.address_components)
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const requests = sessions.map(session => {
      const data = {
        client: { id: client.id },
        cost_center: { id: cost_center.id },
        service: service,
        address: session.address,
        responsible_information: session.responsible_information,
        vertical_data: session.vertical_data
      }
      const options = {
        body: JSON.stringify(data),
        headers: [['Content-Type', 'application/json']],
        method: 'POST'
      }
      return fetch(`/api/sessions`, options)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Erro ocorreu.')
          }
        })
        .then(({ data }) => data.token)
    })
    Promise.all(requests)
      .then(tokens => {
        setSessions(sessions => {
          return sessions.map((session, i) => Object.assign(session, { token: tokens[i] }))
        })
      })
      .then(() => history.push('/sessions/add/scheduling'))
      .catch(() => alert('Falha ao salvar sessões!'))
  }
  return (
    <form onSubmit={handleSubmit}>
      <h1>Confirmar informações</h1>
      <section>
        <CardGrey>
          <h3>SERVIÇO</h3>
          <p>{client.name}</p>
          <p>{service.name}</p>
          <h3>LOCAL</h3>
          <p>
            {addr?.get('route')}
            {addr?.has('street_number') && `, ${addr?.get('street_number')}`}
          </p>
          <p>
            {addr?.get('sublocality')} - CEP: {addr?.get('postal_code')}
          </p>
          <p>
            {addr?.get('administrative_area_level_1')} - {addr?.get('administrative_area_level_1')}
          </p>
          {sessions.map(({ vertical_data: { area, complement, reference, unit } }, i) => (
            <div key={i}>
              <h3>Ensaio {i + 1}</h3>
              <p>
                Ref: {reference} - Unidade {unit}/{complement} - {area}m<sup>2</sup>
              </p>
            </div>
          ))}
          <h3>RESPONSÁVEL</h3>
          <p>Responsável: {responsible_information.name}</p>
          <p>
            {responsible_information.phone} - {responsible_information.email}
          </p>
        </CardGrey>
      </section>
      <Button size="lg" outline onClick={history.goBack}>
        VOLTAR
      </Button>{' '}
      <Button size="lg">AVANÇAR →</Button>
    </form>
  )
}
