import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Card, CardBody } from 'shared/bootstrap/card'
import { SessionDeliverables } from 'shared/components/deliverables/SessionDeliverables'
import { useSession } from 'shared/hooks/data/session'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
// import { RoundButton } from 'shared/ui/button/RoundButton'
import { Container } from 'shared/ui/container/Container'
// import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'

import { SessionDetail } from './components/SessionDetail'
import { SessionInfo } from './components/SessionInfo'
import { ResponsibleInformation } from './components/ResponsibleInformation'
import { VerticalData } from './components/VerticalData'

import classes from './SessionView.module.scss'

export const SessionView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [param, setParam] = useState(match.params)
  const session = useSession(param)
  const handleChange = () => setParam({ id: param.id })
  // const handleEdit = () => null

  if (!session) {
    return null
  }

  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem to="/sessions">Ensaios</BreadcrumbItem>
        <BreadcrumbItem>Ensaio {session.id}</BreadcrumbItem>
      </Breadcrumb>
      <main className={classNames(classes.wrapper, classes.grid)}>
        <div className={classes.sideline}>
          <SessionDetail session={session} onChange={handleChange} />
          <Card className={classes.card} shadow>
            <CardBody className={classes.providerService}>
              {/*<RoundButton className={classes.edit} type="button" onClick={handleEdit}>
                <Icon.Edit />
              </RoundButton>*/}
              <h6>Fotógrafo</h6>
              <p>{session.provider?.name || '-'}</p>
              <h6>Serviço do Cliente</h6>
              <p>{session.service.name}</p>
            </CardBody>
          </Card>
          <ResponsibleInformation session={session} />
          <VerticalData session={session} />
        </div>
        <div className={classes.sidecolumn}>
          <SessionInfo session={session} />
          <SessionDeliverables className={classes.wrapper} session={session} category="admin" />
        </div>
      </main>
    </Container>
  )
}
