import React from 'react'
import { Link } from 'react-router-dom'

import classes from './BreadcrumbItem.module.scss'

interface BreadcrumbItemProps {
  to?: string
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ children, to }) => {
  return <p className={classes.item}>{to ? <Link to={to}>{children}</Link> : children}</p>
}
