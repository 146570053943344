import React from 'react'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { Table, TableHeader, TableColumn, TableBody, TableRow } from 'shared/table'

import classes from './List.module.scss'
import { FormText } from 'shared/form'
import { Button } from 'shared/ui/button/Button'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toFormData } from 'shared/util/form-data'
import { Icon } from 'shared/ui/icon'

export const List: React.FC = () => {
  const history = useHistory()

  const [cities, setCities] = useState<Model.City[]>([])
  const [search, setSearch] = useState<string>()

  useEffect(() => {
    fetch('/api/cities')
      .then(res => res.json())
      .then(({ data }) => setCities(data))
  }, [])

  const filter = () => {
    const init: RequestInit = {
      method: 'POST',
      body: toFormData({
        filter: {
          search
        }
      })
    }

    fetch('/api/cities/search', init)
      .then(res => res.json())
      .then(({ data }) => setCities(data))
  }

  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      filter()
    }
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>Cidades</BreadcrumbItem>
      </Breadcrumb>
      <Container className={classes.container}>
        <Card className={classes.filter}>
          <h2>Filtro</h2>
          <div className={classes.grid}>
            <FormText onKeyPress={onEnterPress} onChange={setSearch} placeholder="Endereço, outros" />
          </div>
          <div className={classes.row}>
            <Button onClick={filter}>Filtrar</Button>
            <Button to="/cities/add">Nova cidade</Button>
          </div>
        </Card>
        <Card>
          <Table>
            <TableHeader>
              <TableColumn>ID</TableColumn>
              <TableColumn size={2} align="flex-start">
                Nome
              </TableColumn>
              <TableColumn>Sessões</TableColumn>
              <TableColumn>Clientes</TableColumn>
              <TableColumn>Habilitada</TableColumn>
              <TableColumn size={2} />
            </TableHeader>
            <TableBody>
              {cities?.map((city, index) => (
                <TableRow key={index}>
                  <TableColumn onClick={() => history.push(`/cities/${city?.id}`)} className={classes.cursor}>
                    {city?.id}
                  </TableColumn>
                  <TableColumn size={2} align="flex-start">
                    {city?.name}
                  </TableColumn>
                  <TableColumn>{city?.sessions}</TableColumn>
                  <TableColumn>{city?.clients}</TableColumn>
                  <TableColumn>{city?.status & 1 ? <Icon.Check /> : <Icon.Times />}</TableColumn>
                  <TableColumn size={2} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  )
}

export default List
