import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './RoundButton.module.scss'

interface RoundButtonProps {
  active?: boolean
  className?: string
  onClick?: (_: React.MouseEvent) => void
  size?: 'sm' | 'md'
  type?: 'button' | 'reset' | 'submit'
}

export const RoundButton: React.FC<RoundButtonProps> = ({ active, className, size, ...props }) => {
  size = size || 'md'
  const klass = classNames(classes.button, active && classes.active, size && classes[`size-${size}`], className)
  return <button className={klass} {...props} />
}
