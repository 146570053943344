import React, { forwardRef, useEffect, useRef, useState } from 'react'

import { classNames } from '../util/class-names'

import classes from './FormText.module.scss'

interface FormTextProps {
  className?: string
  minLength?: number
  name?: string
  onChange?: (_: string) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  /**
   * @param pattern a regex string to consider valid or invalid input
   */
  pattern?: string
  /**
   * @param message a regex string to consider valid or invalid input
   */
  message?: string
  type?: string
  value?: Nullable<string>
  style?: React.CSSProperties
}

let uniqueId = 0

export const FormText = forwardRef<HTMLInputElement, FormTextProps>(
  ({ className, onChange, message, value, ...props }, ref) => {
    const id = useRef(`form-text-${++uniqueId}`)
    const [current, setCurrent] = useState(value || '')
    const params = {
      className: classNames(classes.input, className),
      id: id.current,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrent(event.target.value)
        onChange?.(event.target.value)
      },
      ref: ref,
      value: current
    }
    useEffect(() => setCurrent(value ?? ''), [value])
    return (
      <input {...params} {...props} onInvalid={message ? e => e.currentTarget.setCustomValidity(message) : undefined} />
    )
  }
)
