import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button } from 'shared/ui/button/Button'
import { Table, TableBody, TableRow } from 'shared/table'
import { TableHeader } from 'shared/table/TableHeader'
import { TableColumn } from 'shared/table/TableColumn'
import { VoucherModal } from 'components/users/VoucherModal'
import { useModal } from 'shared/modal'

import { formatDecimal } from 'shared/util/number'

export const Vouchers: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [vouchers, setVouchers] = useState<Model.Voucher[]>()
  const [service, setService] = useState<Model.Service>()

  const voucherModal = useModal(VoucherModal)

  useEffect(() => {
    fetch(`/api/services/${match.params.id}`)
      .then(response => response.json())
      .then(({ data }) => setService(data))
  }, [match.params.id])

  useEffect(() => {
    fetch(`/api/services/${match.params.id}/vouchers`)
      .then(response => response.json())
      .then(({ data }) => setVouchers(data))
  }, [match.params.id])

  if (!vouchers || !service) {
    return null
  }

  const openVoucherModal = () => voucherModal.open({ service })

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={openVoucherModal} outline>
          CRIAR PACOTE
        </Button>
      </div>

      <p>
        Quando o cliente desejar adquirir pacotes, as regras de preço não serão consideradas para o valor a pagar. Ao
        invés, a regra de pacote será desconsiderada.
      </p>
      <Table>
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>Título</TableColumn>
          <TableColumn>Descrição</TableColumn>
          <TableColumn>Quantidade</TableColumn>
          <TableColumn>Preço da Unidade</TableColumn>
          <TableColumn />
        </TableHeader>
        <TableBody>
          {vouchers.map((voucher, index) => (
            <TableRow key={index}>
              <TableColumn>{voucher.id}</TableColumn>
              <TableColumn>{voucher.title}</TableColumn>
              <TableColumn>{voucher?.description}</TableColumn>
              <TableColumn>{voucher.quantity}</TableColumn>
              <TableColumn>R$ {formatDecimal(voucher.unit_price)}</TableColumn>
              <TableColumn></TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
