import { useCallback, useEffect, useRef } from 'react'

export function useDebouncedCallback<T extends (...args: any[]) => any>(fn: T, deps: any[], time: number) {
  const handle = useRef<number>()
  const executor = useRef<T>()
  useEffect(() => {
    executor.current = fn
  }, [fn])
  return useCallback(
    (...args) => {
      clearTimeout(handle.current)
      handle.current = window.setTimeout(() => executor.current?.(...args), time)
    },
    [time]
  )
}
