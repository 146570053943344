import React, { Fragment, createElement } from 'react'
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { useProvider } from 'hooks/data/providers'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'

import { ProviderCalendar } from './tabs/ProviderCalendar'
import { ProviderInfo } from './tabs/ProviderInfo'
import { ProviderPayments } from './tabs/ProviderPayments'
import { ProviderRegions } from './tabs/ProviderRegions'
import { ProviderServices } from './tabs/ProviderServices'
import { ProviderSessions } from './tabs/ProviderSessions'

// import { Ratings } from './ratings/Ratings'

import classes from './ProviderView.module.scss'

export const ProviderView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [provider, setProvider] = useProvider(match.params)
  const state = [provider!, setProvider] as App.ReactState<any>
  const section = (component: React.FC<{ state: App.ReactState<any> }>) => ({
    render: () => createElement(component, { state })
  })
  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem>Fornecedores</BreadcrumbItem>
        <BreadcrumbItem>Visualizar fornecedor</BreadcrumbItem>
      </Breadcrumb>
      {provider && (
        <div className={classes.container}>
          <Card className={classes.info}>
            <div className={classes.icon}>
              <img src={provider?.user?.profile_image_path} alt="Imagem do fornecedor" />
            </div>
            <h1>{provider.name}</h1>
            <h2>
              {provider?.neighborhood?.name}, {provider?.neighborhood?.city?.name}
            </h2>
            <div className={classes.divider} />
            {provider?.cpf && <h2>CPF {provider?.cpf}</h2>}
            {provider?.rg && <h2>RG {provider?.rg}</h2>}
            {provider?.user?.email && <h2>{provider?.user?.email}</h2>}
            {provider?.user?.phone && <h2>{provider?.user?.phone}</h2>}
          </Card>
          <div className={classes.column}>
            <Card className={classes.sections}>
              <nav className={classes.nav}>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/sessions`}>
                  Ensaios
                </NavLink>
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/services`}>
                  Serviços
                </NavLink>
                {/* <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/regions`}>
                  Regiões
                </NavLink> */}
                <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/info`}>
                  Dados básicos
                </NavLink>
                {/* <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/payments`}>
                  Pagamentos
                </NavLink> */}
                {/* <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/calendar`}>
                  Agenda
                </NavLink> */}
              </nav>
              <Switch>
                <Route path={`${match.path}/sessions`} {...section(ProviderSessions)} />
                <Route path={`${match.path}/services`} {...section(ProviderServices)} />
                <Route path={`${match.path}/regions`} {...section(ProviderRegions)} />
                <Route path={`${match.path}/info`} {...section(ProviderInfo)} />
                <Route path={`${match.path}/payments`} {...section(ProviderPayments)} />
                <Route path={`${match.path}/calendar`} {...section(ProviderCalendar)} />
                <Redirect to={`${match.url}/sessions`} />
              </Switch>
            </Card>
            {/* <Ratings provider={provider} /> */}
          </div>
        </div>
      )}
    </Fragment>
  )
}
