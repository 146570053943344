import React, { Fragment } from 'react'

import { Card, CardBody } from 'shared/bootstrap/card'
import { RoundButton } from 'shared/ui/button/RoundButton'
import { Icon } from 'shared/ui/icon'

import classes from './SessionInfo.module.scss'

interface SessionInfoProps {
  session: Resource.SessionResource
}

export const SessionInfo: React.FC<SessionInfoProps> = ({ session }) => {
  const hasCheckListLink = typeof session.additional_info.checklist_link === 'string'
  const hasDeliveryLink = typeof session.additional_info.delivery_link === 'string'
  const handleLinkCopy = (url: string) => () => navigator.clipboard.writeText(url)
  const handleLinkOpen = (url: string) => () => window.open(url)
  return (
    <Fragment>
      <Card className={classes.card} shadow>
        <CardBody className={classes.body}>
          <div className={classes.additional}>
            <p className={classes.title}>Informações adicionais</p>
            <p className={classes.info}>{session.vertical_data?.additional_info || '-'}</p>
          </div>
          {(hasCheckListLink || hasDeliveryLink) && (
            <div className={classes.extra}>
              <p className={classes.title}>Informações extras</p>
              {hasDeliveryLink && (
                <div className={classes.deliveryLink}>
                  <span className={classes.label}>Link de Entrega:</span>
                  {session.additional_info?.delivery_link ? (
                    <Fragment>
                      <a
                        className={classes.link}
                        href={session.additional_info.delivery_link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {session.additional_info.delivery_link}
                      </a>
                      <RoundButton type="button" onClick={handleLinkCopy(session.additional_info.delivery_link)}>
                        <Icon.Clipboard />
                      </RoundButton>
                      <RoundButton type="button" onClick={handleLinkOpen(session.additional_info.delivery_link)}>
                        <Icon.ExternalLinkAlt />
                      </RoundButton>
                    </Fragment>
                  ) : (
                    '-'
                  )}
                </div>
              )}
              {hasCheckListLink && (
                <div className={classes.deliveryLink}>
                  <span className={classes.label}>Link de Check-List:</span>
                  {session.additional_info?.checklist_link ? (
                    <Fragment>
                      <a
                        className={classes.link}
                        href={session.additional_info.checklist_link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {session.additional_info.checklist_link}
                      </a>
                      <RoundButton type="button" onClick={handleLinkCopy(session.additional_info.checklist_link)}>
                        <Icon.Clipboard />
                      </RoundButton>
                      <RoundButton type="button" onClick={handleLinkOpen(session.additional_info.checklist_link)}>
                        <Icon.ExternalLinkAlt />
                      </RoundButton>
                    </Fragment>
                  ) : (
                    '-'
                  )}
                </div>
              )}
            </div>
          )}
          <div className={classes.responsible}>
            <p className={classes.title}>Quem receberá o fotógrafo</p>
            <div className={classes.info}>
              <span>Nome</span>
              <span>{session.scheduling_responsible_info.name}</span>
              <span>E-mail</span>
              <span>{session.scheduling_responsible_info.email}</span>
              <span>Telefone</span>
              <span>{session.scheduling_responsible_info.phone}</span>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className={classes.card} shadow>
        <CardBody className={classes.report}>
          <div>
            <p className={classes.title}>Observações do fotógrafo</p>
            <p className={classes.info}>{session.report?.notes || '-'}</p>
            {session.report?.additional_info?.displacement === true && (
              <p className={classes.info}>
                <strong>Cancelado com deslocamento</strong>
              </p>
            )}
            {session.report?.additional_info?.displacement === false && (
              <p className={classes.info}>
                <strong>Cancelado sem deslocamento</strong>
              </p>
            )}
          </div>
          {session.report?.additional_info?.lacking_areas && (
            <div>
              <p className={classes.title}>Ambientes que faltaram</p>
              <p className={classes.info}>{session.report?.additional_info?.lacking_areas || '-'}</p>
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  )
}
