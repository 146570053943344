import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Badge.module.scss'

interface BadgeProps {
  className?: string
  color?: string
  size?: 'sm' | 'md'
  variant?: 'danger' | 'primary' | 'secondary' | 'success'
}

export const Badge: React.FC<BadgeProps> = ({ className, children, color, size = 'md' }) => {
  const klass = classNames(classes.badge, classes[`badge-${size}`], className)
  const style = {
    backgroundColor: color
  }
  return (
    <span className={klass} style={style}>
      {children}
    </span>
  )
}
