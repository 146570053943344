import React from 'react'

import { classNames } from '../../util/class-names'

import { useDropdownContext } from './Dropdown'

import classes from './DropdownMenu.module.scss'

interface DropdownMenuProps {
  className?: string
  opened?: boolean
  shadow?: boolean
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ children, className, opened, shadow }) => {
  const dropdownContext = useDropdownContext()
  const show = (dropdownContext?.opened || opened) && 'show'
  return (
    <ul className={classNames('dropdown-menu', show, shadow !== false && classes.shadow, className)}>{children}</ul>
  )
}
