import React, { useState } from 'react'

import { FormGroup, FormText } from 'shared/form'
import { Saving } from 'shared/ui/saving/Saving'
import { toFormData } from 'shared/util/form-data'
import { FormDateRange } from 'shared/form/FormDateRange'

interface EditServiceInformationProps {
  service: Model.Service
}

export const EditServiceInformation: React.FC<EditServiceInformationProps> = ({ service }) => {
  const [host_prize, setHostPrize] = useState(service.host_prize)
  const [description, setDescription] = useState(service.description)
  const [enabled_at, setEnabledAt] = useState<string | undefined>(service.enabled_at)
  const [disabled_at, setDisabledAt] = useState<string | undefined>(service.disabled_at)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
  }

  const onDateRangeChange = (dates: [string, string]) => {
    setEnabledAt(dates[0])
    setDisabledAt(dates[1])
  }

  const onSave = () => {
    const url = `/api/services/${service.id}`
    const init: RequestInit = {
      method: 'POST',
      body: toFormData({ host_prize, client: service.client, enabled_at, disabled_at, description })
    }

    return fetch(url, init)
  }

  return (
    <form onSubmit={onSubmit}>
      <FormGroup label="Descrição (opcional)">
        <FormText value={description} onChange={setDescription} />
      </FormGroup>
      <FormGroup label="Prazo de hospedagem (opcional)">
        <FormText value={host_prize?.toString()} onChange={value => setHostPrize(Number(value))} />
      </FormGroup>
      <FormGroup label="Período de vigência (opcional)">
        <FormDateRange
          onChange={onDateRangeChange}
          value={[new Date(enabled_at!).toDateString() ?? '', new Date(disabled_at!).toDateString() ?? '']}
        />
      </FormGroup>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Saving onSave={onSave} />
      </div>
    </form>
  )
}
