import React, { useRef /*, useState*/ } from 'react'

import { useInputRadioContext } from './InputRadioContext'

interface InputRadioProps {
  onChange?: (_: string) => void
  checked?: boolean
  value?: string
}

let uniqueId = 0

export const InputRadio: React.FC<InputRadioProps> = ({ children, onChange, ...props }) => {
  const name = useInputRadioContext()
  const id = useRef(`input-radio-${++uniqueId}`)
  const input = {
    id: id.current,
    name: name,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange?.(event.target.value)
      }
    },
    type: 'radio'
  }
  return (
    <div className="form-check">
      <input className="form-check-input" {...input} {...props} />
      <label className="form-check-label" htmlFor={id.current}>
        {children}
      </label>
    </div>
  )
}
