import React from 'react'
import { VerticalHousings } from './Housing'

enum VerticalType {
  HOUSING = 1,
  CARS = 2
}

interface VerticalProps {
  verticalId?: VerticalType
  onChange?: (value: any) => void
  value?: any
  readonly?: boolean
  vision?: 'admin' | 'client' | undefined
}

export const Vertical: React.FC<VerticalProps> = ({
  //verticalId,
  onChange,
  value,
  readonly = false,
  vision = 'admin'
}) => {
  return <VerticalHousings onChange={onChange} value={value} readonly={readonly} vision={vision} />

  // throw new Error('verticalId not defined.')
}
