import React, { Fragment, useState } from 'react'
import { format } from 'date-fns'

import { PhotoWatermarkSessionModal } from 'shared/components/sessions/PhotoWatermarkSessionModal'
import { FormUpload } from 'shared/form/FormUpload'
import { useModal } from 'shared/modal'

import { Button } from '../../ui/button/Button'
import { RoundButton } from '../../ui/button/RoundButton'
import { Icon } from '../../ui/icon'
import { classNames } from '../../util/class-names'
import { toFormData } from '../../util/form-data'

import classes from './SessionDeliverableEditPhotos.module.scss'

interface SessionDeliverableEditPhotosProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverablePhoto>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableEditPhotos: React.FC<SessionDeliverableEditPhotosProps> = ({
  category,
  product,
  session
}) => {
  const [products, setProducts] = useState(product.deliverables)
  const [dragging, setDragging] = useState(-1)
  const [dragged, setDragged] = useState(-1)
  const photoWatermarkModal = useModal(PhotoWatermarkSessionModal)
  const handleChange = (tag_id: number) => (files: File[]) => {
    const denied = files.filter(file => {
      if (file.size > 3 * 1024 * 1024) {
        return true
      } else {
        const body = toFormData({ tag_id, file })
        fetch(`/api/sessions/${session.id}/${product.id}/photos`, { body, method: 'POST' })
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              alert(`Falha ao realizar upload da foto ${file.name}!`)
              throw new Error('Upload failed')
            }
          })
          .then(({ data }) => setProducts(products => [...products, data]))
        return false
      }
    })
    if (denied.length) {
      const message = `As fotos ultrapassam o tamanho máximo permitido:\n${denied.map(file => `${file.name}\n`)}`
      alert(message)
    }
  }
  const handleDelete = (photo: Resource.SessionDeliverablePhoto) => (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/photos/${photo.id}`
    fetch(url, { method: 'DELETE' }).then(response => {
      if (response.ok) {
        setProducts(products => products.filter(({ id }) => id !== photo.id))
      } else {
        alert('Falha ao excluir foto!')
      }
    })
  }

  const handleDownload = (fileName: string, watermark: boolean, tag_id?: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/photos/download`
    const body = toFormData({ tag_id, watermark })

    fetch(url, { method: 'POST', body })
      .then(response => {
        if (response.ok) {
          return response.blob()
        } else {
          throw new Error(`Download failed`)
        }
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName + '.zip'
        a.click()
      })
  }

  const handleFeatured = (photo: Resource.SessionDeliverablePhoto) => (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/photos/${photo.id}`
    const body = toFormData({ is_featured: !photo.is_featured })

    fetch(url, { method: 'POST', body }).then(response => {
      if (response.ok) {
        if (photo.is_featured) {
          setProducts(products => products.map(product => ({ ...product, is_featured: false })))
        } else {
          setProducts(products => products.map(product => ({ ...product, is_featured: product.id === photo.id })))
        }
      } else {
        alert('Falha ao destacar a foto!')
      }
    })
  }
  const handlePhotoWatermarkModal = (event: React.MouseEvent) => {
    event.preventDefault()
    photoWatermarkModal.open({ session })
  }
  const photoProps = (photo: Resource.SessionResourceDeliverablePhoto, i: number) => {
    return {
      className: classNames(
        classes.photo,
        dragging === photo.id && classes.dragging,
        dragging > 0 && dragged === photo.id && classes.dragged
      ),
      draggable: true,
      onDragEnd: () => setDragging(-1),
      onDragStart: () => setDragging(photo.id),
      onDragEnter: () => setDragged(photo.id),
      onDragOver: (event: React.DragEvent) => event.preventDefault(),
      onDrop: (event: React.DragEvent) => {
        event.preventDefault()
        const photo = products.find(({ id }) => dragging === id)
        if (photo && dragged !== dragging) {
          const order = products.findIndex(({ id }) => dragged === id)
          const url = `/api/sessions/${session.id}/${product.id}/photos/${photo.id}`
          const body = toFormData({ order })
          fetch(url, { body, method: 'POST' })
            .then(() =>
              setProducts(products => {
                const photos = [...products]
                const draggingPhoto = photos.find(({ id }) => dragging === id)!
                // const draggedPhoto = photos.find(({ id }) => dragged === id)!
                const draggingIndex = photos.findIndex(({ id }) => dragging === id)
                const draggedIndex = photos.findIndex(({ id }) => dragged === id)
                // photos.splice(draggedIndex, 1, draggingPhoto)
                photos.splice(draggingIndex, 1)
                photos.splice(draggedIndex, 0, draggingPhoto)
                return photos
              })
            )
            .catch(() => alert('Falha ao reordenar fotos!'))
        }
      }
    }
  }

  const handleFileName = (name: string = 'Fotos') => {
    return `ID_${session.id}_${name}_${format(Date.now(), 'dd_MM_yyyy_HH_mm')}`
  }
  const handleLinkCopy = (url: string) => () => navigator.clipboard.writeText(url)
  const handleLinkOpen = (url: string) => () => window.open(url)
  return (
    <div className={classes.content}>
      {(category === 'admin' || category === 'client') && (
        <div className={classes.deliveryLink}>
          <span className={classes.label}>Link para baixar fotos:</span>{' '}
          {product.content_url ? (
            <Fragment>
              <a className={classes.link} href={product.content_url} target="_blank" rel="noopener noreferrer">
                {product.content_url}
              </a>
              <RoundButton type="button" onClick={handleLinkCopy(product.content_url)}>
                <Icon.Clipboard />
              </RoundButton>
              <RoundButton type="button" onClick={handleLinkOpen(product.content_url)}>
                <Icon.ExternalLinkAlt />
              </RoundButton>
            </Fragment>
          ) : (
            '-'
          )}
        </div>
      )}
      {category === 'admin' && (
        <div style={{ marginBottom: '1rem' }}>
          <Button onClick={handlePhotoWatermarkModal} outline size="sm">
            Configurar marca d'água no ensaio
          </Button>
        </div>
      )}
      <div className={classes.deliverables}>
        {product.item.tags.map(tag => {
          const photos = products.filter(photo => photo.tag_id === tag.id).sort((a, b) => a.order - b.order)
          return (
            <div key={tag.id} className={classes.deliverable}>
              {category !== 'client' && (
                <FormUpload accept=".jpg,.jpeg,.png" multiple onChange={handleChange(tag.id!)}>
                  <span>Fazer upload das fotos (formatos JPG ou PNG) de até 3 MB</span>
                </FormUpload>
              )}
              <p className={classes.title}>
                <Button onClick={handleDownload(handleFileName(tag.name), false, tag.id)}>
                  <Icon.Download />
                </Button>
                <span className={classes.tagName}>{tag.name}</span>
                <span>Marca d'água:</span>
                <Button onClick={handleDownload(handleFileName(tag.name), true, tag.id)}>
                  <Icon.CropAlt /> <Icon.Download />
                </Button>
              </p>
              <div className={classes.photos}>
                {photos.map((photo, i) => (
                  <div key={photo.id} {...photoProps(photo, i)}>
                    <img src={photo.url} alt={'tag'} />
                    <button
                      className={classNames(classes.featured, photo.is_featured ? classes.active : '')}
                      onClick={handleFeatured(photo)}>
                      <Icon.Heart />
                    </button>
                    {category !== 'client' && (
                      <button className={classes.remove} onClick={handleDelete(photo)}>
                        <Icon.Times size="lg" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.rowbutton} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleDownload(handleFileName(), false)} size="sm">
          Baixar todas as fotos
        </Button>
        <Button onClick={handleDownload(handleFileName(), true)} size="sm" outline>
          Baixar todas as fotos com marca d'água
        </Button>
      </div>
    </div>
  )
}
