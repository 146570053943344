import React, { useEffect, useState } from 'react'

import { useItems } from 'hooks/data/item'
import { FormGroup } from 'shared/form/FormGroup'
import { classNames } from 'shared/util/class-names'

import classes from './ServiceItems.module.scss'
import { FormTag } from 'shared/form/FormTag'

interface ServiceItemsProps {
  index?: number
  onChange?: (_: any) => void
  service: Model.Service
}

export const ServiceItems: React.FC<ServiceItemsProps> = ({ index, onChange, service }) => {
  const [state, setState] = useState<Model.Service>(service)
  const items = useItems().filter(item => !item.item)

  useEffect(() => {
    if (!state.products) {
      setState(state => ({ ...state, products: [] }))
    }
  }, [state])

  const [productTags, setProductTags] = useState(state?.products?.[0]?.tags?.map(tag => tag.name ?? ''))

  const handleTagChange = (tags: string[]) => {
    setProductTags(tags)
    const product = { ...state.products[0], tags: tags.map(tag => ({ name: tag })) }
    const products = [product, ...state.products.splice(1)]
    setState(state => ({ ...state, products }))
  }

  // this handles if is selected or not state.products
  const handleItem = (item: Model.Item) => (_: React.MouseEvent) => {
    if (state.products) {
      const index = state.products.findIndex(product => product.item!.id === item.id)

      if (index >= 0) {
        // remove the item
        const products = state.products

        // if item.id is 1 or 2, should add the other
        // if (item.id < 3) {
        //   const itemToAdd = items.find(_item => _item.id === (item.id === 1 ? 2 : 1))

        //   products.push({item_id: itemToAdd!.id!, item: itemToAdd, name: itemToAdd!.title})
        // }

        setState(({ ...service }) => {
          return { ...service, products: products.filter(product => product.item!.id !== item.id) }
        })
      } else {
        // add the item
        const products = state.products
        products.push({ item_id: item!.id!, item, name: item!.title })

        // if item.id is 1 or 2, should remove the other
        // if (item.id < 3) {
        //   const indexToRemove = products.findIndex(product => product.item!.id === (item!.id === 1 ? 2 : 1))

        //   // remove from array only if array contains it
        //   if (indexToRemove !== -1) {
        //     products.splice(indexToRemove, 1)
        //   }
        // }

        setState(({ products = [], ...service }) => {
          return { ...service, products }
        })
      }
    }

    setProductTags(productTags)
  }

  const getItemClass = (item: Model.Item) => {
    return classNames(state.products?.find(({ name }) => name === item.title) && classes.selected)
  }
  useEffect(() => {
    onChange?.(state)
    // TODO
    // eslint-disable-next-line
  }, [state])
  return (
    <div className={classes.wrapper}>
      <h3 className={classes.title}>ENTREGÁVEL LOCAL</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => item.id < 3)
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <h3 className={classes.title}>OUTROS ENTREGÁVEIS</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => item.id >= 3)
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <FormGroup>
        <h3>ADICIONAR CATEGORIAS</h3>
        <small>Separe as tags com vírgula</small>
        <FormTag readOnly={state?.products?.[0] === undefined} onChange={handleTagChange} value={productTags} />
      </FormGroup>
    </div>
  )
}
