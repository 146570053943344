import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { ClientList } from './client-list/ClientList'
import { ClientView } from './client-view/ClientView'
import { ClientAdd } from './client-add/ClientAdd'

export const Clients: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={ClientList} />
    <Route path={`${match.path}/add`} component={ClientAdd} />
    <Route path={`${match.path}/:id`} component={ClientView} />
  </Switch>
)
