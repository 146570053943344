import React from 'react'

interface ModalFooterProps {
  content?: 'between'
}

const definitions = {
  content: {
    between: ['justifyContent', 'space-between']
  }
}

function getStyle(props: ModalFooterProps = {}) {
  return Object.fromEntries(
    Object.entries(props).map(([key, value]) => {
      if (key in definitions) {
        const definition = (definitions as any)[key]
        if (value in definition) {
          return definition[value]
        }
      }
      return []
    })
  )
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, ...props }) => {
  const style = { display: 'flex', ...getStyle(props) }
  return <div style={style}>{children}</div>
}
