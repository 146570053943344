import React, { useState } from 'react'

import { Button } from 'components/Button'
import { useAuthContext } from 'components/auth/AuthContext'
import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'

import classes from './Login.module.scss'

export const Login: React.FC = () => {
  const [, setUser] = useAuthContext()
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const handleChange = (key: keyof typeof state) => (value: string) => {
    setState(state => ({ ...state, [key]: value }))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const data = new FormData()
    data.set('email', state.email)
    data.set('password', state.password)
    const request = {
      body: data,
      method: 'POST'
    }
    fetch(`/api/auth/login`, request)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      .then(({ data }) => {
        if (data?.scope !== 'admin') {
          return fetch(`/api/auth/logout`, { method: 'POST' }).then(() => setUser(null))
        } else {
          setUser(data)
        }
      })
      .catch(() => {
        alert('Falha no login')
      })
  }
  return (
    <div className={classes.wrapper}>
      <h2>Faça o seu login</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup label="E-mail">
          <FormText type="email" onChange={handleChange('email')} required />
        </FormGroup>
        <FormGroup label="Senha">
          <FormText type="password" onChange={handleChange('password')} required />
        </FormGroup>
        <div className={classes.action}>
          <Button className={classes.button}>ENTRAR</Button>
        </div>
      </form>
    </div>
  )
}
