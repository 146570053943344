import React from 'react'

import { classNames } from '../util/class-names'

import classes from './TableColumn.module.scss'

interface TableColumnProps {
  align?: 'center' | 'flex-start' | 'flex-end'
  verticalAlign?: 'center' | 'flex-start' | 'flex-end'
  className?: string
  size?: number
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
}

export const TableColumn: React.FC<TableColumnProps> = ({
  align,
  verticalAlign = 'center',
  className,
  size,
  ...props
}) => {
  const params = {
    className: classNames(className, classes.column, props.onClick && classes.clickable),
    style: {
      flexBasis: 0,
      flexGrow: size || 1,
      alignItems: align,
      justifyContent: verticalAlign
    }
  }
  return <div {...props} {...params} />
}
