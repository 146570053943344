import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { InputDecimal } from 'shared/bootstrap/form/InputDecimal'
import { InputRadio } from 'shared/bootstrap/form/InputRadio'
import { InputRadioContextProvider } from 'shared/bootstrap/form/InputRadioContext'

import { FormLogo } from 'shared/form/FormLogo'

import classes from './PhotoWatermarkConfig.module.scss'

interface PhotoWatermarkConfigProps {
  onChange?: (_: Partial<Model.ClientWatermark>) => void
  watermark?: Partial<Model.ClientWatermark>
}

type ValueOf<T> = T[keyof T]

export const PhotoWatermarkConfig: React.FC<PhotoWatermarkConfigProps> = ({ onChange, watermark }) => {
  const [value, setValue] = useState(watermark)
  const handleChange = (key: keyof Model.ClientWatermark) => (value: ValueOf<Model.ClientWatermark>) => {
    setValue(watermark => {
      onChange?.({ ...watermark, [key]: value })
      return { ...watermark, [key]: value }
    })
  }
  return (
    <div className={classes.watermark}>
      <FormGroup label="Posicionamento">
        <InputRadioContextProvider>
          <InputRadio onChange={handleChange('position')} checked={value?.position === 'top-left'} value="top-left">
            Canto superior esquerdo
          </InputRadio>
          <InputRadio onChange={handleChange('position')} checked={value?.position === 'top-right'} value="top-right">
            Canto superior direito
          </InputRadio>
          <InputRadio
            onChange={handleChange('position')}
            checked={value?.position === 'bottom-left'}
            value="bottom-left">
            Canto inferior esquerdo
          </InputRadio>
          <InputRadio
            onChange={handleChange('position')}
            checked={value?.position === 'bottom-right'}
            value="bottom-right">
            Canto inferior direito
          </InputRadio>
          <InputRadio onChange={handleChange('position')} checked={value?.position === 'center'} value="center">
            Centro
          </InputRadio>
        </InputRadioContextProvider>
      </FormGroup>
      <div className={classes.spacing}>
        <label className={classes.label}>Espaçamento esquerdo (px)</label>
        <InputDecimal onChange={handleChange('margin_left')} value={value?.margin_left} sm />
        <label className={classes.label}>Espaçamento direito (px)</label>
        <InputDecimal onChange={handleChange('margin_right')} value={value?.margin_right} sm />
        <label className={classes.label}>Espaçamento superior (px)</label>
        <InputDecimal onChange={handleChange('margin_top')} value={value?.margin_top} sm />
        <label className={classes.label}>Espaçamento inferior (px)</label>
        <InputDecimal onChange={handleChange('margin_bottom')} value={value?.margin_bottom} sm />
        <label className={classes.label}>Transparência (%)</label>
        <InputDecimal onChange={handleChange('transparency')} value={value?.transparency} sm />
        <label className={classes.label}>Tamanho proporcional (%)</label>
        <InputDecimal onChange={handleChange('proportional')} value={value?.proportional} sm />
      </div>
      <FormGroup label="Imagem">
        <FormLogo accept=".png" onChange={([logo]) => handleChange('logo')(logo)} value={value?.logo_url} />
      </FormGroup>
    </div>
  )
}
