import React, { useContext, useState } from 'react'

type ClientAddContextType = [ClientAddContext, React.Dispatch<React.SetStateAction<ClientAddContext>>]

const Context = React.createContext<ClientAddContextType>(undefined!)

export function useClientAddContext() {
  return useContext(Context)!
}

export const ClientAddContextProvider: React.FC = ({ children }) => {
  const value = useState<ClientAddContext>({})
  return <Context.Provider value={value}>{children}</Context.Provider>
}
