import React, { useEffect, useRef, useState } from 'react'
import { format, parseISO } from 'date-fns'

import { Button } from 'components/Button'
import { useServices } from 'hooks/data/services'
import { useProviders } from 'hooks/data/providers'
import { ItemWorkType } from 'shared/enum/item'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormSelect } from 'shared/form/FormSelect'
import { FormText } from 'shared/form/FormText'
import { FormTextArea } from 'shared/form/FormTextArea'
import { Modal, ModalFooter, ModalHeader } from 'shared/modal'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './EditSessionModal.module.scss'

interface EditSessionModalProps {
  close: () => void
  onChange: () => void
  session: Resource.SessionResource
}

// type StateValue = Pick<Model.Session, 'address'>

export const EditSessionModal: React.FC<EditSessionModalProps> = ({ close, onChange, session }) => {
  const addressRef = useRef<HTMLInputElement>(null)
  const autocompleteRef = useRef<google.maps.places.Autocomplete>()
  const clientIdRef = useRef({ client_id: session.client.id })
  const itemIdRef = useRef({
    'item[id]': session.products.find(({ work_type }) => work_type === ItemWorkType.LOCAL)?.item.id || '',
    'item[pivot][deliverable]': 1
  })
  const providers = useProviders(itemIdRef.current)
  const services = useServices(clientIdRef.current)
  const [value, setValue] = useState<any>({
    address: session.address || {},
    provider: session.provider || {},
    responsible_information: session.responsible_information || {},
    vertical_data: session.vertical_data || {},
    scheduled_for: session.scheduled_for && format(parseISO(session.scheduled_for), "yyyy-MM-dd'T'HH:mm"),
    service_id: session.service.id
  })
  const schedulingUrl = `${window.location.origin.replace('admin', 'client')}/calendar/${session.token}`
  const handleAdditionalInfo = (additional_info: string) => {
    setValue((value: any) => ({ ...value, vertical_data: Object.assign({}, value.vertical_data, { additional_info }) }))
  }
  const handleProvider = (providerId: string) => {
    const provider = providers?.find(({ id }) => Number(providerId) === id)
    if (provider) {
      setValue((value: any) => ({ ...value, provider }))
    }
  }
  const handleResponsibleChange = (key: string) => (input: string) => {
    setValue((value: any) => ({
      ...value,
      responsible_information: Object.assign({}, value.responsible_information, { [key]: input })
    }))
  }
  const handleScheduledFor = (scheduled_for: string) => {
    setValue((value: any) => ({
      ...value,
      scheduled_for
    }))
  }
  const handleService = (serviceId: string) => {
    setValue((value: any) => ({ ...value, service_id: Number(serviceId) }))
  }
  const handleSave = (event: React.FormEvent) => {
    event.preventDefault()
    fetch(`/api/sessions/${session.id}`, { body: toFormData(value), method: 'POST' })
      .then(onChange)
      .then(() => alert('Informações salvas!'))
      .then(close)
  }
  useEffect(() => {
    if (addressRef.current && !Boolean(autocompleteRef.current)) {
      autocompleteRef.current = new google.maps.places.Autocomplete(addressRef.current, {
        componentRestrictions: {
          country: ['BR']
        },
        types: ['address']
      })
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current?.getPlace()
        if (place) {
          setValue((value: any) => ({ ...value, address: place }))
          addressRef.current!.value = place.formatted_address!
        }
      })
    }
  }, [])
  return (
    <Modal size="lg">
      <ModalHeader close={close}>Alterar informações do ensaio</ModalHeader>
      <div className={classes.form}>
        <FormGroup className={classes.full} label="Serviço do Cliente">
          <FormSelect onChange={handleService} value={value.service_id.toString()}>
            <option value="">Selecione</option>
            {services?.map(service => (
              <option key={service.id} value={service.id.toString()}>
                {service.name}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <FormGroup className={classes.full} label="Endereço (com número)">
          <FormText
            placeholder="Pesquisar endereço"
            readOnly
            ref={addressRef}
            required
            value={value.address?.formatted_address}
          />
        </FormGroup>
        <FormGroup className={classes.full} label="Fornecedor">
          <FormSelect onChange={handleProvider} value={value.provider?.id?.toString()}>
            <option value="">Selecione</option>
            {providers?.map(provider => (
              <option key={provider.id} value={provider.id}>
                {provider.name}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <div className={`${classes.scheduling} ${classes.full}`}>
          <FormGroup label="Reagendamento">
            <strong>Data Atual:</strong> {value.scheduled_for} <br />
            <FormText type="datetime-local" onChange={handleScheduledFor} value={value.scheduled_for} />
          </FormGroup>
          <Button className={classes.button} href={schedulingUrl}>
            VER DISPONIBILIDADE <Icon.ExternalLinkSquareAlt />
          </Button>
        </div>
        <div>
          <h4>Responsável pelo ensaio</h4>
          <FormGroup label="Nome">
            <FormText required onChange={handleResponsibleChange('name')} value={value.responsible_information?.name} />
          </FormGroup>
          <FormGroup label="Telefone">
            <FormMasked
              mask="phone"
              required
              onChange={handleResponsibleChange('phone')}
              value={value.responsible_information?.phone}
            />
          </FormGroup>
          <FormGroup label="E-mail">
            <FormText
              type="email"
              required
              onChange={handleResponsibleChange('email')}
              value={value.responsible_information?.email}
            />
          </FormGroup>
        </div>
        <div className={classes.info}>
          <h4>Informações adicionais</h4>
          <FormGroup className={classes.height}>
            <FormTextArea onChange={handleAdditionalInfo} value={session.vertical_data?.additional_info} />
          </FormGroup>
        </div>
      </div>
      <ModalFooter content="between">
        <Button onClick={close} outline>
          CANCELAR
        </Button>
        <Button onClick={handleSave}>SALVAR</Button>
      </ModalFooter>
    </Modal>
  )
}
