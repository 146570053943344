import React from 'react'
import Skeleton from 'react-loading-skeleton'

import classes from './Table.module.scss'

import { Session } from './Session'

import { useLoadingContext } from '../hooks/loading'
import { useSessionContext } from '../hooks/sessions'

export const Sessions = () => {
  const [sessions] = useSessionContext()
  const [load] = useLoadingContext()

  if (load) {
    return <Skeleton style={{ margin: '0 0 48px 0' }} height={48} count={5} />
  }

  return (
    <div className={classes.sessions}>
      {sessions!.map((session: any, index: number) => (
        <Session key={index} index={index} session={session} />
      ))}
    </div>
  )
}
