import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useProviders(params?: Http.Params, paging?: Http.Pagination, setMeta?: (_: Http.ResponseMeta) => void) {
  const [providers, setProviders] = useState<Model.Provider[]>()
  useEffect(() => {
    setProviders(undefined)
    const request = {
      body: toFormData(params || {}),
      method: 'POST'
    }
    const search = new URLSearchParams(params)
    search.set('v', '2') // temporário
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    fetch(`/api/providers/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setProviders(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return providers
}

export function useClient(key: Model.Key) {
  const [client, setClient] = useState<Model.Client>()
  useEffect(() => {
    fetch(`/api/providers/${key.id}`)
      .then(response => response.json())
      .then(({ data }) => setClient(data))
  }, [key])
  return [client, setClient] as ReactState<Model.Client>
}
