import React, { useState, useContext } from 'react'

function useFilters(id: any): [any, any] {
  const [filter, setFilter] = useState<any>({
    providers: [id]
  })

  const handleFilterChange = (key: string, value: any) => {
    let _filter = filter
    _filter[key] = value

    setFilter({ ..._filter })
  }

  return [filter, handleFilterChange]
}

const FilterContext = React.createContext<[any, any] | undefined>(undefined)

export const useFilterContext = () => useContext(FilterContext)!

export const FilterProvider: React.FC<any> = props => {
  const { provider } = props
  const filter = useFilters(provider?.id)

  return <FilterContext.Provider value={filter} {...props} />
}
