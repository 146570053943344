import React, { Fragment, useEffect, useState } from 'react'

import { FormGroup, FormText } from 'shared/form'
import { FormInputGroup } from 'shared/form/FormInputGroup'
import { FormMasked, MaskType } from 'shared/form/FormMasked'
import { FormSelect } from 'shared/form/FormSelect'
import { Card } from 'shared/ui/card/Card'
import { Icon } from 'shared/ui/icon'
import { Button } from 'shared/ui/button/Button'

import classes from './PriceRules.module.scss'

// TODO

interface RuleProps {
  pricingRule: Model.PricingRule
  remove: () => void
  onUpdateOrder: (order: 'UP' | 'DOWN') => void
  onChange: (rule: Model.PricingRule, current: Model.PricingRule) => void
  isEditing: boolean
}

export const Rule: React.FC<RuleProps> = ({ pricingRule, onUpdateOrder, remove, isEditing, onChange }) => {
  const [state, setState] = useState(pricingRule)

  useEffect(() => setState(pricingRule), [pricingRule])

  useEffect(() => onChange(pricingRule, state), [onChange, pricingRule, state])

  const onAttributeChange = (key: string) => (value: any) => {
    setState((rule: any) => ({ ...rule, [key]: value }))
  }

  const onDiscountChange = (value: string) => {
    setState((rule: any) => ({ ...rule, discount: { title: `${value}% OFF`, value: Number(value) / 100 } }))
  }

  const onConditionsChange = (key: string) => (value: any) => {
    setState((rule: any) => ({
      ...rule,
      rule: { ...state.rule, conditions: { ...(state?.rule?.conditions ?? {}), [key]: value } }
    }))
  }

  const onMainChange = (key: string) => (value: any) => {
    setState((rule: any) => ({
      ...rule,
      rule: { ...(rule?.rule ?? {}), main: { ...(rule?.rule?.main ?? {}), [key]: value } }
    }))
  }

  const onAddItem = () => {
    setState(({ rule: { items = [], ...rules } = {}, ...state }: any) => {
      const ruleItems = [...items, {}]

      return { ...state, rule: { ...rules, items: ruleItems } }
    })

    onChange(pricingRule, state)
  }

  const onRemoveItem = (index: number) => {
    state.rule.items.splice(index, 1)
    setState(state)
    onChange(pricingRule, state)
  }

  const onItemTitleChange = (index: number) => (value: string) => {
    setState(({ rule: { items, ...rule }, ...pricingRule }) => {
      const values = [...items]
      values[index].title = value
      return { rule: { items: values, ...rule }, ...pricingRule }
    })
    onChange(pricingRule, state)
  }

  const onItemValueChange = (index: number) => (value: string) => {
    setState(({ rule: { items, ...rule }, ...pricingRule }) => {
      const values = [...items]
      const input = Number(value)
      values[index].value = input > 0 ? input : null
      return { rule: { items: values, ...rule }, ...pricingRule }
    })
    onChange(pricingRule, state)
  }

  return (
    <Card shadow variant="light-grey" className={classes.rule}>
      {!isEditing && (
        <div className={classes.row}>
          <Button onClick={remove}>
            Remover <Icon.TrashAlt />
          </Button>
        </div>
      )}
      <Card className={classes.card}>
        <div className={classes.grid}>
          <FormGroup label="Nome da regra*">
            <FormText required readOnly={!isEditing} value={state.name} onChange={onAttributeChange('name')} />
          </FormGroup>
          <FormGroup label="Preço base*">
            <FormInputGroup>
              <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
              <FormMasked
                required
                mask={MaskType.CURRENCY}
                readOnly={!isEditing}
                value={state.base_price?.toString()}
                onChange={onAttributeChange('base_price')}
              />
            </FormInputGroup>
          </FormGroup>
          <FormGroup label="Valor do desconto">
            <FormInputGroup>
              <FormText type="number" readOnly={!isEditing} onChange={onDiscountChange} />
              <FormInputGroup.Append>%</FormInputGroup.Append>
            </FormInputGroup>
          </FormGroup>
          <div />
          <FormGroup label="Campo*">
            <FormSelect required disabled={!isEditing} onChange={onConditionsChange('field')}>
              <option>Selecione</option>
              <option value="vertical_data.area">Área do imóvel</option>
            </FormSelect>
          </FormGroup>
          <FormGroup label="Condição*">
            <FormSelect required disabled={!isEditing} onChange={onConditionsChange('operator')}>
              <option>Selecione</option>
              <option value="<=">Igual ou menor que</option>
              <option value="<">Menor que</option>
              <option value="==">Igual a</option>
              <option value=">">Maior que</option>
              <option value=">=">Igual ou maior que</option>
            </FormSelect>
          </FormGroup>
          <FormGroup label="Valor da regra*">
            <FormText
              required
              readOnly={!isEditing}
              value={state?.rule?.conditions?.value?.toString()}
              onChange={onConditionsChange('value')}
            />
          </FormGroup>
          <div />
          <FormGroup label="Principal (Título)*">
            <FormText
              required
              readOnly={!isEditing}
              value={state?.rule?.main?.title}
              onChange={onMainChange('title')}
            />
          </FormGroup>
          <FormGroup label="Principal (Valor)*">
            <FormInputGroup>
              <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
              <FormMasked
                mask={MaskType.CURRENCY}
                required
                readOnly={!isEditing}
                value={state?.rule?.main?.value?.toString()}
                onChange={onMainChange('value')}
              />
            </FormInputGroup>
          </FormGroup>
          <div className={classes.actionbutton}>
            {isEditing && (
              <Button onClick={onAddItem}>
                Item <Icon.PlusCircle />
              </Button>
            )}
          </div>
          <div />
          {state?.rule?.items?.map((item: any, index: number) => (
            <Fragment key={index}>
              <FormGroup label="Título*">
                <FormText required readOnly={!isEditing} value={item.title} onChange={onItemTitleChange(index)} />
              </FormGroup>
              <FormGroup label="Descrição (opcional)">
                <FormMasked
                  mask={MaskType.CURRENCY}
                  readOnly={!isEditing}
                  value={item.value}
                  onChange={onItemValueChange(index)}
                />
              </FormGroup>
              <div className={classes.actionbutton}>
                {isEditing && (
                  <Button onClick={() => onRemoveItem(index)}>
                    Remover <Icon.TrashAlt />
                  </Button>
                )}
              </div>
              <div />
            </Fragment>
          ))}
        </div>
        <div className={classes.upndown}>
          {isEditing && (
            <>
              <Icon.ArrowUp size="2x" onClick={() => onUpdateOrder('UP')} />
              <Icon.ArrowDown size="2x" onClick={() => onUpdateOrder('DOWN')} />
            </>
          )}
        </div>
      </Card>
    </Card>
  )
}
