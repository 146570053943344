import { useEffect, useState } from 'react'

export function useClientCostCenters(clientId?: number) {
  const [costCenters, setCostCenters] = useState<Model.CostCenter[]>()
  useEffect(() => {
    if (clientId) {
      fetch(`/api/clients/${clientId}/cost-centers`)
        .then(response => response.json())
        .then(({ data }) => setCostCenters(data))
    } else {
      setCostCenters(undefined)
    }
  }, [clientId])
  return costCenters
}
