import { useEffect, useState } from 'react'

export function useItems() {
  const [items, setItems] = useState<Model.Item[]>([])
  useEffect(() => {
    fetch(`/api/items`)
      .then(response => response.json())
      .then(({ data }) => setItems(data))
  }, [])
  return items
}

export function useItemsState(): [Model.Item[], (items: Model.Item[]) => void] {
  const [items, setItems] = useState<Model.Item[]>([])
  useEffect(() => {
    fetch(`/api/items`)
      .then(response => response.json())
      .then(({ data }) => setItems(data))
  }, [])
  return [items, setItems]
}
