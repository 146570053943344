import React from 'react'

import { classNames } from '../../util/class-names'

interface Props {
  className?: string
  onClick?: (_: React.MouseEvent) => void
  outline?: boolean
  size?: 'lg' | 'sm'
  type?: 'button' | 'reset' | 'submit'
  variant?: 'danger' | 'dark' | 'info' | 'light' | 'link' | 'primary' | 'secondary' | 'success' | 'warning'
}

export const Button: React.FC<Props> = ({ className, outline, size, type = 'button', variant = 'primary', ...props }) => {
  const klass = classNames(
    `btn btn${outline ? '-outline' : ''}-${variant}`,
    size && `btn-${size}`,
    className
  )
  return (
    <button className={klass} type={type} {...props} /> 
  )
}