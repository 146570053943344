import React from 'react'

import { classNames } from '../util/class-names'

import classes from './ModalContainer.module.scss'

interface ModalContainerProps {
  show: boolean
}

export type ModalConfig = {
  size?: 'sm' | 'md' | 'lg'
}

export const ModalContainer: React.FC<ModalContainerProps & ModalConfig> = ({ children, show, size }) => {
  if (show) {
    const className = classNames(classes.container)
    return <div className={className}>{children}</div>
  }
  return null
}
