import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { PhotoWatermarkConfig } from 'shared/components/common/PhotoWatermarkConfig'

import { FormAddress } from 'shared/form/FormAddress'
import { FormCheck } from 'shared/form/FormCheck'
import { FormFeedbackSuccess } from 'shared/form/FormFeedbackSuccess'
import { FormLogo } from 'shared/form/FormLogo'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import classes from './ClientInfo.module.scss'

interface InfoState {
  name: string
  address: google.maps.places.PlaceResult
  corporate_name: string
  identification: string
  watermark: Partial<Model.ClientWatermark>
  white_label: boolean
  logo: File
}

export const ClientInfo: App.ClientViewSection = ({ state: [client] }) => {
  const [info, setInfo] = useState<Partial<InfoState>>({})
  const [status, setStatus] = useState<App.FormStatus>({ state: 'initial' })
  const handleChange = (key: keyof InfoState) => (value: InfoState[keyof InfoState]) => {
    setInfo(info => ({ ...info, [key]: value }))
  }
  const handleLogo = ([file]: File[]) => setInfo(info => ({ ...info, logo: file }))
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData(info)
    setStatus({ state: 'loading' })
    fetch(`/api/clients/${client!.id}`, { body, method: 'POST' })
      .then(() => setStatus({ state: 'saved' }))
      .catch(() => alert('Falha ao atualizar cliente!'))
  }
  const preventSubmit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }
  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit} onKeyDown={preventSubmit}>
        <div className={classes.form}>
          <div className={classes.col}>
            <FormGroup label="Logo">
              <FormLogo accept=".jpg,.jpeg,.png,.svg" onChange={handleLogo} value={client?.logo_url} />
            </FormGroup>
            <FormCheck onChange={handleChange('white_label')} checked={client?.white_label}>
              Empresa White Label
            </FormCheck>
          </div>
          <div className={classes.col}>
            <FormGroup label="Nome Fantasia">
              <FormText onChange={handleChange('name')} value={client?.name} />
            </FormGroup>
            <FormGroup label="Endereço">
              <FormAddress onChange={handleChange('address')} value={client?.address?.formatted_address} />
            </FormGroup>
          </div>
        </div>
        <div className={classes.form}>
          <FormGroup label="CNPJ">
            <FormMasked mask="cnpj" onChange={handleChange('identification')} value={client?.identification} />
          </FormGroup>
          <FormGroup label="Razão Social">
            <FormText onChange={handleChange('corporate_name')} value={client?.corporate_name} />
          </FormGroup>
        </div>
        <hr />
        <h6>Configuração de marca d'água nas fotos</h6>
        <PhotoWatermarkConfig onChange={handleChange('watermark')} watermark={client?.watermark} />
        <div className={classes.footer}>
          <Button className={classes.button} disabled={status.state === 'loading'}>
            SALVAR
          </Button>
          <FormFeedbackSuccess autoHide={5} status={status} />
        </div>
      </form>
    </div>
  )
}
