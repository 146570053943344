import React from 'react'

import { classNames } from '../../util/class-names'

import { useTableContext } from './Table'

import classes from './TableRow.module.scss'

interface Props {
  className?: string
  onClick?: () => void
}

export const TableRow: React.FC<Props> = ({ className, onClick, ...props }) => {
  const context = useTableContext()
  const klass = classNames(
    classes.row,
    context.hover && classes.hover,
    context.strip && classes.strip,
    className
  )
  return <div onClick={onClick} className={klass} {...props} />
}
