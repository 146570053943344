import React from 'react'

import classes from './Table.module.scss'

import { Header } from './Header'
import { Sessions } from './Sessions'

export const Table = () => {
  return (
    <div className={classes.table}>
      <Header />
      <Sessions />
    </div>
  )
}
