import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useServiceProductSession(
  params?: Http.Params,
  paging?: Http.Pagination,
  setMeta?: (_: Http.ResponseMeta) => void
) {
  const [serviceProductSessions, setServiceProductSessions] = useState<Model.ServiceProductSession[]>()
  useEffect(() => {
    setServiceProductSessions(undefined)
    const request = {
      body: toFormData(params || {}),
      method: 'POST'
    }
    const search = new URLSearchParams()
    if (paging) {
      search.set('page', paging.page.toString())
      search.set('length', paging.length.toString())
    }
    fetch(`/api/service-product-session/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setServiceProductSessions(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return serviceProductSessions
}
