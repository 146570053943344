import React from 'react'
import ReactDOM from 'react-dom'

import './assets/styles/index.scss'

import 'shared/ui/styles.scss'

import { App } from './App'

ReactDOM.render(<App />, document.getElementById('root'))
