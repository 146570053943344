import React, { useEffect, useState } from 'react'

import { RouteComponentProps, Switch, Route, Redirect, NavLink } from 'react-router-dom'

import classes from './ServiceView.module.scss'

import { Container } from 'shared/ui/container/Container'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'
import { Button } from 'shared/ui/button/Button'
import { useModal } from 'shared/modal'

import { ServiceDeliverables } from './tabs/ServiceDeliverables'
import { PriceRules } from './tabs/PriceRules'
import { Vouchers } from './tabs/Vouchers'

import { AddServiceToClient } from './components/AddServiceToClient'
import { EditServiceCost } from './components/EditServiceCost'
import { EditServiceInformation } from './components/EditServiceInformation'
import { classNames } from 'shared/util/class-names'
import { AddCityToServiceModal } from './components/AddCityToServiceModal'

export const ServiceView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [service, setService] = useState<Model.Service>()

  const serviceToAnotherCity = useModal(AddCityToServiceModal)
  const serviceToAnotherClient = useModal(AddServiceToClient)

  useEffect(() => {
    fetch(`/api/services/${match.params.id}`)
      .then(response => response.json())
      .then(({ data }) => setService(data))
  }, [match])

  if (!service) {
    return null
  }

  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>Serviços</BreadcrumbItem>
        <BreadcrumbItem>Serviço {service.name}</BreadcrumbItem>
      </Breadcrumb>
      <div className={classes.container}>
        <Container className={classes.sidebar}>
          <Card className={classes.center}>
            <h1>{service.id}</h1>
            <h2>{service.name}</h2>
          </Card>
          <Card className={classes.center}>
            <h3>{service.client?.name ?? 'Serviço padrão'}</h3>
          </Card>
          {service.city && (
            <Card className={classes.center}>
              <h3>{service.city.name}</h3>
            </Card>
          )}
          <Card>
            <h3 className={classes.center}>Custo padrão do serviço</h3>
            <EditServiceCost service={service} />
          </Card>
          <Card>
            <h3 className={classes.center}>Informações</h3>
            <EditServiceInformation service={service} />
          </Card>
          <Card className={classNames(classes.center, classes.oferservices)}>
            <h3>Oferecer serviço</h3>
            <Button onClick={() => serviceToAnotherCity.open({ service })}>Para outra cidade</Button>
            <Button onClick={() => serviceToAnotherClient.open({ service })}>Para outro cliente</Button>
          </Card>
        </Container>
        <Container>
          <Card className={classes.sections}>
            <nav className={classes.nav}>
              <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/deliverables`}>
                Entregáveis
              </NavLink>
              <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/price-rules`}>
                Regras de Preço
              </NavLink>
              <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/vouchers`}>
                Pacotes
              </NavLink>
            </nav>
            <Switch>
              <Route path={`${match.path}/deliverables`} component={ServiceDeliverables} />
              <Route path={`${match.path}/price-rules`} component={PriceRules} />
              <Route path={`${match.path}/vouchers`} component={Vouchers} />
              <Redirect to={`${match.url}/deliverables`} />
            </Switch>
          </Card>
        </Container>
      </div>
    </Container>
  )
}
