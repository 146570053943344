import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { InputAutocomplete } from 'shared/bootstrap/form/InputAutocomplete'
import { InputDateRange } from 'shared/bootstrap/form/InputDateRange'
import { InputSelect } from 'shared/bootstrap/form/InputSelect'
import { InputSelectItem } from 'shared/bootstrap/form/InputSelectItem'
import { ProviderDataSource } from 'shared/hooks/source/ProviderDataSource'
import { Button } from 'shared/ui/button/Button'

import classes from './DeliverableListFilter.module.scss'

interface DeliverableListFilterProps {
  onChange?: (_: object) => void
}

export const DeliverableListFilter: React.FC<DeliverableListFilterProps> = ({ onChange }) => {
  const [value, setValue] = useState<object>({})
  const handleChange = (key: string) => (value: string | number) => {
    setValue(current => ({ ...current, [key]: value }))
  }
  const handleDateChange = (key: string) => (value: [string, string]) => {
    setValue(current => ({ ...current, [key]: value }))
  }
  return (
    <div className={classes.container}>
      <FormGroup label="Fornecedor">
        <ProviderDataSource>
          <InputAutocomplete placeholder="Todos" onChange={handleChange('session.provider_id')} />
        </ProviderDataSource>
      </FormGroup>
      <FormGroup label="Data do ensaio">
        <InputDateRange onChange={handleDateChange('session.scheduled_for')} />
      </FormGroup>
      {/*<FormGroup label="Data de entrega">
        <InputDateRange onChange={handleDateChange('delivered_at')} />
      </FormGroup>*/}
      <FormGroup label="Entregável">
        <InputSelect onChange={handleChange('item_id')}>
          <InputSelectItem value={6}>Fotos Tour 3D</InputSelectItem>
          <InputSelectItem value={9}>Fotos DSLR</InputSelectItem>
          <InputSelectItem value={10}>Vídeos</InputSelectItem>
          <InputSelectItem value={11}>Plantas</InputSelectItem>
        </InputSelect>
      </FormGroup>
      <FormGroup label="Status">
        <InputSelect onChange={handleChange('status')}>
          <InputSelectItem value={2}>Pendente</InputSelectItem>
          <InputSelectItem value={3}>Entregue</InputSelectItem>
        </InputSelect>
      </FormGroup>
      <div className={classes.submit}>
        <Button type="button" onClick={() => onChange?.(value)}>
          BUSCAR
        </Button>
      </div>
    </div>
  )
}
