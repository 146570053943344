import React, { useEffect, useState } from 'react'

import { useItems } from 'hooks/data/item'
import { FormGroup } from 'shared/form/FormGroup'
import { FormInputGroup } from 'shared/form/FormInputGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { classNames } from 'shared/util/class-names'

import classes from './ContractServiceItem.module.scss'
import { FormTag } from 'shared/form/FormTag'

interface ContractServiceItemProps {
  index?: number
  onChange?: (_: any) => void
  service: Partial<Model.Service>
}

export const ContractServiceItem: React.FC<ContractServiceItemProps> = ({ index, onChange, service }) => {
  const [state, setState] = useState<Partial<Model.Service>>(service)
  const items = useItems().filter(item => !item.item)

  useEffect(() => {
    if (!state.products) {
      setState(state => ({ ...state, products: [] }))
    }
  }, [state])

  const [productTags, setProductTags] = useState(() => {
    const defaults = ['Destaque', 'Salas', 'Cozinha', 'Banheiro', 'Dormitório', 'Áreas Comuns', 'Fachada']
    return state?.products?.[0]?.tags?.map(tag => tag.name ?? '') || defaults
  })

  const handleChange = (key: string) => (value: any) => {
    setState({ ...state, [key]: value })
  }

  const handleTagChange = (tags: string[]) => {
    setProductTags(tags)
    const product = { ...state.products![0], tags: tags.map(tag => ({ name: tag })) }
    const products = [product, ...(state.products?.splice(1) || [])]
    setState(state => ({ ...state, products }))
  }

  // this handles if is selected or not state.products
  const handleItem = (item: Model.Item) => (_: React.MouseEvent) => {
    if (state.products) {
      const index = state.products.findIndex(product => product.item!.id === item.id)

      if (index >= 0) {
        // Remove the item
        const products = state.products
        setState(service => {
          return { ...service, products: products.filter(product => product.item!.id !== item.id) }
        })
      } else {
        setState(({ products = [], ...service }) => {
          if ([1, 2, 12].includes(item.id)) {
            // Allow only one main deliverable
            const deliverableIndex = products.findIndex(product => {
              return product.item && product.item.id !== item.id && [1, 2, 12].includes(product.item.id)
            })
            if (deliverableIndex >= 0) {
              products.splice(deliverableIndex, 1)
            }
          }
          return { ...service, products: [...products, { item_id: item!.id!, item, name: item!.title }] }
        })
      }
    }

    setProductTags(productTags)
  }

  const getItemClass = (item: Model.Item) => {
    return classNames(state.products?.find(({ name }) => name === item.title) && classes.selected)
  }
  useEffect(() => {
    onChange?.(state)
  }, [state])
  return (
    <div className={classes.wrapper}>
      {index !== undefined && <h3 className={classes.title}>SERVIÇO {index + 1}</h3>}
      <FormGroup label="Nome do serviço">
        <FormText onChange={handleChange('name')} value={state.name} required />
      </FormGroup>
      <FormGroup label="Valor">
        <FormInputGroup>
          <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
          <FormMasked mask="currency" onChange={handleChange('price')} value={state.price?.toString()} required />
        </FormInputGroup>
      </FormGroup>
      <h3 className={classes.title}>ENTREGÁVEL PRINCIPAL</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => [1, 2, 12].includes(item.id))
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <h3 className={classes.title}>ENTREGÁVEIS ADICIONAIS</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => ![1, 2, 12].includes(item.id))
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <FormGroup>
        <h3>CATEGORIAS PARA FOTOS</h3>
        <small>Separe as tags com vírgula</small>
        <FormTag readOnly={state?.products?.[0] === undefined} onChange={handleTagChange} value={productTags} />
      </FormGroup>
      <FormGroup label="Prazo padrão de hospedagem do tour">
        <FormInputGroup>
          <FormText onChange={handleChange('host_prize')} value={`${state.host_prize || 6}`} required />
          <FormInputGroup.Append>meses</FormInputGroup.Append>
        </FormInputGroup>
      </FormGroup>
    </div>
  )
}
