import React from 'react'

import classes from './Filter.module.scss'

import { Button } from 'components/Button'

import { Clients } from './Clients'
import { Data } from './Data'
import { Search } from './Search'
import { Status } from './Status'
import { useMetaContext } from '../hooks/meta'

export const Filter = () => {
  const [meta] = useMetaContext()

  const onClick = () => {
    meta('update')
  }
  return (
    <div className={classes.container}>
      <h2>Filtrar ensaios</h2>
      <div className={classes.grid}>
        <Clients />
        <Data />
        <Status />
        <Search />
      </div>

      <Button onClick={onClick}>Filtrar</Button>
    </div>
  )
}
