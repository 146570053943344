import React, { useState } from 'react'

import { FormGroup } from 'shared/form/FormGroup'
import { FormInputGroup, FormInputGroupAppend } from 'shared/form/FormInputGroup'
import { FormText } from 'shared/form/FormText'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableVideos.module.scss'

interface SessionDeliverableVideosProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableVideo>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableVideos: React.FC<SessionDeliverableVideosProps> = ({ product, session }) => {
  const [current, setCurrent] = useState('')
  const [products, setProducts] = useState(product.deliverables)
  const handleCopy = (value: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(value).catch(() => {
      alert('Falha ao copiar para área de transferência')
    })
  }
  const handleDelete = (id: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    fetch(`/api/sessions/${session.id}/${product.id}/videos/${id}`, { method: 'DELETE' }).then(response => {
      if (response.ok) {
        setProducts(products => [...products.filter(product => product.id !== id)])
      }
    })
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData({ url: current })
    fetch(`/api/sessions/${session.id}/${product.id}/videos`, { body, method: 'POST' })
      .then(response => response.json())
      .then(({ data }) => {
        setCurrent('')
        setProducts(products => [...products, data])
      })
  }
  return (
    <div className={classes.content}>
      <div className={classes.deliverables}>
        <form onSubmit={handleSubmit}>
          <FormGroup label="URL do vídeo" className={classes.iconVideo}>
            <FormInputGroup>
              <FormInputGroup.Prepend>
                <Icon.Link />
              </FormInputGroup.Prepend>
              <FormText type="url" onChange={setCurrent} value={current} required />
              <FormInputGroupAppend>
                <button>SALVAR</button>
              </FormInputGroupAppend>
            </FormInputGroup>
          </FormGroup>
        </form>
        <div className={classes.products}>
          {products.map(product => (
            <div key={product.id} className={classes.product}>
              <Icon.Link className={classes.icon} />
              <a className={classes.file} href={product.url} rel="noopener noreferrer" target="_blank">
                {product.url}
              </a>
              <button className={classes.copy} onClick={handleCopy(product.url)}>
                <Icon.Clipboard />
              </button>
              <button className={classes.remove} onClick={handleDelete(product.id)}>
                <Icon.Times />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
