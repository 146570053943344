import React from 'react'
import { FormGroup, FormText } from 'shared/form'
import { useState } from 'react'
import { Saving } from 'shared/ui/saving/Saving'

interface EditServiceCostProps {
  service: Model.Service
}

export const EditServiceCost: React.FC<EditServiceCostProps> = ({ service }) => {
  const [price, setPrice] = useState(service.price)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <FormGroup label="Custo (opcional)">
        <FormText value={price?.toString()} onChange={value => setPrice(Number(value))} />
      </FormGroup>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Saving />
      </div>
    </form>
  )
}
