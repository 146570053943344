import React, { useEffect, useState } from 'react'

import { Button } from 'components/Button'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { Card } from 'shared/ui/card/Card'
import { Icon } from 'shared/ui/icon'

import classes from './ClientCosts.module.scss'
import { FormSelect } from 'shared/form/FormSelect'
import { useBanks } from 'hooks/data/banks'

export const ClientCosts: App.ClientViewSection = ({ state: [client] }) => {
  const [costs, setCosts] = useState<Model.CostCenter[]>([])
  const banks = useBanks()!
  const handleAdd = (event: React.MouseEvent) => {
    event.preventDefault()
    setCosts(costs => [
      ...costs,
      {
        name: '',
        corporate_name: '',
        identification: '',
        bank_account: {
          bank_id: null,
          agency: '',
          account: '',
          identification: '',
          owner_name: ''
        }
      }
    ])
  }
  const handleBankChange = (i: number) => (key: keyof Model.BankAccount) => (value: string) => {
    setCosts(costs => {
      Object.assign(costs[i].bank_account, { [key]: value })
      return [...costs]
    })
  }
  const handleChange = (i: number) => (key: keyof Model.CostCenter) => (value: string) => {
    setCosts(costs => {
      Object.assign(costs[i], { [key]: value })
      return [...costs]
    })
  }
  const handleDelete = (i: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const costCenter = costs[i]
    if (costCenter.id) {
      fetch(`/api/clients/${client!.id}/cost-centers/${costCenter.id}`, { method: 'DELETE' })
        .then(() => setCosts(costs => [...costs.filter(cost => cost !== costCenter)]))
        .catch(() => alert('Falha ao remover Centro de Custos!'))
    } else {
      setCosts(costs => [...costs.filter(cost => cost !== costCenter)])
    }
  }
  const handleSubmit = (i: number) => (event: React.FormEvent) => {
    event.preventDefault()
    const data = costs[i]
    const request: RequestInit = {
      body: JSON.stringify(data),
      headers: [['Content-Type', 'application/json']],
      method: 'POST'
    }
    const url = Boolean(data.id) ? `cost-centers/${data.id}` : `cost-centers`
    fetch(`/api/clients/${client!.id}/${url}`, request).then(response => {
      if (response.ok === false) {
        alert('Falha ao salvar centro de custos')
      }
    })
  }
  useEffect(() => {
    setCosts(client?.cost_centers || [])
  }, [client])
  return (
    <>
      {costs?.map((cost, i) => (
        <form key={i} className={classes.container} onSubmit={handleSubmit(i)}>
          <div className={classes.costCenter}>
            <p className={classes.title}>Centro de custos</p>
            <FormGroup label="Nome">
              <FormText onChange={handleChange(i)('name')} value={cost.name} required />
            </FormGroup>
            <FormGroup label="Razão Social">
              <FormText onChange={handleChange(i)('corporate_name')} value={cost.corporate_name} />
            </FormGroup>
            <FormGroup label="CNPJ">
              <FormMasked mask="cnpj" onChange={handleChange(i)('identification')} value={cost.identification} />
            </FormGroup>
            <div className={classes.actions}>
              <Button>SALVAR</Button>
              <Button outline onClick={handleDelete(i)}>
                EXCLUIR
              </Button>
            </div>
          </div>
          <div>
            <Card>
              <p className={classes.title}>Dados bancários</p>
              <div className={classes.bankAccount}>
                <FormGroup label="Nome">
                  <FormText onChange={handleBankChange(i)('owner_name')} value={cost.bank_account.owner_name} />
                </FormGroup>
                <FormGroup label="Agência">
                  <FormText onChange={handleBankChange(i)('agency')} value={cost.bank_account.agency} />
                </FormGroup>
                <FormGroup label="CPF/CNPJ">
                  <FormMasked
                    mask="cnpj-cpf"
                    onChange={handleBankChange(i)('identification')}
                    value={cost.bank_account.identification}
                  />
                </FormGroup>
                <FormGroup label="Conta">
                  <FormText onChange={handleBankChange(i)('account')} value={cost.bank_account.account} />
                </FormGroup>
                <FormGroup label="Banco">
                  <FormSelect
                    value={cost?.bank_account?.bank_id?.toString()}
                    required
                    onChange={handleBankChange(i)('bank_id')}>
                    <option value="">Selecione um banco</option>
                    {banks?.map((bank, index) => (
                      <option key={index} value={index}>
                        {bank.code} - {bank.name}
                      </option>
                    ))}
                  </FormSelect>
                </FormGroup>
              </div>
            </Card>
          </div>
        </form>
      ))}
      <button className={classes.add} onClick={handleAdd}>
        <Icon.PlusCircle />
        <span>Adicionar Centro de Custos</span>
      </button>
    </>
  )
}
