import React from 'react'

import classes from './FormCheck.module.scss'

type FormCheckValue = string | number
type FormCheckOnChange<T> = T extends { value: FormCheckValue } ? T['value'] : boolean

interface FormCheckProps {
  checked?: boolean
  className?: string
  onChange?: (_: FormCheckOnChange<this>) => void
  value?: FormCheckValue
  readOnly?: boolean
}

export const FormCheck: React.FC<FormCheckProps> = ({ className, children, onChange, value, ...props }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Boolean(value)) {
      onChange?.(Boolean(value))
    } else {
      onChange?.(event.target.checked)
    }
  }
  return (
    <div className={className}>
      <label className={classes.label}>
        <input type="checkbox" onChange={handleChange} {...props} />
        <span>{children}</span>
      </label>
    </div>
  )
}
