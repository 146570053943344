import React from 'react'

import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import classes from './Error.module.scss'

interface ErrorModalProps {
  title?: string
  message: string
}

export const ErrorModal: App.Modal<ErrorModalProps> = ({
  title = 'Ops, não conseguimos efetuar sua transação :(',
  message,
  close
}) => {
  return (
    <Container className={classes.container}>
      <Card className={classes.card}>
        <h1>{title}</h1>
        <h2>{message}</h2>
        <div className={classes.row}>
          <Button onClick={close} outline>
            Entendi!
          </Button>
        </div>
      </Card>
    </Container>
  )
}
