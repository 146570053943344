import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthContextProvider } from './components/auth/AuthContext'
import { Footer } from './components/Footer'
import { Navbar } from './components/Navbar'
import { Routes } from 'Routes'

import classes from './App.module.scss'

export const App = () => (
  <BrowserRouter>
    <div className={classes.root}>
      <AuthContextProvider>
        <Navbar />
        <div className={classes.wrapper}>
          <Routes />
          <Footer />
        </div>
      </AuthContextProvider>
    </div>
  </BrowserRouter>
)
