import React from 'react'
import { ReactSVG } from 'react-svg'

import { getServiceProductSessionStatusColor, ServiceProductSessionStatus } from '../../enum/service-product-session'
import { classNames } from '../../util/class-names'

import classes from './SessionProductIcon.module.scss'

interface SessionProductIconProps {
  className?: string
  status?: ServiceProductSessionStatus
  url: string
}

export const SessionProductIcon: React.FC<SessionProductIconProps> = ({ className, status, url, ...props }) => {
  const color = getServiceProductSessionStatusColor(status)
  const params = {
    className: classNames(className, classes.icon),
    style: {
      color: color,
      fill: color
    }
  }
  return <ReactSVG src={`https://assets.birdie.com.br/${url}`} {...params} {...props} />
}
