import React, { useState } from 'react'

import { Card, CardBody } from 'shared/bootstrap/card'
import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { Vertical } from 'shared/ui/vertical'

import { toFormData } from 'shared/util/form-data'

import classes from '../SessionView.module.scss'

interface RatingProps {
  session: Resource.SessionResource
}

enum Save {
  OK = 1,
  SAVING = 2,
  ERROR = 3
}

export const VerticalData: React.FC<RatingProps> = ({ session }) => {
  const [saved, setSaved] = useState<Save>(Save.OK)
  const [vertical_data, setVerticalData] = useState<any>(session.vertical_data || {})

  const dispatch = (partial: Partial<any>) => setVerticalData({ ...vertical_data, ...partial })

  if (!session || session.service.vertical === undefined) {
    return null
  }

  // don't show if it's not delivered yet
  if (session.status === SessionStatus.CANCELED) return <></>
  if (session.status === SessionStatus.NOSHOW_CLIENT) return <></>
  if (session.status === SessionStatus.NOSHOW_PROVIDER) return <></>

  let canUpdate = session.status !== SessionStatus.DELIVERED

  const save = () => {
    if (canUpdate) {
      setSaved(Save.SAVING)
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ vertical_data })
      }
      fetch(`/api/sessions/${session.id}`, init)
        .then(() => setTimeout(() => setSaved(Save.OK), 1000))
        .catch(() => {
          setSaved(Save.ERROR)
          alert('Não foi possível atualizar a sessão.')
        })
    }
  }

  return (
    <Card shadow>
      <CardBody>
        <h3>Informações importantes</h3>

        {/* TODO: corrigir código mal-feito */}
        <Vertical
          verticalId={session?.service?.vertical?.id || 1}
          value={session.vertical_data}
          onChange={value => dispatch({ ...value })}
        />

        {canUpdate && (
          <div className={classes.row}>
            <Button onClick={save}>Salvar alterações</Button>
            <div className={classes.check}>
              {canUpdate && saved === Save.OK && <Icon.Check />}
              {canUpdate && saved === Save.SAVING && <Icon.CircleNotch spin />}
              {canUpdate && saved === Save.ERROR && <Icon.Cross />}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
