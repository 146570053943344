import React from 'react'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'

import { List } from './list'
import { Add } from './add'
import { View } from './view'

export const Cities: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={List} />
      <Route exact path={`${match.path}/add`} component={Add} />
      <Route path={`${match.path}/:id`} component={View} />
      <Redirect to="/cities" />
    </Switch>
  )
}

export default Cities
