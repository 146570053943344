import React, { useCallback, useState } from 'react'

import { DeliverableListTable } from 'shared/table/DeliverableListTable'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card, CardBody } from 'shared/bootstrap/card'
import { Container } from 'shared/ui/container/Container'

import { DeliverableListFilter } from './components/DeliverableListFilter'

import classes from './DeliverableList.module.scss'

export const DeliverableList: React.FC = () => {
  const [filter, setFilter] = useState<object>({})
  const handleFilter = useCallback((filter: object) => setFilter({ filter }), [])
  return (
    <Container>
      <Breadcrumb className={classes.header}>
        <BreadcrumbItem>Entregáveis</BreadcrumbItem>
      </Breadcrumb>
      <Card className={classes.card} shadow>
        <CardBody>
          <DeliverableListFilter onChange={handleFilter} />
        </CardBody>
      </Card>
      <Card className={classes.content} shadow>
        <DeliverableListTable className={classes.table} filter={filter} />
      </Card>
    </Container>
  )
}
