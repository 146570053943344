import React from 'react'

import { Card } from '../../card/Card'
import { Button } from '../../button/Button'
import { Container } from '../../container/Container'

import { toFormData } from 'shared/util/form-data'

import classes from './City.module.scss'

interface EnableCityProps {
  close: () => void
  city: any
}

export const EnableCityModal: React.FC<EnableCityProps> = ({ close, city }) => {
  const update = () => {
    if (city && city.id) {
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ status: (city.status! & 1) === 1 ? city.status! - 1 : city.status! + 1 })
      }
      fetch(`/api/cities/${city.id}`, init).then(res => {
        if (res.ok) {
          window.location.reload()
        }
      })
    }
  }
  return (
    <Container size="sm" className={classes.container}>
      <Card shadow className={classes.card}>
        <h1>Você gostaria de {city.status! & 1 ? 'desabilitar' : 'habilitar'} esta cidade?</h1>
        <div className={classes.row}>
          <Button outline onClick={close}>
            Cancelar
          </Button>
          <Button onClick={update}>{city.status! & 1 ? 'Desabilitar' : 'Habilitar'}</Button>
        </div>
      </Card>
    </Container>
  )
}
