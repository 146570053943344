import React from 'react'

import { classNames } from '../util/class-names'

import classes from './TableRow.module.scss'

interface TableRowProps {
  className?: string
  hoverClass?: string
  stripClass?: string
  onClick?: () => void
}

export const TableRow: React.FC<TableRowProps> = ({ className, hoverClass, stripClass, onClick, ...props }) => {
  return <div onClick={onClick} className={classNames(classes.row, className, hoverClass, stripClass)} {...props} />
}
