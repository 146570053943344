import React, { createElement, Fragment, useState, useEffect } from 'react'
import { RouteComponentProps, NavLink, Switch, Route, Redirect } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'

import classes from './View.module.scss'
import Services from './tabs/services'
import Calendars from './tabs/calendars'
import { Button } from 'shared/ui/button/Button'
import { EnableCityModal } from 'shared/ui/modals/city/enable'
import { useModal } from 'shared/modal'

export const View: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [city, setCity] = useState<Model.City>()
  const enableModal = useModal(EnableCityModal)

  const section = (component: React.FC<any>) => ({
    render: () => createElement(component, { city })
  })

  useEffect(() => {
    fetch(`/api/cities/${match.params.id}`)
      .then(res => res.json())
      .then(({ data }) => setCity(data))
  }, [match])

  if (!city) {
    return <></>
  }

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem>Cidades</BreadcrumbItem>
        <BreadcrumbItem>{city.name}</BreadcrumbItem>
      </Breadcrumb>
      <div className={classes.container}>
        <div>
          <Card className={classes.info}>
            <h1>{city.name}</h1>
            <h2>{city.state}</h2>
          </Card>
          <Card style={{ marginTop: '12px' }} className={classes.info}>
            <h2>Cidade está {city?.status & 1 ? 'habilitada' : 'desabilitada'}</h2>
            <Button onClick={() => enableModal.open({ city })}>{city?.status & 1 ? 'Desabilitar' : 'Habilitar'}</Button>
          </Card>
        </div>
        <div className={classes.column}>
          <Card className={classes.sections}>
            <nav className={classes.nav}>
              <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/services`}>
                Serviços
              </NavLink>
              <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/calendars`}>
                Calendários
              </NavLink>
            </nav>
            <Switch>
              <Route path={`${match.path}/services`} {...section(Services)} />
              <Route path={`${match.path}/calendars`} {...section(Calendars)} />

              <Redirect to={`${match.url}/services`} />
            </Switch>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default View
