import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { UserProfile, getUserProfileDescription } from 'shared/enum/user/UserProfile'

import { UserProvider, useUserContext } from './hooks/users'
import { LoadingProvider, useLoadingContext } from './hooks/loading'

import { Button } from 'components/Button'
import { UserFormModal } from 'components/users/UserFormModal'
import { useModal } from 'shared/modal'

import Edit from 'assets/images/icones/icon-edit.svg'
import Trash from 'assets/images/icones/icon-trash.svg'
import { DeleteUserModal } from 'components/users/DeleteUserModal'

import * as S from '../../styled'
import { MetaProvider, useMetaContext } from './hooks/meta'

const User = ({ client, user, index, modal, deleteModal }: any) => {
  const { id, name, email, phone } = user
  const [meta] = useMetaContext()

  const userType = () => {
    return Object.keys(UserProfile)
      .map(profile => parseInt(profile))
      .filter(profile => !Number.isNaN(profile))
      .filter(profile => (user.profile & profile) > 0)
      .map(index => getUserProfileDescription(index))
      .join(', ')
  }

  const openEditModal = (e: any) => {
    e.preventDefault()
    modal.open({ client, user })
  }

  const openDeleteModal = () => {
    deleteModal.open({
      user,
      close: () => {
        meta('update')
        deleteModal.close()
      }
    })
  }

  return (
    <>
      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>{id}</S.ItemTable>
      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>{name}</S.ItemTable>
      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>{email}</S.ItemTable>
      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>{phone}</S.ItemTable>
      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>{userType()}</S.ItemTable>

      <S.ItemTable className={index % 2 === 0 ? 'dark' : 'light'}>
        <S.IconEdit onClick={openEditModal} src={Edit} />
        <S.IconTrash onClick={openDeleteModal} src={Trash} />
      </S.ItemTable>
    </>
  )
}
const ClientUsersContent: React.FC<{ client: Model.Client }> = ({ client }) => {
  const [loading] = useLoadingContext()!
  const [users] = useUserContext()
  const [meta] = useMetaContext()
  const modal = useModal(UserFormModal)
  const deleteModal = useModal(DeleteUserModal)

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    modal.open({
      client,
      onClosed: () => {
        meta('update')
      }
    })
  }

  return (
    <>
      <div
        style={{
          width: '15rem',
          marginLeft: 'auto',
          textAlign: 'right'
        }}>
        <Button onClick={onClick} outline>
          Criar usuário
        </Button>
      </div>
      <S.Table className={'usuarios'}>
        <S.ItemTable className={'first-line'}>ID</S.ItemTable>
        <S.ItemTable className={'first-line'}>Nome</S.ItemTable>
        <S.ItemTable className={'first-line'}>E-mail</S.ItemTable>
        <S.ItemTable className={'first-line'}>Telefone</S.ItemTable>
        <S.ItemTable className={'first-line'}>Perfil</S.ItemTable>
        <S.ItemTable className={'first-line'}></S.ItemTable>

        {loading ? (
          <Skeleton width="600%" height="50px" count={5} style={{ marginBottom: '50px' }} />
        ) : (
          users!.map((user: any, index: number) => (
            <User key={index} index={index} client={client} user={user} modal={modal} deleteModal={deleteModal} />
          ))
        )}
      </S.Table>
    </>
  )
}

export const ClientUsers: App.ClientViewSection = ({ state: [client] }) => (
  <LoadingProvider>
    <UserProvider>
      <MetaProvider id={client.id}>
        <ClientUsersContent client={client} />
      </MetaProvider>
    </UserProvider>
  </LoadingProvider>
)
