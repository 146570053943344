export const handleTimeout = (callback: () => void, timeout: number = 900) => {
  setTimeout(callback, timeout)
}

export function clipboardWrite(value: string) {
  return (event: React.MouseEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(value).catch(() => {
      alert('Falha ao copiar para área de transferência')
    })
  }
}
