export enum Item {
  CAPTURE_TOUR = 5,
  EDIT_TOUR = 6,
  PRESENTATION_TOUR = 7,
  CAPTURE_PHOTOS = 8,
  EDIT_PHOTOS = 9,
  VIDEOS = 10,
  BLUEPRINTS = 11,
  CAPTURE_TOUR_PHOTOS_TOUR = 13,
  CAPTURE_TOUR_PHOTOS_PHOTOS = 14,
  EDIT_TOUR_PHOTOS = 15,
  PRESENTATION_TOUR_PHOTOS = 16,
  COPYWRITING = 18
}
