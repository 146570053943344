import React, { useState, useContext } from 'react'

function usePagination(): [any, any] {
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
    totalSessions: 0,
    sessionsPerPage: 25
  })

  const reducer = (action: string, page: number | any = pagination.page) => {
    switch (action) {
      case 'next':
        page = page === pagination.lastPage ? page : page + 1
        setPagination({ ...pagination, page })
        break

      case 'select':
        setPagination({ ...pagination, page })
        break

      case 'previous':
        page = page === 1 ? 1 : page - 1
        setPagination({ ...pagination, page })
        break

      case 'meta':
        setPagination({ ...pagination, ...page })
        break
    }
  }

  return [pagination, reducer]
}

const PaginationContext = React.createContext<[any, any] | undefined>(undefined)

export const usePaginationContext = () => useContext(PaginationContext)!

export const PaginationProvider: React.FC = props => {
  const pagination = usePagination()

  return <PaginationContext.Provider value={pagination} {...props} />
}
