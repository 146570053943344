import React, { useState, useContext } from 'react'

function useSessions(): ReactState<any[]> {
  const [sessions, setSessions] = useState<any[]>([])

  return [sessions, setSessions]
}

const SessionContext = React.createContext<ReactState<any[]> | undefined>(undefined)

export const useSessionContext = () => useContext(SessionContext)!

export const SessionsProvider: React.FC = props => {
  const sessions = useSessions()

  return <SessionContext.Provider value={sessions} {...props} />
}
