import React, { useState } from 'react'

import { Button } from 'components/Button'

import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'
import { Modal, ModalHeader } from 'shared/modal'

import classes from './TransactionModal.module.scss'
import { toFormData } from 'shared/util/form-data'

interface TransactionModalProps {
  client?: Model.Client
}

export const TransactionModal: App.Modal<TransactionModalProps> = ({ client, close }) => {
  const [cost, setCost] = useState(0)

  const save = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (cost !== 0) {
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ cost })
      }

      fetch(`/api/clients/${client?.id}/transactions`, init).then(close)
    }
  }

  return (
    <Modal size="md">
      <ModalHeader>
        <h2>Nova transação</h2>
      </ModalHeader>
      <form onSubmit={save}>
        <FormGroup label="Valor">
          <FormText type="number" onChange={value => setCost(Number(value))} value={cost.toString()} required />
        </FormGroup>
        <hr />
        <div className={classes.footer}>
          <Button outline onClick={close} type="button">
            CANCELAR
          </Button>
          <Button type="submit">SALVAR</Button>
        </div>
      </form>
    </Modal>
  )
}
