import React from 'react'

import { Icon } from 'shared/ui/icon'

import classes from './ModalHeader.module.scss'

interface ModalHeaderProps {
  close?: () => void
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ close, ...props }) => {
  return (
    <div className={classes.container}>
      <div {...props} />
      {close && (
        <button className={classes.close} type="button" onClick={close}>
          <Icon.Times />
        </button>
      )}
    </div>
  )
}
