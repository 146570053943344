import React from 'react'

import { classNames } from '../../util/class-names'

interface CardBodyProps {
  className?: string
}

export const CardBody: React.FC<CardBodyProps> = ({ className, ...props }) => {
  return <div className={classNames('card-body', className)} {...props} />
}
