import { format, isValid, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const options = {
  locale: ptBR
}

export function formatDate(date: Date, formatString: string) {
  if (isValid(date)) {
    return format(date, formatString, options)
  }
}

export function formatISO(dateString: string, formatString: string) {
  const date = parseISO(dateString)
  return formatDate(date, formatString)
}
