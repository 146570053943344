import React, { useState } from 'react'

import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { Input } from 'shared/bootstrap/form/Input'
import { InputAutocomplete } from 'shared/bootstrap/form/InputAutocomplete'
// import { InputDateRange } from 'shared/bootstrap/form/InputDateRange'
import { ClientDataSource } from 'shared/hooks/source/ClientDataSource'
// import { ProviderDataSource } from 'shared/hooks/source/ProviderDataSource'
// import { ServiceDataSource } from 'shared/hooks/source/ServiceDataSource'

import classes from './ProviderListFilter.module.scss'

interface ProviderListFilterProps {
  onChange?: (_: any) => void
}

export const ProviderListFilter: React.FC<ProviderListFilterProps> = ({ onChange }) => {
  const [, setValue] = useState<object>()
  const handleChange = (key: string) => (value: string | number) => {
    setValue(current => {
      onChange?.({ ...current, [key]: value })
      return { ...current, [key]: value }
    })
  }
  return (
    <div className={classes.container}>
      <FormGroup label="Cliente">
        <ClientDataSource>
          <InputAutocomplete onChange={handleChange('client_id')} />
        </ClientDataSource>
      </FormGroup>
      <FormGroup label="Nome">
        <Input maxLength={200} placeholder="Nome do fornecedor" onChange={handleChange('name')} />
      </FormGroup>
      <FormGroup className={classes.search} label="Buscar">
        <Input maxLength={200} placeholder="ID ou endereço" onChange={handleChange('search')} />
      </FormGroup>
    </div>
  )
}
