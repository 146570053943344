import React, { useCallback, useState } from 'react'

import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { useSessions } from 'shared/hooks/data/session'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
// import { clipboardWrite } from 'shared/util/functions'
import { parseAddress } from 'shared/util/google-maps'

import classes from './SessionListTable.module.scss'

interface Props {
  className?: string
  filter?: { [key: string]: any }
}

export const SessionListTable: React.FC<Props> = ({ filter, ...props }) => {
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const sessions = useSessions(filter, paging, handleMeta)
  return (
    <Table hover strip {...props}>
      <TableHeader>
        <TableColumn name="id" align="center">
          Ensaio
        </TableColumn>
        <TableColumn size={3}>Endereço</TableColumn>
        <TableColumn size={3}>Informações</TableColumn>
      </TableHeader>
      <TableBody>
        {sessions?.map(session => {
          const address = parseAddress(session.address?.address_components)
          return (
            <TableRow key={session.id}>
              <TableColumn align="center">
                <Button className={classes.id} size="sm" to={`/sessions/${session.id}`}>
                  <span>{session.id}</span>
                  <Icon.AngleRight />
                </Button>
                <div className={classes.status}>
                  <Badge color={getSessionStatusColor(session.status)} size="sm">
                    {getSessionStatusDescription(session.status)}
                  </Badge>
                </div>
              </TableColumn>
              <TableColumn align="center" size={3}>
                <div>
                  {address.get('street')}
                  {address.has('number') && `, ${address.get('number')}`}
                  {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                </div>
                <div>
                  {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
                </div>
              </TableColumn>
              <TableColumn align="center" size={3}>
                <div>
                  <strong>{session.client.name}</strong>
                </div>
                <div>{session.provider?.name || '-'}</div>
                {session.scheduled_for && (
                  <div>{formatISO(session.scheduled_for, 'dd/MM/yyyy HH:mm')}</div>
                )}
              </TableColumn>
            </TableRow>
          )
        })}
      </TableBody>
      <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
    </Table>
  )
}
