import React, { useCallback, useState } from 'react'

import { useClients } from 'shared/hooks/data/client'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { parseAddress } from 'shared/util/google-maps'

import classes from './ClientListTable.module.scss'

interface ClientListTableProps {
  className?: string
  filter?: { [key: string]: any }
}

export const ClientListTable: React.FC<ClientListTableProps> = ({ className, filter }) => {
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 100 })
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const clients = useClients(filter, paging, handleMeta)
  return (
    <Table className={className} hover strip>
      <TableHeader>
        <TableColumn name="id" align="center">
          ID
        </TableColumn>
        <TableColumn name="client" align="center" size={3}>
          Cliente
        </TableColumn>
        <TableColumn name="city" align="center" size={2}>
          Cidade
        </TableColumn>
        <TableColumn name="address" align="center" size={3}>
          Endereço
        </TableColumn>
        <TableColumn name="created_at" align="center">
          Data de criação
        </TableColumn>
      </TableHeader>
      <TableBody>
        {clients?.map(client => {
          const address = parseAddress(client.address?.address_components)
          return (
            <TableRow key={client.id}>
              <TableColumn align="center">
                <Button className={classes.id} size="sm" to={`/clients/${client.id}`}>
                  <span>{client.id}</span>
                  <Icon.AngleRight />
                </Button>
              </TableColumn>
              <TableColumn align="center" size={3}>
                {client.name}
              </TableColumn>
              <TableColumn align="center" size={2}>
                {address.get('city')}
                {address.has('state') && `, ${address.get('state')}`}
              </TableColumn>
              <TableColumn align="center" size={3}>
                <div>
                  {address.get('street')}
                  {address.has('number') && `, ${address.get('number')}`}
                </div>
                {address.has('neighborhood') && <div>{address.get('neighborhood')}</div>}
              </TableColumn>
              <TableColumn align="center">{formatISO(client.created_at, 'dd/MM/yyyy HH:mm')}</TableColumn>
            </TableRow>
          )
        })}
      </TableBody>
      <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
    </Table>
  )
}
