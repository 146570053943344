import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from 'react-router-dom'

import { ServiceItems } from '../components/ServiceItems'

export const ServiceDeliverables: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [service, setService] = useState<Model.Service>()

  useEffect(() => {
    fetch(`/api/services/${match.params.id}`)
      .then(res => res.json())
      .then(({ data }) => setService(data))
  }, [match])

  if (!service) {
    return <></>
  }

  return <ServiceItems service={service} />
}
