import React, { useContext, useState } from 'react'

export interface Sort {
  order_by: string
  descending: boolean
}

const initState: Sort = {
  order_by: 'scheduled_for',
  descending: true
}

function useSort(): ReactState<Sort> {
  const [sort, setSort] = useState(initState)

  return [sort, setSort]
}

const SortContext = React.createContext<ReactState<Sort> | undefined>(undefined)

export const useSortContext = () => useContext(SortContext)!

export const SortProvider: React.FC = props => {
  const sort = useSort()

  return <SortContext.Provider value={sort} {...props} />
}
