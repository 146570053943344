import React, { useState } from 'react'

import { Button } from 'components/Button'

import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'
import { Modal, ModalHeader } from 'shared/modal'

import { toFormData } from 'shared/util/form-data'
import { FormInputGroup } from 'shared/form/FormInputGroup'

import classes from './VoucherModal.module.scss'
import { Icon } from 'shared/ui/icon'

// TODO: wrong place!
interface VoucherModalProps {
  service: Model.Service
  close?: () => void
}

export const VoucherModal: App.Modal<VoucherModalProps> = ({ service, close }) => {
  const [quantity, setQuantity] = useState(0)
  const [cost, setCost] = useState(0)
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()

  const save = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (cost !== 0 && quantity !== 0) {
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ unit_price: cost, quantity, title, description })
      }

      fetch(`/api/services/${service.id}/vouchers`, init).then(() => window.location.reload())
    }
  }

  return (
    <Modal size="md">
      <ModalHeader>
        <h2>Novo pacote</h2>
      </ModalHeader>
      <form onSubmit={save}>
        <FormGroup label="Título">
          <FormText type="text" required value={title} onChange={setTitle} />
        </FormGroup>
        <FormGroup label="Descrição (opcional)">
          <FormText type="text" value={description} onChange={setDescription} />
        </FormGroup>
        <FormGroup label="Quantidade">
          <div className={classes.line}>
            <Icon.Minus
              className={classes.lineicon}
              size="2x"
              onClick={() => setQuantity(quantity => Math.max(0, --quantity))}
            />
            <FormText
              type="number"
              required
              value={quantity.toString()}
              onChange={value => setQuantity(Number(value))}
            />
            <Icon.PlusCircle
              className={classes.lineicon}
              size="2x"
              onClick={() => setQuantity(quantity => ++quantity)}
            />
          </div>
        </FormGroup>
        <FormGroup label="Valor Unitário">
          <FormInputGroup>
            <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
            <FormText type="number" required value={cost.toString()} onChange={value => setCost(Number(value))} />
          </FormInputGroup>
        </FormGroup>
        <FormGroup label="Valor total">
          <FormInputGroup>
            <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
            <FormText readOnly value={(cost * quantity).toString()} />
          </FormInputGroup>
        </FormGroup>
        <FormGroup label="Valor padrão">
          <FormInputGroup>
            <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
            <FormText readOnly value={service.price ? service.price.toString() : '-'} />
          </FormInputGroup>
        </FormGroup>
        <div className={classes.line}>
          <FormGroup label="Valor do desconto efetivo">
            <FormInputGroup>
              <FormInputGroup.Prepend>R$</FormInputGroup.Prepend>
              <FormText readOnly value={service.price ? (service.price - cost).toString() : '-'} />
            </FormInputGroup>
          </FormGroup>
          <FormGroup label="Porcentagem do desconto">
            <FormInputGroup>
              <FormText
                readOnly
                value={service.price ? Math.round((1 - cost / service.price) * 100).toString() : '-'}
              />
              <FormInputGroup.Append>%</FormInputGroup.Append>
            </FormInputGroup>
          </FormGroup>
        </div>
        <hr />
        <div className={classes.footer}>
          <Button outline onClick={close} type="button">
            CANCELAR
          </Button>
          <Button type="submit">SALVAR</Button>
        </div>
      </form>
    </Modal>
  )
}
