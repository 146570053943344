import React, { useContext } from 'react'

import { classNames } from '../../util/class-names'

import classes from './Table.module.scss'

interface TableProps {
  className?: string
  hover?: boolean
  strip?: boolean
}

interface TableContext {
  hover: boolean
  strip: boolean
}

const Context = React.createContext<TableContext>(undefined!)

export function useTableContext() {
  return useContext(Context)
}

export const Table: React.FC<TableProps> = ({ className, children, ...props }) => {
  const context = {
    hover: Boolean(props.hover),
    strip: Boolean(props.strip)
  }
  return (
    <Context.Provider value={context}>
      <div className={classNames(classes.table, className)}>{children}</div>
    </Context.Provider>
  )
}
