import React from 'react'
import { Link } from 'react-router-dom'

import { classNames } from 'shared/util/class-names'

import classes from './Button.module.scss'

interface ButtonProps {
  active?: boolean
  className?: string
  disabled?: boolean
  href?: string
  onClick?: (_: React.MouseEvent) => void
  outline?: boolean
  size?: 'sm' | 'md' | 'lg'
  target?: 'blank'
  to?: string
  type?: 'button' | 'submit'
  variant?: 'danger' | 'flat' | 'info' | 'link' | 'primary' | 'secondary' | 'warning'
  style?: React.CSSProperties
}

export const Button: React.FC<ButtonProps> = ({ active, className, outline, size, to, variant, ...props }) => {
  size = size || 'md'
  variant = variant || 'primary'

  const params = {
    className: classNames(
      'button',
      className,
      className && classes[className],
      classes.button,
      classes[`size-${size}`],
      classes[`variant-${variant}`],
      active && classes.active,
      outline && classes.outline
    )
  }

  if (to) {
    return <Link to={to} {...params} {...props} />
  }
  if (props.href !== undefined) {
    return (
      <a {...params} {...props} rel="noreferrer noopener" target="_blank">
        {props.children}
      </a>
    )
  }
  return <button {...props} {...params} />
}
