import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { SessionAdd } from './session-add/SessionAdd'
import { SessionList } from './session-list/SessionList'
import { SessionView } from './session-view/SessionView'

export const Sessions: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/add`} component={SessionAdd} />
    <Route path={`${match.path}/:id(\\d+)`} component={SessionView} />
    <Route path={`${match.path}/:scope?`} component={SessionList} />
  </Switch>
)
