import React from 'react'
import styled from 'styled-components'

const MyFooter = styled.nav`
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
`

const Text = styled.p`
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #7f7f7f;
`

export const Footer = () => (
  <MyFooter>
    <Text>© 2020 BIRDIE LTDA.</Text>
  </MyFooter>
)
