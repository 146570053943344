import React, { Fragment, useEffect, useRef, useState } from 'react'

import { ServiceFormModal } from 'components/services/ServiceFormModal'
import { useClients } from 'hooks/data/client'
import { useClientCostCenters } from 'hooks/data/client-cost-center'
import { useClientServices } from 'hooks/data/client-service'
import { FormGroup } from 'shared/form/FormGroup'
import { FormSelect } from 'shared/form/FormSelect'
import { FormText } from 'shared/form/FormText'
import { Button } from 'shared/ui/button/Button'
import { CardGrey } from 'shared/ui/card/CardGrey'
import { Icon } from 'shared/ui/icon'
import { useModal } from 'shared/modal'

import classes from './BasicInfo.module.scss'

interface BasicInfoValue {
  address: google.maps.places.PlaceResult | google.maps.GeocoderResult
  client: Model.Client
  costCenter: Model.CostCenter
  service: Partial<Model.Service>
}

export const BasicInfo: App.SessionAddSection = ({ history, state: [sessions, setSessions] }) => {
  const [value, setValue] = useState<Partial<BasicInfoValue>>({})
  const addressRef = useRef<HTMLInputElement>(null)
  const autocompleteRef = useRef<google.maps.places.Autocomplete>()
  const serviceModalRef = useModal(ServiceFormModal)
  useEffect(() => {
    if (addressRef.current && !Boolean(autocompleteRef.current)) {
      autocompleteRef.current = new google.maps.places.Autocomplete(addressRef.current, {
        componentRestrictions: {
          country: ['BR']
        },
        types: ['address']
      })
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current?.getPlace()
        if (place) {
          setValue(value => ({ ...value, address: place }))
          addressRef.current!.value = place.formatted_address!
        }
      })
    }
  }, [])
  const clients = useClients()
  const costCenters = useClientCostCenters(value.client?.id)
  const services = useClientServices(value.client?.id)
  const handleAddService = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    serviceModalRef.open({
      onSubmit: (service: Partial<Model.Service>) => {
        setValue(value => ({ ...value, service }))
      },
      service: value.service
    })
  }
  const handleClient = (clientId: string) => {
    const client = clients?.find(({ id }) => id === Number(clientId))
    setValue(value => ({ ...value, client }))
  }
  const handleCostCenter = (costCenterId: string) => {
    const costCenter = costCenters?.find(({ id }) => id === Number(costCenterId))
    setValue(value => ({ ...value, costCenter }))
  }
  const handleRemove = (i: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    setSessions(sessions => sessions.filter((_, index) => index !== i))
  }
  const handleService = (serviceId: string) => {
    const service = services?.find(({ id }) => id === Number(serviceId))
    setValue(value => ({ ...value, service }))
  }
  const handleSession = (k: number) => (key: string) => (value: string) => {
    setSessions(sessions =>
      sessions.map((session, i) => {
        if (k === i) {
          const vertical_data = Object.assign(session.vertical_data || {}, { [key]: value })
          return Object.assign({}, session, { vertical_data })
        } else {
          return session
        }
      })
    )
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setSessions(sessions =>
      sessions.map(session => ({
        ...session,
        address: value.address!,
        client: value.client!,
        cost_center: value.costCenter!,
        service: value.service!
      }))
    )
    history.push('/sessions/add/responsible')
  }
  return (
    <form onSubmit={handleSubmit}>
      <h1>Informações básicas</h1>
      <FormGroup label="Cliente">
        <FormSelect required onChange={handleClient}>
          <option value="">Selecione</option>
          {clients?.map(client => (
            <option key={client.id} value={client.id}>
              {client.name}
            </option>
          ))}
        </FormSelect>
      </FormGroup>
      <div className={classes.service}>
        <FormGroup label="Serviço">
          <FormSelect
            required
            disabled={services === undefined || (value.service && !value.service.id)}
            onChange={handleService}>
            <option value="">Selecione</option>
            {services?.map(service => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <Button className={classes.serviceAdd} disabled={!value.client?.id} onClick={handleAddService} outline>
          <span>SERVIÇO</span>
          <Icon.PlusCircle />
        </Button>
      </div>
      {value.service && !value.service.id && (
        <p>
          <strong>Serviço personalizado:</strong> {value.service.name}
        </p>
      )}
      <FormGroup label="Centro de custos">
        <FormSelect required disabled={costCenters === undefined} onChange={handleCostCenter}>
          <option value="">Selecione</option>
          {costCenters?.map(costCenter => (
            <option key={costCenter.id} value={costCenter.id}>
              {costCenter.name}
            </option>
          ))}
        </FormSelect>
      </FormGroup>
      <FormGroup label="Endereço (com número)">
        <FormText placeholder="Pesquisar endereço" ref={addressRef} required value={value.address?.formatted_address} />
      </FormGroup>
      {sessions.map(({ vertical_data: data = {} }, i) => (
        <Fragment key={i}>
          <h2>Ensaio</h2>
          <CardGrey className={classes.card}>
            <div className={classes.session}>
              <FormGroup label="Unidade">
                <FormText onChange={handleSession(i)('unit')} value={data.unit?.toString()} />
              </FormGroup>
              <FormGroup label="Bloco/Torre/Quadra">
                <FormText onChange={handleSession(i)('complement')} value={data.complement?.toString()} />
              </FormGroup>
              <FormGroup label="Área">
                <FormText required onChange={handleSession(i)('area')} value={data.area?.toString()} />
              </FormGroup>
              <FormGroup label="Ref. do imóvel">
                <FormText onChange={handleSession(i)('reference')} value={data.reference?.toString()} />
              </FormGroup>
              {i > 0 && (
                <Button className={classes.remove} size="sm" outline onClick={handleRemove(i)}>
                  REMOVER
                </Button>
              )}
            </div>
          </CardGrey>
        </Fragment>
      ))}
      <Button>AVANÇAR →</Button>
    </form>
  )
}
