import React, { createContext, useContext, useRef } from 'react'

interface FormGroupContextProps {
  id: string
}

interface FormGroupProps {
  className?: string
  label?: string
}

let uniqueId = 0

const FormGroupContext = createContext<FormGroupContextProps>(undefined!)

export function useFormGroupContext(): FormGroupContextProps | undefined {
  return useContext(FormGroupContext)
}

export const FormGroup: React.FC<FormGroupProps> = ({ children, className, label }) => {
  const ref = useRef<FormGroupContextProps>({
    id: `form-input-${++uniqueId}`
  })
  return (
    <FormGroupContext.Provider value={ref.current}>
      <div className={className}>
        {label && (
          <label htmlFor={ref.current.id} className="form-label">
            {label}
          </label>
        )}
        {children}
      </div>
    </FormGroupContext.Provider>
  )
}
