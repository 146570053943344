import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './CardGrey.module.scss'

interface CardGreyProps {
  className?: string
}

export const CardGrey: React.FC<CardGreyProps> = ({ className, ...props }) => {
  return <div className={classNames(className, classes.card)} {...props} />
}
