import React, { useState } from 'react'
import ReactQuill from 'react-quill'

import { FormFeedbackSuccess } from 'shared/form/FormFeedbackSuccess'
import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'
import { FormState } from 'shared/form/enum/FormState'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableText.module.scss'

interface Props {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableText>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

interface Item {
  id?: number
  title: string | null
  content: string | null
}

export const SessionDeliverableText: React.FC<Props> = ({ /*category,*/ product, session }) => {
  const [current, setCurrent] = useState<Item[]>(product.deliverables || [])
  const [state, setState] = useState<FormState>(FormState.INITIAL)
  const handleAdd = (event: React.MouseEvent) => {
    event.preventDefault()
    setCurrent(current => [...current, { title: '', content: '' }])
  }
  const handleChange = (i: number, key: keyof Item) => (value: string) => {
    const changed = current[i]
    setCurrent(current => {
      const index = current.indexOf(changed)
      const item = { ...current[index], [key]: value }
      return [...current.slice(0, index), item, ...current.slice(index + 1)]
    })
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setState(state => {
      if (state === FormState.INITIAL) {
        const values = [...current]
        const deliverables = values.map(({ id, title, content }) => {
          const baseUrl = `/api/sessions/${session.id}/${product.id}/texts`
          const url = id ? `${baseUrl}/${id}` : baseUrl
          const body = toFormData({ title, content })
          return fetch(url, { body, method: 'POST' }).then(response => response.json())
        })
        Promise.allSettled(deliverables)
          .then(results => {
            setCurrent(
              values.map((value, i) => {
                const result = results[i]
                if (result.status === 'rejected') {
                  return value
                } else {
                  return result.value.data as Item
                }
              })
            )
            const failed = results
              .map(({ status }, i) => {
                if (status === 'rejected') {
                  return `#${i + 1}`
                } else {
                  return false
                }
              })
              .filter(Boolean)
            if (failed.length === 1) {
              alert(`Falha ao salvar ${failed[0]}`)
            } else if (failed.length > 1) {
              alert(`Falha ao salvar os conteúdos ${failed.join(', ')}`)
            }
          })
          .then(() => setState(FormState.SUCCESSFULL))
          .then(() => setState(FormState.INITIAL))
        return FormState.SUBMITTING
      } else {
        return state
      }
    })
  }
  return (
    <form className={classes.content} onSubmit={handleSubmit}>
      {current.map(({ content, title }, i) => (
        <div key={i} className={classes.item}>
          <FormGroup label={`Título #${i + 1}`}>
            <FormText onChange={handleChange(i, 'title')} value={title} />
          </FormGroup>
          <FormGroup label={`Conteúdo #${i + 1}`}>
            <ReactQuill
              className={classes.quill}
              theme="snow"
              onChange={handleChange(i, 'content')}
              value={content || ''}
            />
          </FormGroup>
        </div>
      ))}
      <div className={classes.footer}>
        <Button className={classes.add} onClick={handleAdd} variant="info">
          <Icon.PlusCircle /> ADICIONAR CONTEÚDO
        </Button>
        <FormFeedbackSuccess autoHide={10} state={state} />
        <Button type="submit">SALVAR</Button>
      </div>
    </form>
  )
}
