import React, { useState, useContext } from 'react'

function useSessions(): [any[], (_: any[]) => void] {
  const [sessions, setSessions] = useState<any[]>([])

  return [sessions, setSessions]
}

const SessionContext = React.createContext<[any[], (_: any[]) => void] | undefined>(undefined)

export const useSessionContext = () => useContext(SessionContext)!

export const SessionsProvider: React.FC = props => {
  const sessions = useSessions()

  return <SessionContext.Provider value={sessions} {...props} />
}
