import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Card.module.scss'

interface CardProps {
  className?: string
  onClick?: (_: React.MouseEvent<HTMLDivElement>) => void
  rounded?: false | '2x'
  shadow?: boolean
  solidBorder?: boolean
  style?: React.CSSProperties
  variant?: 'black' | 'light' | 'light-grey' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
}

export const Card: React.FC<CardProps> = ({ className, shadow, variant, rounded, solidBorder, ...props }) => {
  const klass = classNames(
    classes.card,
    rounded === false && classes.square,
    rounded === '2x' && classes.doubleBorderRadius,
    shadow && classes.shadow,
    solidBorder && classes.solidBorder,
    variant && classes[`variant-${variant}`],
    className
  )
  return <div className={klass} {...props} />
}
