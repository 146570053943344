import React, { Fragment, createElement } from 'react'
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Card, CardBody } from 'shared/bootstrap/card'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { toFormData } from 'shared/util/form-data'

import { Button } from 'components/Button'

import { useClient } from 'hooks/data/client'

import { ClientCosts } from './tabs/ClientCosts'
import { ClientInfo } from './tabs/ClientInfo'
import { ClientTransactions } from './tabs/ClientTransactions/ClientTransactions'
import { ClientOrientations } from './tabs/ClientOrientations'
import { ClientServices } from './tabs/ClientServices/ClientServices'
import { ClientSessions } from './tabs/ClientSessions/ClientSessions'
import { ClientUsers } from './tabs/ClientUsers/ClientUsers'

import classes from './ClientView.module.scss'

export const ClientView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [client, setClient] = useClient(match.params)
  const state = [client!, setClient] as App.ReactState<Model.Client>
  const section = (component: React.FC<{ state: App.ReactState<Model.Client> }>) => ({
    render: () => createElement(component, { state })
  })

  const changeClientPaymentType = (payment_plan: number) => {
    if (client) {
      const init: RequestInit = {
        method: 'POST',
        body: toFormData({ payment_plan })
      }

      fetch(`/api/clients/${client.id}`, init)
        .then(res => res.json())
        .then(({ data }) => setClient(data))
    }
  }

  return (
    <Fragment>
      {client && (
        <Fragment>
          <Breadcrumb>
            <BreadcrumbItem to="/clients">Clientes</BreadcrumbItem>
            <BreadcrumbItem>Cliente {client.id}</BreadcrumbItem>
          </Breadcrumb>
          <div className={classes.container}>
            <div className={classes.info}>
              <Card shadow>
                <CardBody>
                  <h1>{client.name}</h1>
                  {client.city && <h2>{client.city.name}</h2>}
                  <hr />
                  <p>ID {client.id}</p>
                  <p className={classes.address}>{client.address?.formatted_address || '-'}</p>
                  {client.city && (
                    <p>
                      {client.city.name}/{client.city.state}
                    </p>
                  )}
                  <p className={classes.corporateName}>Razão Social: {client.corporate_name}</p>
                  <p>CNPJ: {client.identification}</p>
                </CardBody>
              </Card>
              <Card shadow>
                <CardBody>
                  <h2>
                    Cliente <span>{client.payment_plan === 2 ? 'PÓS PAGO' : 'PRÉ PAGO'}</span>
                  </h2>

                  <Button onClick={() => changeClientPaymentType(client.payment_plan === 1 ? 2 : 1)}>
                    Trocar para {client.payment_plan === 1 ? 'pós pago' : 'pré pago'}
                  </Button>
                </CardBody>
              </Card>
            </div>

            <Card className={classes.sections} shadow>
              <CardBody>
                <nav className={classes.nav}>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/sessions`}>
                    Ensaios
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/services`}>
                    Serviços
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/users`}>
                    Usuários
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/orientations`}>
                    Orientações ao fornecedor
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/info`}>
                    Dados básicos
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/transactions`}>
                    Transações
                  </NavLink>
                </nav>
                <Switch>
                  <Route path={`${match.path}/costs`} {...section(ClientCosts)} />
                  <Route path={`${match.path}/info`} {...section(ClientInfo)} />
                  <Route path={`${match.path}/orientations`} {...section(ClientOrientations)} />
                  <Route path={`${match.path}/services`} {...section(ClientServices)} />
                  <Route path={`${match.path}/sessions`} {...section(ClientSessions)} />
                  <Route path={`${match.path}/users`} {...section(ClientUsers)} />
                  <Route path={`${match.path}/transactions`} {...section(ClientTransactions)} />
                  <Redirect to={`${match.url}/sessions`} />
                </Switch>
              </CardBody>
            </Card>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
