import React, { useEffect, useRef } from 'react'

import { classNames } from '../util/class-names'

import classes from './FormAddress.module.scss'

interface FormAddressProps {
  className?: string
  onChange?: (_: google.maps.places.PlaceResult) => void
  placeholder?: string
  required?: boolean
  type?: 'address' | 'city' | 'cities'
  value?: string
}

export const FormAddress: React.FC<FormAddressProps> = ({ className, onChange, value, type = 'address', ...props }) => {
  const useType = type === 'city' || type === 'cities' ? '(cities)' : type
  const autocomplete = useRef<google.maps.places.Autocomplete>()
  const input = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (input.current && !autocomplete.current) {
      autocomplete.current = new google.maps.places.Autocomplete(input.current, {
        componentRestrictions: {
          country: ['BR']
        },
        types: [useType]
      })
      autocomplete.current.addListener('place_changed', () => {
        const place = autocomplete.current?.getPlace()
        if (place) {
          onChange?.(place)
        }
      })
    }
  }, [onChange, useType])
  useEffect(() => {
    if (input.current && value) {
      input.current.value = value
    }
  }, [value])
  const params = {
    className: classNames(classes.input, className),
    placeholder: type === 'cities' || type === 'city' ? 'São Paulo, SP' : 'Paulista, 1578',
    ref: input
  }
  return <input {...params} {...props} />
}
