import React, { useState } from 'react'

import { Button } from 'shared/ui/button/Button'
import { FormGroup } from 'shared/form/FormGroup'
import { FormInputGroup } from 'shared/form/FormInputGroup'
// import { FormLogo } from 'shared/form/FormLogo'
// import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
// import {classNames} from 'shared/util/class-names'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableTour.module.scss'

interface SessionDeliverableTourProps {
  onChange?: () => void
  product: Resource.SessionResourceProduct<Resource.SessionResourceDeliverableTour>
  session: Resource.SessionResource
}

export const SessionDeliverableTour: React.FC<SessionDeliverableTourProps> = ({ product, session }) => {
  const [tour, setTour] = useState(product.deliverables?.[0])
  const [current, setCurrent] = useState(tour || {})
  const handleChange = (key: string) => (value: string) => {
    setCurrent(current => ({ ...current, [key]: value }))
  }
  // const handleLogo = ([logo]: File[]) => {
  //   setCurrent(current => ({ ...current, logo }))
  // }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData(current)
    fetch(`/api/sessions/${session.id}/${product.id}/tours`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          alert('Salvo com sucesso!')
          return response.json()
        } else {
          throw new Error('Failed to save')
        }
      })
      .then(({ data }) => setTour(data))
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.urls}>
        <FormGroup label="URL do Tour Virtual (Matterport)">
          <FormText type="url" onChange={handleChange('matterport_url')} value={current.matterport_url} />
        </FormGroup>
        <FormGroup label="URL do Tour Virtual (Birdie)">
          <FormText type="url" onChange={handleChange('birdie_url')} value={current.birdie_url} />
        </FormGroup>
        <FormGroup label="Prazo de hospedagem">
          <FormInputGroup>
            <FormText onChange={handleChange('host_prize')} value={current.host_prize?.toString()} />
            <FormInputGroup.Append>meses</FormInputGroup.Append>
          </FormInputGroup>
        </FormGroup>
      </div>
      {/*<div className={classes.custom}>
        <p className={classes.title}>Personalizar o Tour Virtual</p>
        <div className={classes.fields}>
          <div className={classes.info}>
            <FormGroup label="Nome">
              <FormText onChange={handleChange('tour_name')} value={current.tour_name} />
            </FormGroup>
            <FormGroup label="Endereço">
              <FormText onChange={handleChange('tour_address')} value={current.tour_address} />
            </FormGroup>
            <FormGroup label="Nome para contato">
              <FormText onChange={handleChange('tour_contact_name')} value={current.tour_contact_name} />
            </FormGroup>
            <FormGroup label="E-mail do contato">
              <FormText type="email" onChange={handleChange('tour_contact_email')} value={current.tour_contact_email} />
            </FormGroup>
            <FormGroup label="Apresentado por">
              <FormText onChange={handleChange('tour_presented_by')} value={current.tour_presented_by} />
            </FormGroup>
            <FormGroup label="Link externo">
              <FormText type="url" onChange={handleChange('tour_external_url')} value={current.tour_external_url} />
            </FormGroup>
            <FormGroup label="Telefone do contato">
              <FormMasked
                mask="phone"
                onChange={handleChange('tour_contact_phone')}
                value={current.tour_contact_phone}
              />
            </FormGroup>
            <FormGroup label="Descrição">
              <FormText onChange={handleChange('tour_description')} value={current.tour_description} />
            </FormGroup>
          </div>
          {session.client.white_label === true && (
            <div className={classes.logo}>
              <p className={classes.title}>Logo do cliente</p>
              <FormGroup label="Nome do cliente">
                <FormText onChange={handleChange('white_label_name')} value={current.white_label_name} />
              </FormGroup>
              <FormGroup label="Logo">
                <FormLogo onChange={handleLogo} />
              </FormGroup>
              <button className={classes.link}>Escolher logo existente →</button>
            </div>
          )}
        </div>
      </div>*/}
      <div className={classes.save}>
        <Button>SALVAR ALTERAÇÕES</Button>
      </div>
    </form>
  )
}
