import React, { useContext, useState } from 'react'

function useUser(): [any, any] {
  const [user, setUser] = useState<any[]>()

  return [user, setUser]
}

const UserContext = React.createContext<ReactState<any[]> | undefined>(undefined)

export const useUserContext = () => useContext(UserContext)!

export const UserProvider: React.FC = props => {
  const user = useUser()!

  return <UserContext.Provider value={user} {...props} />
}
