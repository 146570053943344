import React, { createElement, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { ModalConfig } from './ModalContainer'
import { ModalHost, ModalHostRef } from './ModalHost'

type Component<P extends {}> = React.FC<P>

export function useModal<P>(component: Component<P>, params?: P & ModalConfig) {
  const container = useRef<Nullable<HTMLDivElement>>(null)
  const [modal, setModal] = useState<Nullable<ModalHostRef<P>>>()
  const ref = useCallback<(_: ModalHostRef<P>) => void>(ref => setModal(ref), [])
  useEffect(() => {
    if (container.current === null) {
      const element = document.createElement('div')
      container.current = document.body.appendChild(element)
      const host = createElement(ModalHost, { component, params, ref })
      ReactDOM.render(host, element)
    }
    return () => {
      container.current?.remove()
    }
    // TODO: Solve this warning
    // eslint-disable-next-line
  }, [component, ref])
  return modal!
}
