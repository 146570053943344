import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { useDebouncedCallback } from 'shared/hooks/util'

import { SessionListFilter } from './components/SessionListFilter'
import { SessionListTable } from './components/SessionListTable'

import classes from './SessionList.module.scss'

export const SessionList: React.FC<RouteComponentProps<{ scope: string }>> = ({ match }) => {
  const [params, setParams] = useState<object>({})
  const handleParams = useDebouncedCallback((filter: object) => setParams({ filter }), [], 400)
  return (
    <Container>
      <div className={classes.header}>
        <Breadcrumb>
          <BreadcrumbItem>Ensaios</BreadcrumbItem>
        </Breadcrumb>
        <Button size="sm" to="/sessions/add">
          CRIAR ENSAIO
        </Button>
      </div>
      <Card className={classes.card} shadow>
        <SessionListFilter onChange={handleParams} />
      </Card>
      <Card className={classes.content} shadow>
        <SessionListTable className={classes.table} params={params} match={match} />
      </Card>
    </Container>
  )
}
