import React from 'react'

import { Table, TableHeader, TableColumn, TableBody, TableRow } from 'shared/table'
import { useHistory } from 'react-router-dom'

interface CityProps {
  city: Partial<Model.City>
}

export const Services: React.FC<CityProps> = ({ city }) => {
  const history = useHistory()

  const goToService = (id: number) => {
    history.push('/services/' + id)
  }

  return (
    <Table>
      <TableHeader>
        <TableColumn>ID</TableColumn>
        <TableColumn size={3} align="flex-start">
          Nome
        </TableColumn>
      </TableHeader>
      <TableBody>
        {city.services?.map((service, index) => (
          <TableRow key={index}>
            <TableColumn onClick={() => goToService(service.id!)}>{service?.id}</TableColumn>
            <TableColumn size={3} align="flex-start">
              {service?.name}
            </TableColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default Services
