import { useEffect, useState } from 'react'

export function useClients() {
  const [clients, setClients] = useState<Model.Client[]>()
  useEffect(() => {
    fetch(`/api/clients`)
      .then(response => response.json())
      .then(({ data }) => setClients(data))
  }, [])
  return clients
}

export function useClient(key: ModelKey) {
  const [client, setClient] = useState<Model.Client>()
  useEffect(() => {
    fetch(`/api/clients/${key.id}`)
      .then(response => response.json())
      .then(({ data }) => setClient(data))
  }, [key])
  return [client, setClient] as ReactState<Model.Client>
}
