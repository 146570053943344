export enum ServiceProductSessionStatus {
  SCHEDULED = 1,
  PENDING = 2,
  DELIVERED = 3
}

export function getServiceProductSessionStatusColor(status?: ServiceProductSessionStatus) {
  switch (status) {
    case ServiceProductSessionStatus.SCHEDULED:
      return '#0aa0d2'
    case ServiceProductSessionStatus.PENDING:
      return '#e50000'
    case ServiceProductSessionStatus.DELIVERED:
      return '#00b200'
    default:
      return ''
  }
}

export function getServiceProductSessionStatusLabel(status?: ServiceProductSessionStatus) {
  switch (status) {
    case ServiceProductSessionStatus.SCHEDULED:
      return 'Agendado'
    case ServiceProductSessionStatus.PENDING:
      return 'Pendente'
    case ServiceProductSessionStatus.DELIVERED:
      return 'Entregue'
  }
}
