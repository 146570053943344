import React, { useRef, useState } from 'react'

import { PhotoWatermarkConfig } from 'shared/components/common/PhotoWatermarkConfig'
import { FormCheck } from 'shared/form/FormCheck'
import { Modal, ModalFooter, ModalHeader } from 'shared/modal'
import { Button } from 'shared/ui/button/Button'
import { classNames } from 'shared/util/class-names'
import { toFormData } from 'shared/util/form-data'

import classes from './PhotoWatermarkSessionModal.module.scss'

interface PhotoWatermarkSessionModalProps {
  close: () => void
  session: Resource.SessionResource
}

export const PhotoWatermarkSessionModal: React.FC<PhotoWatermarkSessionModalProps> = ({ close, session }) => {
  const watermark = useRef(session.watermark || {})
  const [custom, setCustom] = useState(session.watermark !== null)
  const [value, setValue] = useState(session.watermark || {})
  const handleChange = (checked: boolean) => {
    setCustom(!checked)
  }
  const handleSave = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData({
      watermark: custom ? value : ''
    })
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' }).then(() => window.location.reload())
  }
  return (
    <Modal className={classes.modal} size="xl">
      <ModalHeader>Configurar marca d'água nas fotos do ensaio</ModalHeader>
      <FormCheck onChange={handleChange} checked={!custom}>
        Utilizar o padrão do cliente para marca d'água
      </FormCheck>
      <div className={classNames(classes.watermark, !custom && classes.disabled)}>
        <PhotoWatermarkConfig onChange={setValue} watermark={watermark.current} />
      </div>
      <ModalFooter content="between">
        <Button onClick={close} outline>
          CANCELAR
        </Button>
        <Button onClick={handleSave}>SALVAR</Button>
      </ModalFooter>
    </Modal>
  )
}
