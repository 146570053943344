import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { classNames } from 'shared/util/class-names'

import classes from './Button.module.scss'

interface ButtonProps {
  className?: string
  disabled?: boolean
  href?: string
  onClick?: (_: React.MouseEvent) => void
  outline?: boolean
  size?: 'sm' | 'md' | 'lg'
  to?: string
  type?: 'button' | 'submit'
  variant?: 'primary' | 'info'
}

export const Button: React.FC<ButtonProps> = ({ className, outline, size, to, variant, ...props }) => {
  size = size || 'md'
  variant = variant || 'primary'
  const params = {
    className: classNames(
      className,
      classes.button,
      classes[`size-${size}`],
      classes[`variant-${variant}`],
      outline && classes.outline,
      classes[className!]
    )
  }
  if (to) {
    return <Link to={to} {...params} {...props} />
  }
  if (props.href) {
    return (
      <a {...params} {...props} rel="noreferrer noopener" target="_blank">
        <Fragment children={props.children} />
      </a>
    )
  }
  return <button {...props} {...params} />
}
