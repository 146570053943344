export const ProductStatus = {
  AGENDADA: 1,
  PENDENTE: 2,
  ENTREGUE: 3
}

export const sessionStates = new Map([
  [1, { className: 'naoagendada', label: 'Não agendada' }],
  [2, { className: 'agendada', label: 'Agendada' }],
  [3, { className: 'cancelado', label: 'Cancelado' }],
  [4, { className: 'entregapendente', label: 'Entrega Pendente' }],
  [5, { className: 'entregue', label: 'Entregue' }],
  [6, { className: 'noshow', label: 'NoShow Cliente' }],
  [7, { className: 'noshow', label: 'NoShow Fotógrafo' }],
  [8, { className: 'emrevisao', label: 'Em Revisão' }]
])

export const itemStates = new Map([
  [1, { label: 'agendada' }],
  [2, { label: 'pendente' }],
  [3, { label: 'entregue' }]
])
