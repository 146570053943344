import React, { useState } from 'react'

import { Button } from 'components/Button'
import { useItems } from 'hooks/data/item'
import { FormFeedbackSuccess } from 'shared/form/FormFeedbackSuccess'
import { FormGroup } from 'shared/form/FormGroup'
import { FormTextArea } from 'shared/form/FormTextArea'
import { toFormData } from 'shared/util/form-data'

import classes from './ClientOrientations.module.scss'

export const ClientOrientations: App.ClientViewSection = ({ state: [client, setClient] }) => {
  const [orientations, setOrientations] = useState<Map<number, string>>(() => {
    return new Map<number, string>(client.items.map(item => [item.id, item.pivot.orientation]))
  })
  const [status, setStatus] = useState<App.FormStatus>({ state: 'initial' })
  const items = useItems()?.flatMap<[Model.Item, Model.Item[]]>((item, _, items) => {
    if (item.item) {
      return []
    } else {
      return [[item, items.filter(current => current.item?.id === item.id)]]
    }
  })
  const handleChange = (item: Model.Item) => (value: string) => {
    setOrientations(orientations => new Map(orientations.set(item.id, value)))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData({
      items: Array.from(orientations).map(([item_id, orientation]) => ({
        item_id,
        pivot: { orientation }
      }))
    })
    setStatus({ state: 'loading' })
    fetch(`/api/clients/${client.id}`, { body, method: 'POST' })
      .then(response => response.json())
      .then(({ data }) => {
        setClient(data)
        setStatus({ state: 'saved' })
      })
  }
  return (
    <form onSubmit={handleSubmit}>
      <p className={classes.legend}>
        Adicione em cada entregável as respectivas orientações desejadas pelo cliente para produção do material
      </p>
      {items?.map(([parent, items]) => (
        <div key={parent.id}>
          <p className={classes.title}>{parent.title}</p>
          <div className={classes.items}>
            {items.map(item => (
              <FormGroup key={item.id} label={item.label}>
                <FormTextArea onChange={handleChange(item)} value={orientations.get(item.id)} rows={4} />
              </FormGroup>
            ))}
          </div>
          <hr />
        </div>
      ))}
      {items?.length > 0 && (
        <div className={classes.footer}>
          <FormFeedbackSuccess autoHide={10} status={status} />
          <Button className={classes.button}>SALVAR</Button>
        </div>
      )}
    </form>
  )
}
