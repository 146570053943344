import React, { useCallback, useState } from 'react'

import { Button } from 'components/Button'
import { ContractServiceItem } from 'pages/clients/client-add/pages/components/ContractServiceItem'
import { Modal, ModalFooter } from 'shared/modal'
import { toFormData } from 'shared/util/form-data'

type ServiceType = Pick<Model.Service, 'name' | 'host_prize' | 'price' | 'products'>

interface ServiceFormProps {
  client?: Model.Client
  onSubmit?: (_: Partial<Model.Service>) => void
  service?: Model.Service | Partial<Model.Service>
}

export const ServiceFormModal: App.Modal<ServiceFormProps> = ({ client, close, onSubmit, service }) => {
  const [state, setState] = useState<ServiceType>({
    name: service?.name || '',
    host_prize: service?.host_prize || 6,
    price: service?.price || 0,
    products: service?.products || []
  })
  const handleChange = useCallback((service: ServiceType) => {
    setState(service)
  }, [])
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    onSubmit?.(state)
    if (client?.id) {
      const body = toFormData({
        ...state,
        client: {
          id: client?.id
        }
      })
      if (service) {
        fetch(`/api/services/${service.id}`, { body, method: 'POST' })
          .then(close)
          .catch(() => alert('Falha ao atualizar serviço!'))
      } else {
        fetch(`/api/services`, { body, method: 'POST' })
          .then(close)
          .catch(() => alert('Falha ao cadastrar serviço!'))
      }
    } else {
      close()
    }
  }
  return (
    <Modal size="md">
      <form onSubmit={handleSubmit}>
        <ContractServiceItem onChange={handleChange} service={state} />
        <ModalFooter content="between">
          <Button onClick={close}>CANCELAR</Button>
          <Button>SALVAR</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
