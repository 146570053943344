import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Steps } from 'components/Steps'
import { Button } from 'components/Button'

import { FormAddress } from 'shared/form/FormAddress'
import { FormGroup } from 'shared/form/FormGroup'
import { FormLogo } from 'shared/form/FormLogo'
import { FormText } from 'shared/form/FormText'

import { useClientAddContext } from '../ClientAddContext'

import classes from './BasicInfo.module.scss'

export const BasicInfo: React.FC<RouteComponentProps> = ({ history }) => {
  const [context, setContext] = useClientAddContext()
  console.log(context)
  const [client, setClient] = useState<ClientAddContext>({
    address: context.address,
    name: context.name || '',
    logo: context.logo || null,
    status: 1
  })
  const handleChange = (prop: string) => (value: string | boolean | number | google.maps.places.PlaceResult) => {
    setClient(client => ({ ...client, [prop]: value }))
  }
  const handleLogo = ([logo]: File[]) => {
    const reader = new FileReader()
    reader.onerror = () => alert('Falha ao carregar logo')
    reader.onload = () => {
      const result = reader.result?.toString()
      if (result) {
        setClient(client => ({ ...client, logo }))
      }
    }
    reader.readAsDataURL(logo)
  }
  const handleNext = (event: React.FormEvent) => {
    event.preventDefault()
    setContext(context => ({ ...context, ...client }))
    history.push(`/clients/add/services`)
  }
  return (
    <Fragment>
      <Steps className="first" />
      <h2 className={classes.title}>Informações básicas</h2>
      <form onSubmit={handleNext}>
        <FormGroup label="Nome Fantasia do Cliente">
          <FormText onChange={handleChange('name')} required value={client.name} />
        </FormGroup>
        <FormGroup label="Cidade">
          <FormAddress
            onChange={handleChange('address')}
            type="city"
            required
            value={client.address?.formatted_address}
          />
        </FormGroup>
        <FormGroup label="Logo">
          <FormLogo accept=".jpg,.jpeg,.png,.svg" onChange={handleLogo} value={client.logo || ''} />
          <p className={classes.upload}>
            Esse logo deverá ter fundo transparente e aparecerá na realização de um agendamento público
          </p>
        </FormGroup>
        <Button className={classes.next}>Avançar →</Button>
      </form>
    </Fragment>
  )
}
