import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useMatterportTours(
  params?: Http.Params,
  paging?: Http.Pagination,
  setMeta?: (_: Http.ResponseMeta) => void
) {
  const [matterportTours, setMatterportTours] = useState<Model.MatterportTour[]>()
  useEffect(() => {
    setMatterportTours(undefined)
    const request = {
      body: toFormData(params || {}),
      method: 'POST'
    }
    const search = new URLSearchParams()
    if (paging) {
      search.set('page', paging.page.toString())
      search.set('length', paging.length.toString())
    }
    fetch(`/api/matterport-tours/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setMatterportTours(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return matterportTours
}
