import React, { useEffect, useRef, useState } from 'react'

import { Icon } from '../ui/icon'
import { classNames } from '../util/class-names'

import { FormState } from './enum/FormState'

import classes from './FormFeedbackSuccess.module.scss'

interface FormFeedbackSuccessProps {
  /** Automatically hide time feedback in seconds */
  align?: 'left' | 'right'
  autoHide?: number
  state?: FormState
  status?: App.FormStatus
}

export const FormFeedbackSuccess: React.FC<FormFeedbackSuccessProps> = ({
  align = 'right',
  autoHide,
  state,
  status
}) => {
  const interval = useRef<number>()
  const [form, setForm] = useState(status)
  const [showing, setShowing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    if (status) {
      if ((status.state === 'saved') !== showing && form !== status) {
        setForm(status)
        setShowing(true)
        if (autoHide) {
          window.clearTimeout(interval.current)
          interval.current = window.setTimeout(() => setShowing(false), autoHide * 1000)
        }
      }
    }
  }, [autoHide, form, showing, status])
  useEffect(() => {
    if (state) {
      if (state === FormState.SUBMITTING) {
        setSubmitting(true)
      }
      if ((state === FormState.SUCCESSFULL) !== showing && submitting) {
        setSubmitting(false)
        setShowing(true)
        if (autoHide) {
          window.clearTimeout(interval.current)
          interval.current = window.setTimeout(() => setShowing(false), autoHide * 1000)
        }
      }
    }
  }, [autoHide, showing, state, submitting])
  const loadingClass = classNames(
    classes.loader,
    status?.state === 'loading' && classes.loading,
    state === FormState.SUBMITTING && classes.loading
  )
  return (
    <div className={classNames(classes.container, align === 'left' && classes.left)}>
      <div className={classNames(classes.content, showing && !submitting && classes.showing)}>
        <Icon.CheckCircle />
        <span className={classes.text}>Alterações salvas com sucesso!</span>
      </div>
      <div className={loadingClass}>
        <Icon.CircleNotch spin />
      </div>
    </div>
  )
}
