type ToFormData = { [key: string]: any }

function transformPath(path: string) {
  if (path.includes('.')) {
    return path.replace(/\./, '[').replace(/$/, ']').replace(/\./g, '][')
  } else {
    return path
  }
}

function flattenToFormData(form: FormData, data: any, path: string) {
  if (typeof data === 'string' || data instanceof File) {
    form.set(transformPath(path), data)
  } else if (typeof data === 'number') {
    form.set(transformPath(path), data.toString())
  } else if (typeof data === 'boolean') {
    form.set(transformPath(path), Number(data).toString())
  } else if (typeof data === 'undefined' || data === null) {
    form.set(transformPath(path), '')
  } else {
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value, i) => {
          const prefix = `${path}.${key}.${i}`.replace(/^\.|\.$/g, '').replace(/\.\.+/g, '.')
          flattenToFormData(form, value, prefix)
        })
      } else {
        const prefix = `${path}.${key}`.replace(/^\.|\.$/g, '').replace(/\.\.+/g, '.')
        flattenToFormData(form, value, prefix)
      }
    })
  }
}

export function toFormData(data: ToFormData) {
  const formData = new FormData()
  flattenToFormData(formData, data, '')
  return formData
}
