import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { NavLink, Route, RouteComponentProps } from 'react-router-dom'

import { SessionConfirmation } from 'shared/enum/session/SessionConfirmation'
import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { FormAddress } from 'shared/form/FormAddress'
import { FormCheck } from 'shared/form/FormCheck'
import { FormText } from 'shared/form/FormText'
import { FormTextArea } from 'shared/form/FormTextArea'
import { useSessions } from 'shared/hooks/data/session'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { RoundButton } from 'shared/ui/button/RoundButton'
import { Icon } from 'shared/ui/icon/Icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'
import { parseAddress } from 'shared/util/google-maps'
import { phoneToWhatsApp } from 'shared/util/phone-number'

import classes from './SessionListTable.module.scss'

interface SessionListTableProps extends Pick<RouteComponentProps<{ scope: string }>, 'match'> {
  className?: string
  params?: {
    filter?: { [key: string]: any }
  }
}

interface SessionListTableMeta extends Http.ResponseMeta {
  imported_count: number
  scheduled_count: number
  total_count: number
}

type GoogleAddressType = google.maps.GeocoderResult | google.maps.places.PlaceResult

interface SessionData {
  address: GoogleAddressType
  additional_info: {
    confirmation?: string
  }
  vertical_data: {
    complement?: string
    unit?: string
  }
}

const confirmationMessage = (session: Model.Session) => {
  const date = formatISO(session.scheduled_for!, 'dd/MM/yyy')
  const hour = formatISO(session.scheduled_for!, 'HH:mm')
  return `*Confirmação de Fotos - LOFT ID ${session.vertical_data?.reference}*

Olá ${session.responsible_info.name}, tudo bem? Temos um ensaio de fotos agendado no endereço:

${session.address?.formatted_address} agendado para às ${hour} do dia ${date}.

👉 *Confirmar Ensaio:* Responda aqui "Confirmado".

Temos uma dica: A apresentação impacta na performance do anúncio. Procure organizadar os ambientes e estantes, não deixar roupa estendida, nem louça nas pias.

📸 O fotógrafo é definido no dia anterior ao ensaio. Ele entrará em contato para informar nome completo e RG.

Somos da Agenciou, empersa parceira da Loft, responsável pela produção das imagens.

Com a sua ajuda, faremos um excelente ensaio. Qualquer dúvida, não hesite em me chamar.

Abraços da equipe Agenciou
`
}

export const SessionListTable: React.FC<SessionListTableProps> = ({ className, params, match }) => {
  const scope = match.params.scope || ''
  const [data, setData] = useState<Map<number, Partial<SessionData>>>(new Map())
  const [current, setCurrent] = useState({ filter: { ...params?.filter, scope } })
  const [meta, setMeta] = useState<SessionListTableMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 100 })
  const [selectedAll, setSelectedAll] = useState(false)
  const [selected, setSelected] = useState(new Set<number>())
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const sessions = useSessions(current, paging, handleMeta)
  const handleSelect = (session: Model.Session) => (checked: boolean) => {
    const selection = new Set(selected)
    if (checked) {
      selection.add(session.id)
      if (selection.size === sessions?.length) {
        setSelectedAll(true)
      }
    } else {
      setSelectedAll(false)
      selection.delete(session.id)
    }
    setSelected(selection)
  }
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelected(new Set(sessions?.map(({ id }) => id)))
    } else {
      setSelected(new Set())
    }
    setSelectedAll(checked)
  }
  const handleAddress = (sessionId: number) => (value: GoogleAddressType) => {
    const address = JSON.parse(JSON.stringify(value))
    setData(data => {
      return new Map(data).set(sessionId, { ...data.get(sessionId), address })
    })
  }
  const handleAdditionalConfirmation = (sessionId: number) => (confirmation: string) => {
    setData(data => {
      const session = { ...data.get(sessionId), additional_info: { confirmation } }
      return new Map(data).set(sessionId, session)
    })
  }
  const handleVerticalData = (key: string, sessionId: number) => (value: string) => {
    setData(data => {
      const verticalData = sessions?.find(({ id }) => id === sessionId)?.vertical_data || {}
      const session = {
        ...data.get(sessionId),
        vertical_data: {
          ...data.get(sessionId)?.vertical_data,
          ...verticalData,
          [key]: value
        }
      }
      return new Map(data).set(sessionId, session)
    })
  }
  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (window.confirm('Deseja cancelar os ensaios selecionados?')) {
      const requests = Array.from(selected.values()).map(sessionId => {
        const body = toFormData({ confirmation: SessionConfirmation.CANCELED })
        return fetch(`/api/sessions/${sessionId}`, { body, method: 'POST' })
      })
      Promise.allSettled(requests).then(() => {
        setCurrent({ filter: { ...params?.filter, scope } })
        setSelected(new Set<number>())
      })
    }
  }
  const handleConfirm = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (window.confirm('Deseja marcar os ensaios selecionados como confirmados?')) {
      const requests = Array.from(selected.values()).map(sessionId => {
        const body = toFormData({ confirmation: SessionConfirmation.CONFIRMED })
        return fetch(`/api/sessions/${sessionId}`, { body, method: 'POST' })
      })
      Promise.allSettled(requests).then(() => {
        setCurrent({ filter: { ...params?.filter, scope } })
        setSelected(new Set<number>())
      })
    }
  }
  const handlePending = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (window.confirm('Deseja marcar os ensaios selecionados como pendentes?')) {
      const requests = Array.from(selected.values()).map(sessionId => {
        const body = toFormData({ confirmation: SessionConfirmation.PENDING })
        return fetch(`/api/sessions/${sessionId}`, { body, method: 'POST' })
      })
      Promise.allSettled(requests).then(() => {
        setCurrent({ filter: { ...params?.filter, scope } })
        setSelected(new Set<number>())
      })
    }
  }
  const handleVerify = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (window.confirm('Deseja marcar os ensaios selecionados como verificados?')) {
      const requests = Array.from(selected.values())
        .map(sessionId => data.get(sessionId) && { id: sessionId, ...data.get(sessionId) })
        .filter(session => session && session.address)
        .map(session => {
          const body = toFormData({
            address: session?.address,
            verified: 1,
            vertical_data: session?.vertical_data
          })
          return fetch(`/api/sessions/${session!.id}`, { body, method: 'POST' })
        })
      Promise.allSettled(requests).then(() => {
        setCurrent({ filter: { ...params?.filter, scope } })
        setSelected(new Set<number>())
      })
    }
  }
  const handleCopyWhatsAppMessage = (session: Model.Session) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (session.responsible_info.phone) {
      const responsiblePhone = session.responsible_info.phone.replace(/[^\d]/g, '')
      const phone = responsiblePhone.length !== 11 ? responsiblePhone : `55${responsiblePhone}`
      navigator.clipboard.writeText(phoneToWhatsApp(phone, confirmationMessage(session)))
    }
  }
  const handleOpenWhatsAppMessage = (session: Model.Session) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (session.responsible_info.phone) {
      const responsiblePhone = session.responsible_info.phone.replace(/[^\d]/g, '')
      const phone = responsiblePhone.length !== 11 ? responsiblePhone : `55${responsiblePhone}`
      window.open(phoneToWhatsApp(phone, confirmationMessage(session)), '_blank')
    }
  }
  useEffect(() => {
    if (scope !== current.filter.scope) {
      // console.log('useEffect 1')
      setCurrent({ filter: { ...params?.filter, scope } })
      setSelected(new Set<number>())
    }
  }, [match])
  useEffect(() => {
    // console.log('useEffect 2')
    setCurrent({ filter: { ...params?.filter, scope } })
  }, [params])
  return (
    <Fragment>
      <nav className={classes.nav}>
        <NavLink className={classes.link} activeClassName={classes.active} to="/sessions" exact>
          Todos Ensaios {Number.isInteger(meta?.total_count) && `(${meta!.total_count})`}
        </NavLink>
        <NavLink className={classes.link} activeClassName={classes.active} to="/sessions/confirm">
          Confirmar Agendamentos {Number.isInteger(meta?.scheduled_count) && `(${meta!.scheduled_count})`}
        </NavLink>
        <NavLink className={classes.link} activeClassName={classes.active} to="/sessions/import">
          Confirmar Importação {Number.isInteger(meta?.imported_count) && `(${meta!.imported_count})`}
        </NavLink>
      </nav>
      {scope === 'confirm' && (
        <div className={classes.actions}>
          <Button onClick={handleCancel} outline size="sm" variant="danger">
            CANCELAR SELECIONADOS
          </Button>
          <Button onClick={handlePending} size="sm">
            MARCAR COMO PENDENTE
          </Button>
          <Button onClick={handleConfirm} size="sm">
            CONFIRMAR SELECIONADOS
          </Button>
        </div>
      )}
      {scope === 'import' && (
        <div className={classes.actions}>
          <Button onClick={handleCancel} outline size="sm" variant="danger">
            CANCELAR SELECIONADOS
          </Button>
          <Button onClick={handleVerify} size="sm">
            VERIFICAR SELECIONADOS
          </Button>
        </div>
      )}
      <Table className={className} hover strip>
        <TableHeader>
          <TableColumn name="id" align="center">
            ID
          </TableColumn>
          <TableColumn name="scheduled" align="center">
            Ensaio
          </TableColumn>
          <TableColumn name="client" align="center" size={2}>
            Cliente
          </TableColumn>
          <Route
            path="/sessions"
            exact
            render={() => (
              <Fragment>
                <TableColumn name="address" align="center" size={3}>
                  Endereço
                </TableColumn>
                <TableColumn name="created_at" align="center">
                  Data de criação
                </TableColumn>
                <TableColumn name="deliverables">Produtos</TableColumn>
              </Fragment>
            )}
          />
          <Route
            path="/sessions/confirm"
            render={() => (
              <Fragment>
                <TableColumn name="address" align="center" size={3}>
                  Endereço
                </TableColumn>
                <TableColumn size={2}>Observações</TableColumn>
                <TableColumn size={2}>Responsável pelo Agendamento</TableColumn>
                <TableColumn>Mensagem</TableColumn>
                <TableColumn size={0.5}>
                  <FormCheck onChange={handleSelectAll} checked={selectedAll} />
                </TableColumn>
              </Fragment>
            )}
          />
          <Route
            path="/sessions/import"
            render={() => (
              <Fragment>
                <TableColumn size={3}>Endereço completo</TableColumn>
                <TableColumn size={0.5}>Confirmar</TableColumn>
                <TableColumn size={0.5}>
                  <FormCheck onChange={handleSelectAll} checked={selectedAll} />
                </TableColumn>
              </Fragment>
            )}
          />
        </TableHeader>
        <TableBody>
          {sessions?.map(session => {
            const address = parseAddress(session.address?.address_components)
            return (
              <TableRow key={session.id}>
                <TableColumn align="center">
                  <Button className={classes.id} size="sm" to={`/sessions/${session.id}`}>
                    <span>{session.id}</span>
                    <Icon.AngleRight />
                  </Button>
                  <div className={classes.status}>
                    <Badge color={getSessionStatusColor(session.status)} size="sm">
                      {getSessionStatusDescription(session.status)}
                    </Badge>
                  </div>
                </TableColumn>
                <TableColumn align="center">
                  {match.params.scope === 'import' && <div>{session.service.name}</div>}
                  {session.scheduled_for ? <div>{formatISO(session.scheduled_for, 'dd/MM/yyyy HH:mm')}</div> : '-'}
                  {session.provider && <div>{session.provider.name}</div>}
                </TableColumn>
                <TableColumn align="center" size={2}>
                  <div>{session.client.name}</div>
                  {session.vertical_data?.reference && <strong>{session.vertical_data?.reference}</strong>}
                </TableColumn>
                <Route
                  path="/sessions"
                  exact
                  render={() => (
                    <Fragment>
                      <TableColumn align="center" size={3}>
                        <div>
                          {address.get('street')}
                          {address.has('number') && `, ${address.get('number')}`}
                          {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                        </div>
                        <div>
                          {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
                        </div>
                      </TableColumn>
                      <TableColumn align="center">
                        <div>{session.user.name}</div>
                        <div>{formatISO(session.created_at!, 'dd/MM/yyyy HH:mm')}</div>
                      </TableColumn>
                      <TableColumn align="center"></TableColumn>
                    </Fragment>
                  )}
                />
                <Route
                  path="/sessions/confirm"
                  render={() => (
                    <Fragment>
                      <TableColumn align="center" size={3}>
                        <div>
                          {address.get('street')}
                          {address.has('number') && `, ${address.get('number')}`}
                          {session.vertical_data?.unit && ` - ${session.vertical_data.unit}`}
                        </div>
                        {session.vertical_data?.complement && <div>{session.vertical_data.complement}</div>}
                        <div>
                          {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
                        </div>
                      </TableColumn>
                      <TableColumn size={2}>
                        <FormTextArea
                          onChange={handleAdditionalConfirmation(session.id)}
                          value={session.additional_info?.confirmation?.toString()}
                        />
                      </TableColumn>
                      <TableColumn size={2}>
                        <div>{session.responsible_info.name}</div>
                        <div>{session.responsible_info.email}</div>
                        <div>{session.responsible_info.phone}</div>
                      </TableColumn>
                      <TableColumn className={classes.whatsAppColumn} justify="space-around">
                        <RoundButton active onClick={handleCopyWhatsAppMessage(session)}>
                          <Icon.Copy />
                        </RoundButton>
                        <RoundButton active onClick={handleOpenWhatsAppMessage(session)}>
                          <Icon.Whatsapp />
                        </RoundButton>
                      </TableColumn>
                      <TableColumn size={0.5}>
                        <FormCheck onChange={handleSelect(session)} checked={selected.has(session.id)} />
                      </TableColumn>
                    </Fragment>
                  )}
                />
                <Route
                  path="/sessions/import"
                  render={() => (
                    <Fragment>
                      <TableColumn className={classes.address} size={3}>
                        <div className={classes.input}>{session.additional_info?.address_original}</div>
                        <FormAddress
                          className={classes.input}
                          onChange={handleAddress(session.id)}
                          placeholder={session.additional_info?.address_original?.toString()}
                          value={session.additional_info?.address_original?.toString()}
                        />
                        <span>Unidade</span>
                        <FormText placeholder="Unidade" onChange={handleVerticalData('unit', session.id)} />
                        <span>Complemento</span>
                        <FormText placeholder="Complemento" onChange={handleVerticalData('complement', session.id)} />
                      </TableColumn>
                      <TableColumn size={0.5}>
                        <FormCheck onChange={handleSelect(session)} checked={selected.has(session.id)} />
                      </TableColumn>
                    </Fragment>
                  )}
                />
              </TableRow>
            )
          })}
        </TableBody>
        <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
      </Table>
    </Fragment>
  )
}
