import React, { useEffect, useRef, useState } from 'react'
import { IMaskInput } from 'react-imask'

import classes from './FormText.module.scss'

export enum MaskType {
  CNPJ = 'cnpj',
  CNPJ_CPF = 'cnpj-cpf',
  CPF = 'cpf',
  CREDIT_CARD = 'credit-card',
  CURRENCY = 'currency',
  CVV = 'cvv',
  MONTH_YEAR = 'month-year',
  PHONE = 'phone',
  POSTAL_CODE = 'postal-code'
}

interface FormMaskedProps {
  mask: string
  onChange?: (_: string) => void
  placeholder?: string
  required?: boolean
  type?: string
  value?: Nullable<string>
  maxValue?: number
  /** @deprecated */
  readonly?: boolean
  readOnly?: boolean
}

let uniqueId = 0

function getMaskProps(mask: string, required?: boolean) {
  switch (mask) {
    case MaskType.CNPJ:
      return {
        mask: '00.000.000/0000-00',
        pattern: required && ''
      }
    case MaskType.CNPJ_CPF:
      return {
        mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }]
      }
    case MaskType.CPF:
      return {
        mask: '000.000.000-00'
      }
    case MaskType.CREDIT_CARD:
      return {
        mask: '0000 0000 0000 0000[ 000]',
        placeholder: '0000 0000 0000 0000'
      }
    case MaskType.CURRENCY:
      return {
        mask: Number,
        scale: 2,
        signed: false,
        thousandsSeparator: '.',
        padFractionalZeros: true,
        radix: ',',
        min: 0,
        max: 10 ** 8, // max ten digits
        unmask: true
      }
    case MaskType.CVV:
      return {
        mask: '000',
        placeholder: '000'
      }
    case MaskType.MONTH_YEAR:
      return {
        mask: '00/0000',
        pattern: '',
        placeholder: 'MM/AAAA'
      }
    case MaskType.PHONE:
      return {
        mask: [{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }],
        pattern: required && ''
      }
    case MaskType.POSTAL_CODE:
      return {
        mask: '00000-000',
        placeholder: '00000-000'
      }
  }
}

export const FormMasked: React.FC<FormMaskedProps> = ({ mask, onChange, value, readonly = false, ...props }) => {
  const id = useRef(`form-masked-${++uniqueId}`)
  const [current, setCurrent] = useState(value ?? '')
  const params = {
    className: classes.input,
    id: id.current,
    readOnly: readonly,
    onAccept: (value: string) => {
      setCurrent(value)
      onChange?.(value)
    }
  }
  useEffect(() => setCurrent(value ?? ''), [value])
  return <IMaskInput value={current} {...params} {...props} {...getMaskProps(mask, props.required)} />
}
