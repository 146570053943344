import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useClients(params?: Http.Params, paging?: Http.Pagination, setMeta?: (_: Http.ResponseMeta) => void) {
  const [clients, setClients] = useState<Model.Client[]>()
  useEffect(() => {
    setClients(undefined)
    const request = {
      body: toFormData(params || {}),
      method: 'POST'
    }
    const search = new URLSearchParams()
    search.set('v', '2') // temporário
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    fetch(`/api/clients/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setClients(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return clients
}

export function useClient(key: Model.Key) {
  const [client, setClient] = useState<Model.Client>()
  useEffect(() => {
    fetch(`/api/clients/${key.id}`)
      .then(response => response.json())
      .then(({ data }) => setClient(data))
  }, [key])
  return [client, setClient] as ReactState<Model.Client>
}
